
import { Row, Col, Button, Space, } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons'
import Artboardone from '@assets/images/Artboard – 1.jpg'
import Artboardtwo from '@assets/images/Artboard – 2.jpg'
import ContentPage from '@layout/MainLayout/Header/Content';
import { useAuthContextState } from '@authContext/store';

import config from '@config/app.config';
import { ROLE_ENUM } from '@interface/role';

const Document = () => {

  const { userInfo } = useAuthContextState()
  const buttonName = {
    sector:{url:'/example/document/user_manual_sat_r_all_v3.pdf',title:"คู่มือผู้บริหาร"},
    officer:{url:'/example/document/user_manual_sat_r_off_v3.pdf',title:"คู่มือเจ้าหน้าที่"},
    assessor:{url:'/example/document/user_manual_sat_r_ass_v3.pdf',title:"คู่มือผู้ถูกประเมิน"},
    admin:{url:'/example/document/user_manual_sat_r_admin_v3.pdf',title:"คู่มือแอดมิน"}
  } 

  const buttonDoc= {
    [ROLE_ENUM.ADMIN]:[buttonName.assessor,buttonName.officer,buttonName.sector,buttonName.admin],
    [ROLE_ENUM.SECTOR]:[buttonName.assessor,buttonName.officer,buttonName.sector],
    [ROLE_ENUM.OFFICER]:[buttonName.assessor,buttonName.officer],
    [ROLE_ENUM.ASSESSOR]:[buttonName.assessor],

  }
  const getButton = (val:string)=>{
  let _role: { url: string; title: string; }[]|[] = []
  switch(val) {
    case ROLE_ENUM.ADMIN:
      _role =  buttonDoc[ROLE_ENUM.ADMIN]
     break;
     case ROLE_ENUM.SECTOR:_role = buttonDoc[ROLE_ENUM.SECTOR]
     break;
     case ROLE_ENUM.OFFICER:_role =buttonDoc[ROLE_ENUM.OFFICER]
     break;
     case ROLE_ENUM.ASSESSOR:_role =buttonDoc[ROLE_ENUM.ASSESSOR]
     break;
     default:
       _role = []

  }
  return _role
}
  return (
    <ContentPage
      headerContent={
        <div className="hn-title-text" >
          <div className="text-title-white">การประเมินผลสัมฤทธิ์</div>
          <div className="header-font-subtitle">{userInfo.title}</div>
        </div>
      }
    >
      <div>
        <div className="box-row">
          <Col sm={24} md={24} className="line">
            <Row>
              <Col sm={12}>
              <div className="document-titie">
              <b
                className="font"
                style={{ fontSize: 35 }}>
                คู่มือการใช้งาน
              </b>
              <span
                className="font"
                style={{ fontSize: 25 }}>
                การประเมินผลสัมฤทธิ์แบบฟอร์ม
              </span>
            </div>
              </Col>
              <Col lg={12} className="see-document " >
           <Space>
                {getButton(userInfo.role).map((item:any,ind:any)=>(
                    <Button key={ind} type="primary" href={`${config.apiURL}${item.url}`} target="_blank">{item.title}</Button>
                    ))}
                    </Space>
             
           </Col>
            </Row>
           
        

          </Col>
          <Row
            gutter={[24, 16]}
            justify="center"
            align="middle"
            className="document-step-style"
          >
            <Col xl={9} xxl={9} >
              <div className="document-img document-distance"  >
                <img
                  className="document-img-border"
                  src={Artboardone}
                  alt="Artboard-1"
                />
              </div>
            </Col>
            <Col xl={15} xxl={15} >
              <Col sm={4} md={4} className="boxsmalltopcontent" >
                <div>ขั้นตอนแรก</div>
              </Col>
              <div className="linboxcontent ">
                <div className="document-datastap">
                  <span>- เลือกปีการประเมินผลสัมฤทธิ์</span>
                  <span>- เลือกตัวชี้วัด ประเมินตามขั้นตอน เช่น มีข้อมูล ให้อัปโหลดไฟล์ ถ้าไม่มีทำขั้นตอนถัดไป</span>
                </div>
                <div className="document-datastap-note">
                  <span>*** หมายเหตุ</span>
                  <span>ประเมินได้เฉพาะปีปัจจุบันเท่านั้น</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            gutter={[24, 16]}
            justify="center"
            align="middle"
            className="document-step-style"
          >
            <Col xl={9} xxl={9} >
              <div className="document-img document-distance"  >
                <img
                  src={Artboardtwo}
                  alt="Artboard-2"
                />
              </div>
            </Col>
            <Col xl={15} xxl={15}>
              <Col sm={4} md={4} className="boxsmalltopcontent" >
                <div>ขั้นตอนสอง</div>
              </Col>
              <div className="linboxcontent">
                <div className="document-datastap">
                  <span>- ประเมินครบสมบูรณ์ จะขึ้นสถานะสีเขียวทั้งหมด</span>
                  <span style={{ padding: "0px 11px" }}>หลังจากนั้นกดส่งแบบประเมิน เสร็จสิ้นขั้นตอนการประเมิน</span>
                </div>
                <div>
                  <div className="document-font" >*** หมายเหตุ</div>
                  <div className="document-format" style={{ padding: "0px 0px 20px 20px" }} >
                    <Row gutter={[24, 16]}>
                      <div className="document-format" >
                        <div className="circlc-yellow"> </div>
                        <div className="document-text" style={{ padding: " 0px 0px 0px 10px" }}>สีเหลือง</div>
                      </div>
                      <div className="document-format" >
                        <div className="document-text" >
                          <CheckCircleFilled style={{ fontSize: '14px', padding: '0px 10px 0px 0px', color: "#1FA361" }} />
                          สีเขียว   เสร็จสมบูรณ์
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div >
    </ContentPage>
  )
}

export default Document
