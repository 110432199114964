import Swal from 'sweetalert2';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { AssessmentTemplate } from '@interface/pages/assessment';
import { useAuthContextState } from '@authContext/store';
import { CreateAssessmentApi } from '@services/api/assessment';
import { convertJsonToFormData } from '@yim/util-tool-ts'

import ButtonForm from './ButtonForm';
import FormDetailMember from './Formdetail'
import { useState } from 'react';

interface Props {
  assessmentTemplateInfo: AssessmentTemplate[] | []
}

const FormAssessor = (props: Props) => {
  let history = useHistory();
  const { token } = useAuthContextState()
  const [loading, setLoading] = useState<boolean>(false)

  const handleFinish = async () => {
    setLoading(true)
    const res = await CreateAssessmentApi(token.accesstoken, convertJsonToFormData({}))
    if (res.code === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'สร้างแบบประเมินสำเร็จ',
        showConfirmButton: false,
        timer: 1000
      })
      history.push("/member/assessment")
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: res.message,
        showConfirmButton: false,
        timer: 1500
      })
    }
    setLoading(false)
  }
  return (
    <div className="assess-white-backgournd" style={{ height: '100%' }}>
      <Form onFinish={handleFinish}>
        <div style={{ display: 'flex' }} className="text-title assess-title">
          สร้างแบบประเมินผลสัมฤทธิ์
          <ButtonForm loading={loading} />
        </div>
      </Form>
      <FormDetailMember values={props.assessmentTemplateInfo} />
    </div>
  )
}

export default FormAssessor
