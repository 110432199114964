import car from '@assets/images/icon/car.svg'
import golf from '@assets/images/icon/golf.svg'
import bike from '@assets/images/icon/bike.svg'
import tennis from '@assets/images/icon/tennis.svg'
import jetski from '@assets/images/icon/jetski.svg'
import takraw from '@assets/images/icon/takraw.svg'
import bowling from '@assets/images/icon/bowling.svg'
import snooker from '@assets/images/icon/snooker.svg'
import football from '@assets/images/icon/football.svg'
import badminton from '@assets/images/icon/badminton.svg'
import volleyball from '@assets/images/icon/volleyball.svg'
import motorcycle from '@assets/images/icon/motorcycle.svg'
import basketball from '@assets/images/icon/basketball.svg'
import esport from '@assets/images/icon/esport.svg'

export const SportImages = [
  { title: "กอล์ฟ", icon: golf },
  { title: "เจ็ตสกี", icon: jetski },
  { title: "ตะกร้อ", icon: takraw },
  { title: "รถยนต์", icon: car },
  { title: "โบว์ลิ่ง", icon: bowling },
  { title: "เทนนิส", icon: tennis },
  { title: "จักรยาน", icon: bike },
  { title: "สนุกเกอร์", icon: snooker },
  { title: "แบดมินตัน", icon: badminton },
  { title: "ฟุตบอล", icon: football },
  { title: "บาสเกตบอล", icon: basketball },
  { title: "วอลเลย์บอล", icon: volleyball },
  { title: "จักรยานยนต์", icon: motorcycle },
  { title: "อีสปอร์ต", icon: esport },
]
export const name_type = [
  { role: "officer", roleTH: "เจ้าหน้าที่", },
  { role: "sector", roleTH: "ผู้บริหาร", },
  { role: "assessor", roleTH: "ผู้ประเมิน", },
  { role: "club", roleTH: "สโมสร", },
  { role: "organizer", roleTH: "ผู้จัดการแข่งขัน", },
  { role: "association", roleTH: "สมาคม", },

]