import React from 'react'
import IconBox from './CardList';
import appConfig from '@config/app.config';
import { useHistory } from 'react-router-dom';
import nodata from '@assets/images/3024051.jpg'
import LoadingDashboard from './LoadingDashboard';
import { CaretDownOutlined } from '@ant-design/icons';
import { Row, Col, Select, Avatar } from 'antd';
import { useAuthContextState } from '@authContext/store';
import ContentPage from '@layout/MainLayout/Header/Content';
import CompairProject from './CompairProject/compairProject';
import { DashboardMember } from '@interface/pages/dashboard'
import { Icons } from '@components/icon/RenderIcon/iconStatus';
import AssessmentIcon from '@components/icon/RenderIcon/assessmentIcon';
import {
  GetDashboardMemberApi,
  GetDashboardMemberApiById,
  GetDashboardYearApi
} from '@services/api/dashboard'

const { Option } = Select;

const Dashboard = () => {

  const [dashboardMemberInfo, setDashboardMemberInfo] = React.useState<DashboardMember[] | [] | null>([])
  const [year, setYear] = React.useState<any[] | []>([])
  const [project, setProject] = React.useState<any[] | []>([])
  const history = useHistory()
  const { token, userInfo } = useAuthContextState()
  const [projectValue, setProjectValue] = React.useState<string>('')
  const [yearValue, setYearValue] = React.useState<string>('')
  const [isLoading, setIsloading] = React.useState<boolean>(false)

  const filterProjectOfYear = async (value: string) => {
    const Data = year.filter((data) => {
      return data.year === value
    })
    setYearValue(value)
    setProject(Data[0].project)
    setProjectValue(Data[0].project[0].title)
    handleChangeProjct(Data[0].project[0].id)
  }

  const handleChangeProjct = async (e: string) => {
    try {
      const { res } = await GetDashboardMemberApiById(token.accesstoken, e)
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        if (setDashboardMemberInfo !== undefined) {
          setDashboardMemberInfo(res.result.data)
        }
        setProjectValue(e)
      }
    } catch (error: any) {
      history.push(error.message)
    }
  }

  const getDashboardMemberInfo = async () => {
    setIsloading(true)
    try {
      const { res } = await GetDashboardMemberApi(token.accesstoken)
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        setDashboardMemberInfo(res.result.data)
        setIsloading(false)
      }
    } catch (error: any) {
      history.push(error.message)
    }
  }

  const getYearsAndProjectData = async () => {
    setIsloading(true)
    try {
      const { res } = await GetDashboardYearApi(token.accesstoken)
      if (!res) throw new Error("/500");
      if (!res) throw new Error("/401");
      if (res.code === 200) {
        setYear(res.result)
        setYearValue(res.result[0].year || [])
        setProjectValue(res.result[0].project[0]?.title || '')
        setProject(res.result[0].project || [])
        setIsloading(false)
      }
    } catch (error: any) {
      history.push(error.message)
    }
  }

  React.useEffect(() => {
    getDashboardMemberInfo()
    getYearsAndProjectData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContentPage
      headerContent={
        <div className="hn-title-center" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div className="hn-title-text" >
            <div className="text-title-white">แดชบอร์ด</div>
            <div className="header-font-subtitle">{userInfo.title}</div>
          </div>
          <div className="hn-select-style">
            <div className="hn-select-btn-spacing">
              <Select
                size='large'
                disabled={isLoading}
                onChange={(e: string) => filterProjectOfYear(e)}
                suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                value={yearValue}
              >
                {year.map((data, id) => (
                  <Option key={id} value={data.year}>ปี :  {data.year}</Option>
                ))}
              </Select>
            </div>
            <div className="hn-select-btn-spacing">
              <Select
                size='large'
                disabled={isLoading}
                style={{ width: 'auto' }}
                onChange={(e: string) => handleChangeProjct(e)}
                value={projectValue}
                suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
              >
                {project.map((data, id) => (
                  <Option key={id} value={data.id} name={data.title}>
                    {data.title}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      }
    >
      <IconBox isLoading={isLoading} values={dashboardMemberInfo} />
      {!isLoading ? dashboardMemberInfo?.map((item, ind) => (
        <div key={ind}>
          <Row gutter={[5, 8]}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Col>
                <div className="DashMember-col-background">
                  <div className="DashMember-text-center">
                    <Avatar
                      size={150}
                      style={{ padding: '0' }}
                      icon={<img alt="img" src={!!item.userdetail?.img ? `${appConfig.apiURL}/${item.userdetail?.img}` : "/images/no-user-image.png"} />}
                      className="DashMember-avatar"
                    />
                    <div className="DashMember-user-title">
                      {item.userdetail.name}
                    </div>
                  </div>
                  <div style={{ fontSize: '17px' }}>
                    <Row className="DashMember-user-detail" gutter={[16, 0]}>
                      <Col sm={10} className="DashMember-user-detail-title">
                        เบอร์โทรติดต่อ :
                      </Col>
                      <Col sm={14}>
                        {item.userdetail.phone}
                      </Col>
                    </Row>
                    <Row className="DashMember-user-detail" gutter={[16, 0]}>
                      <Col sm={10} className="DashMember-user-detail-title">
                        อีเมล์ :
                      </Col>
                      <Col sm={14}>
                        {item.userdetail.email}
                      </Col>
                    </Row>
                    <Row className="DashMember-user-detail" gutter={[16, 0]}>
                      <Col sm={10} className="DashMember-user-detail-title">
                        สถานที่ติดต่อ :
                      </Col>
                      <Col sm={14}>
                        {item.userdetail.address}
                      </Col>
                    </Row>
                    <Row className="DashMember-user-detail" gutter={[16, 0]}>
                      <Col sm={10} className="DashMember-user-detail-title">
                        ชื่อผู้ประสานงาน :
                      </Col>
                      <Col sm={14}>
                        {item.userdetail.coordinator}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col >
                <div className="DashMember-col-background-processnow">
                  <div className="DashMember-col-processnow DashMember-text-center">
                    {item.status !== "" ? (
                      <React.Fragment>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                          <AssessmentIcon
                            values={{
                              status: item.status,
                              path: 'none',
                              icon: Icons.find((icon) => icon.status === item.status)?.icon
                            }}
                          />
                        </div>
                        <div className="DashMember-now-status">
                          สถานะปัจจุบัน
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <span> - </span>
                      </React.Fragment>
                    )}

                  </div>
                  <div className="DashMember-col-processnow-date">
                    <Row className="DashMember-user-detail" gutter={[16, 0]}>
                      <Col sm={10} className="DashMember-user-detail-title">
                        วันสิ้นสุดการประเมิน :
                      </Col>
                      <Col sm={14}>
                        {item.date.endAssessment}
                      </Col>
                    </Row>
                    <Row className="DashMember-user-detail" gutter={[16, 0]}>
                      <Col sm={10} className="DashMember-user-detail-title">
                        จำนวนวันประเมิน :
                      </Col>
                      <Col sm={14}>
                        {item.date.DateNumberAssessment} วัน
                      </Col>
                    </Row>
                    <Row className="DashMember-user-detail" gutter={[16, 0]}>
                      <Col sm={10} className="DashMember-user-detail-title">
                        เหลืออีก :
                      </Col>
                      <Col sm={14} style={{ color: 'red' }}>
                        {item.date.DateRemain} วัน
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs={24} sm={24} md={24} lg={18} >
              {year[0] !== "" ? (
                <CompairProject year={year} />
              ) : (
                <div className="DashMember-col-background ">
                  <div style={{ width: '100%', height: '550px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <h2 className="title-FormAssessmentList">ไม่พบข้อมูลรายการประเมิน</h2>
                    <img
                      alt='img'
                      width={'80%'}
                      height={'80%'}
                      src={nodata}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      )) : (
        <LoadingDashboard />
      )}
    </ContentPage>
  )
}
export default Dashboard

