import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
interface Props {
    data: {
        countTotal: number,
        color: {
            assessor: string;
            officer: string;
            sector: string;
        }
        count: {
            assessor: string;
            officer: string;
            sector: string;
        }
    },
    id: string
}
export default function DoughnutComponetn({ data, id }: Props) {
    const [resetNum, setResetNum] = useState<number | null>(null)
    let setNum = {
        id: id,
        beforeDraw(chart: {
            ctx: any;
            data: any;
            chartArea: {
                top: any;
                right: any;
                bottom: any;
                left: any;
                width: any;
                height: any;
            };
        }, arg: any, option: any) {
            const { ctx, chartArea: { top, width, height } } = chart;
            const text = chart?.data?.labels[3] || "0"
            const ycenter = top + (height / 2)
            ctx.fillStyle = 'black';
            ctx.font = "25px FCIconic";
            ctx.textAlign = 'center';
            ctx.fillText(text, width / 2, ycenter - 10, 90, 50)
            ctx.font = "18px FCIconic";
            ctx.fillText('ผู้ประเมินทั้งหมด', width / 2, ycenter + 10, 90, 50)
        },
    }

    useEffect(() => {
        setResetNum(data.countTotal);
    }, [data])

    return (
        !!resetNum || resetNum === 0 ? (
            <Doughnut
                options={{
                    cutout: 50,
                    plugins: {
                        legend: { display: false }
                    },

                    events: [!!data.countTotal ? 'mousemove' : undefined]
                }}
                plugins={[setNum]}
                data={{
                    labels: ['ผู้ถูกประเมินประเมินเสร็จ',
    'เจ้าหน้าที่ประเมินเสร็จ',
    'ผู้บริหารประเมินเสร็จ',
    String(data.countTotal)
],
                    datasets: [{
                        data: !!data.countTotal ? [data.count.assessor, data.count.officer, data.count.sector] : [1, 1, 1],
                        backgroundColor: !!data.countTotal ? [
                            data.color.assessor,
                            data.color.officer,
                            data.color.sector
                        ] : ['#d2dee2', '#d2dee2', '#d2dee2'],
                        datalabels: {
                            display: false
                        },
                    }],
                }}
                width={170}
                height={170}
            />
        ) : (
            <>
            </>

        )

    );
}
