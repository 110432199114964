import { Fetch } from "../../utils/fetch/fetch";
import { FetchConfig } from "@utils/fetch/interface";
import { GetUserListApiProp } from "@interface/pages/userManagement";

export const GetUserListApi = async <T = any>(data: GetUserListApiProp) => {
  let options: FetchConfig = {
    method: "GET",
    path: "/user",
    token: data.token,
    params: data.params || undefined,
  };
  const res = await Fetch<T>(options);
  return res;
};
