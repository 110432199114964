import React from 'react';
import { Button, Col, Row } from 'antd';

interface Props {
    setEditstatus: React.Dispatch<React.SetStateAction<boolean>>;
}

function FormSectorDoing({ setEditstatus }: Props) {
    return (
        <Row justify="center" align="middle" style={{ height: '80%' }}>
            <Col className="form-success center column">
                <div><h2 style={{ fontSize: '2rem' }}>รอดำเนินการตรวจสอบข้อมูล</h2></div>
                <img src="/images/flie-sucess.png" alt="form-success" />
                <div><h2 style={{ margin: 0 }}>ตอนนี้แบบฟอร์มอยู่ระหว่างผู้บริหารประเมิน</h2></div>
                <Button onClick={() => { setEditstatus(false); }} type='default' aria-label='middle' style={{ width: '100%', marginTop: '3rem', height: '40px' }}>
                    <span>ดู</span>
                </Button>
            </Col>
        </Row>
    )
}

export default FormSectorDoing;
