import { Fetch, Fetchfile, FetchFormData2 } from "../../utils/fetch/fetch";

export const GetIndicatorApi = async <T = any>(
  token: string,
  assessmentid: string
) => {
  const res = await Fetch<T>({
    method: "GET",
    path: `/evaluation/${assessmentid}`,
    token: token,
  });
  return res;
};

export const GetEvaluationByID = async <T = any>(token: string, id: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: `/evaluation/id/${id}`,
    token: token,
  });
  return res;
};

export const GetQuestionBySubIdApi = async <T = any>(
  token: string,
  evaluationID: string,
  subIndicatorID: string,
  questionID: number
) => {
  const res = await Fetch<T>({
    method: "GET",
    path: `/evaluation/${evaluationID}/${subIndicatorID}`,
    token: token,
  });
  return res;
};
export const GetQuestionApiById = async <T = any>(
  token: string,
  evaluationID: string,
  subIndicatorID: string,
  questionID: number
) => {
  const res = await Fetch<T>({
    method: "GET",
    path: `/evaluation/${evaluationID}/${subIndicatorID}/${questionID}`,
    token: token,
  });
  return res;
};

export const InsertAnswerById = async <T = any>(
  token: string,
  assessmentid: number,
  data: any
) => {
  const res = await FetchFormData2<T>(
    {
      method: "PATCH",
      path: `/evaluation/${assessmentid}`,
      token: token,
    },
    data
  );

  return res;
};

export const PatchEvaluationById = async <T = any>(
  token: string,
  id: number,
  data: any
) => {
  const res = await FetchFormData2<T>(
    {
      method: "PATCH",
      path: `/evaluation/id/${id}`,
      token: token,
    },
    data
  );

  return res;
};

export const DeleteEvaluationById = async <T = any>(
  token: string,
  id: number,
  data: any
) => {
  const res = await FetchFormData2<T>(
    {
      method: "DELETE",
      path: `/evaluation/${id}`,
      token: token,
    },
    data
  );

  return res;
};

export const sendAnswerSubmit = async <T = any>(
  token: string,
  assessmentid: number | string
) => {
  const res = await Fetch<T>({
    method: "POST",
    path: `/evaluation/${assessmentid}/submit`,
    token: token,
  });
  return res;
};
export const GetAssessmentApi = async <T = any>(
  token: string,
  year: number
) => {
  const res = await Fetch<T>({
    method: "GET",
    path: `/sport/dashboard/${year}`,
    token: token,
  });
  return res;
};

export const GetEvaluationTemplateApi = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: "/evaluation/template",
    token: token,
  });
  return res;
};
export const CreateAssessmentApi = async (
  token: string,
  formData: FormData
) => {
  const res = await FetchFormData2(
    {
      method: "POST",
      path: "/evaluation",
      token: token,
      data: formData,
    },
    formData
  );
  return res;
};

export const GetAchievementInfo = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: "/evaluation/user",
    token: token,
  });
  return res;
};

export const GetAssessmentStep = async <T = any>() => {
  const res = await Fetch<T>({
    method: "GET",
    path: "/admin/assessment",
  });
  return res;
};
export const GetAchievement = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: "/evaluation",
    token: token,
  });
  return res;
};
export const GetYearOfAsses = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: "/evaluation/year",
    token: token,
  });
  return res;
};
export const GetAchievementByYear = async <T = any>(
  token: string,
  year: number
) => {
  const res = await Fetch<T>({
    method: "GET",
    path: `/evaluation/user/${year}`,
    token: token,
  });
  return res;
};
export const GetAchievementByYearAndTitle = async <T = any>(
  token: string,
  year: number,
  title: string
) => {
  const res = await Fetch<T>({
    method: "GET",
    path: `/sport/organizer/${title}/${year}`,
    token: token,
  });
  return res;
};
export const GetAnserPdf = async <T = any>(
  token: string,
  assessorType: string,
  id: number
) => {
  const res = await Fetch<T>({
    method: "GET",
    path: `/evaluation/pdf/addFileSection/${assessorType}/${id}`,
    token: token,
  });
  return res;
};
export const GetAnserPdfAndGen = async (
  token: string,
  assessorType: string,
  id: number
) => {
  const res = await Fetchfile({
    method: "GET",
    path: `/evaluation/pdf/EvaluationPDF/${assessorType}/${id}`,
    token: token,
  });
  return res;
};

export const GetExcelHistoryFileAndGen = async (token: string, id: number) => {
  const res = await Fetchfile({
    method: "GET",
    path: `/evaluation/Excelhistory/${id}`,
    token: token,
  });
  return res;
};
export const InsertCommentReject = async <T = any>(
  token: string,
  comment: any,
  id: number
) => {
  const res = await Fetch<T>({
    method: "PATCH",
    path: `/evaluation/reject/${id}?comment=${comment}`,
    token: token,
  });
  return res;
};
