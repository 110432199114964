import { Fetch } from "../../utils/fetch/fetch";

export const GetDashboardApi = async <T = any>(token: string, year: any) => {
  const res = await Fetch<T>({
    method: "GET",
    path: `/sport/year/${year}`,
    token: token
  });
  return res;
};
export const getInfomationOfSportApi = async <T = any>(token: string, sportName: string, year: any) => {
  const res = await Fetch<T>({
    method: "GET",
    path: `/sport/${sportName}/${year}`,
    token: token
  });
  return res;
};

export const GetDashboardMemberApi = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: "/evaluation/dashboard",
    token: token
  });

  return res;
};
export const GetDashboardMemberApiById = async <T = any>(token: string, id: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: `/evaluation/dashboard/${id}`,
    token: token
  });

  return res;
};

export const GetDashboardYearApi = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: "/evaluation/select",
    token: token
  });

  return res;
};
export const GetYearOfAdminDashboard = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: "/sport/select",
    token: token
  });

  return res;
};


