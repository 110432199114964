import React from "react";
import { Icons } from "@components/icon/RenderIcon/iconStatus";
import { YearOfAchievement } from "@interface/pages/assessment";

import ListItem from "./ListItem";

interface Props {
  values: YearOfAchievement[];
  getAssessmentMemberInfo: () => void;
}

const Lists = ({ values, getAssessmentMemberInfo }: Props) => {
  const getStyles = (ind: number) => {
    const styles = {
      top: {
        borderRadius: "10px 10px 0px 0px",
        border: "1px solid #f0f0f0",
        borderBottom: "1px solid #f0f0f0",
      },
      center: {
        borderRadius: "0px",
        border: "1px solid #f0f0f0",
        borderBottom: "1px solid #f0f0f0",
        borderTop: "0px solid #f0f0f0",
      },
      botton: {
        borderRadius: "0px 0px 10px 10px",
        border: "1px solid #f0f0f0",
        borderBottom: "1px solid #f0f0f0",
        borderTop: "0px solid #f0f0f0",
      },
    };
    return ind === 0
      ? styles["top"]
      : ind === values.length - 1
      ? styles["botton"]
      : styles["center"];
  };

  return (
    <React.Fragment>
      {!!values &&
        values.map((item, index) => (
          <React.Fragment key={index}>
            <div style={{ ...getStyles(index), padding: 10 }}>
              <ListItem
                values={item}
                icon={Icons.find((icon) => icon.status === item.status)?.icon}
                getAssessmentMemberInfo={getAssessmentMemberInfo}
              />
            </div>
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

export default Lists;
