// import { lazy } from "react";
import { RoutesInterface } from "./interface/router";
import { TypesLayout } from "../interface/theme";

import Page403 from "../page/403";
import Page404 from "../page/404";
import Page500 from "../page/500";
import UserProfile from "../page/UserProfile";

// const Document= lazy(() => import("../page/Document"))
// const UserProfile= lazy(() => import("../page/UserProfile"))
// const Page500= lazy(() => import("../page/500"))
// const Page403= lazy(() => import("../page/403"))
// const Page404= lazy(() => import("../page/404"))

export default [
  {
    name: "Profile",
    path: "/profile/",
    exact: true,
    layout: TypesLayout.main,
    sidebar: false,
    component: UserProfile,
  },
  {
    path: "/500",
    exact: true,
    layout: TypesLayout.none,
    sidebar: false,
    component: Page500,
  },
  {
    path: "/401",
    exact: true,
    layout: TypesLayout.none,
    sidebar: false,
    component: Page403,
  },
  {
    path: "**",
    exact: true,
    layout: TypesLayout.none,
    sidebar: false,
    component: Page404,
  },
] as RoutesInterface[];
