import React from 'react';
import ChartCom from '../Chart/chart';
import { CaretDownOutlined } from '@ant-design/icons';
import { Row, Col, Select } from 'antd';
import { useAuthContextState } from '@authContext/store';
import { GetDashboardMemberApiById, GetDashboardYearApi } from '@services/api/dashboard';
import Chnage from '@assets/images/Chnage.png';

const { Option } = Select;

interface Props {
    year: any[];
}

function CompairProject({ year }: Props) {

    const [yearValueLeft, setYearValueLeft] = React.useState<string>('')
    const [yearValueRight, setYearValueRight] = React.useState<string>('')
    const [projectLeft, setProjectLeft] = React.useState<any[] | []>([])
    const [projectRight, setProjectRight] = React.useState<any[] | []>([])
    const [projectValueLeft, setProjectValueLeft] = React.useState<string>('')
    const [projectValueRight, setProjectValueRight] = React.useState<string>('')
    const [variableOne, setVariableOne] = React.useState<any | []>([])
    const [variableTwo, setVariableTwo] = React.useState<any | []>([])
    const { token } = useAuthContextState()

    const filterProjectOfYear = async (value: string, position: string) => {
        if (position === 'left') {
            const Data = year.filter((data: { year: string; }) => {
                return data.year === value
            })
            setYearValueLeft(value)
            setProjectLeft(Data[0].project)
            setProjectValueLeft(Data[0].project[0].title)
            handleChangeProjct(Data[0].project[0].id, position)
        }
        else if (position === 'right') {
            const Data = year.filter((data: { year: string; }) => {
                return data.year === value
            })
            setYearValueRight(value)
            setProjectRight(Data[0].project)
            setProjectValueRight(Data[0].project[0].title)
            handleChangeProjct(Data[0].project[0].id, position)
        }
    }
    const handleChangeProjct = async (e: string, postion: string) => {
        const { res } = await GetDashboardMemberApiById(token.accesstoken, e)

        if (res.status === 'success') {
            if (postion === 'left') {
                setVariableOne([res.result.data[0].criterion])
                setProjectValueLeft(e)
            }
            else if (postion === 'right') {
                setVariableTwo([res.result.data[0].criterion])
                setProjectValueRight(e)
            }

        }
    }

    const getYearsAndProjectData = async () => {
        const { res } = await GetDashboardYearApi(token.accesstoken)
        if (res.status === 'success') {
            setYearValueLeft(res.result[0].year || [])
            setYearValueRight(res.result[0].year || [])
            setProjectLeft(res.result[0].project || '')
            setProjectRight(res.result[0].project || '')
        }
    }

    React.useEffect(() => {
        getYearsAndProjectData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="DashMember-col-background ">
            <div className="DashMember-chart-title" style={{ flexWrap: 'wrap', }}>
                การแสดงข้อมูลสถิติ
                <Row className='data-chart-compare' gutter={[6, 6]} >
                    <Col xs={24} xl={11} className='box-of-select' >
                        <Row className='tab-variable-and-select' >
                            <Row className='tab-variable'>
                                <div className='tab-variableOne-color' />
                                <span>
                                    ตัวแปรที่่ 1
                                </span>
                            </Row>
                            <Row className='tab-select' >
                                <div className="hn-select-style-inChart" style={{ width: "100%" }}>
                                    <div className="hn-select-btn-spacing" >
                                        <Select
                                            onChange={(e: string) => filterProjectOfYear(e, 'left')}
                                            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                                            value={yearValueLeft}
                                        >
                                            {year.map((data, id) => (
                                                <Option key={id} value={data.year}>ปี :  {data.year}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="hn-select-btn-spacing" style={{ marginLeft: '10px', width: '100%' }}>
                                        <Select
                                            style={{ width: '100%' }}
                                            onChange={(e: string) => handleChangeProjct(e, 'left')}
                                            value={projectValueLeft}
                                            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                                        >
                                            {projectLeft?.map((data, id) => (
                                                <Option key={id} value={data.id} name={data.title}>{data.title}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </Row>
                        </Row>
                    </Col>
                    <Col xs={23} xl={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='box-image' >
                            <div className='imageCompaire' >
                                <img alt="iconLock" src={Chnage} className="assessmentListMember-iconStatus" width={25} height={20} />
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} xl={11} className='box-of-select'>
                        <Row className='tab-variable-and-select' >
                            <Row className='tab-variable'>
                                <div className='tab-variableTwo-color' />
                                <span>
                                    ตัวแปรที่่ 2
                                </span>
                            </Row>
                            <Row className='tab-select'>
                                <div className="hn-select-style-inChart" style={{ width: "100%" }} >
                                    <div className="hn-select-btn-spacing">
                                        <Select
                                            onChange={(e: string) => filterProjectOfYear(e, 'right')}
                                            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                                            value={yearValueRight}
                                        >
                                            {year.map((data, id) => (
                                                <Option key={id} value={data.year}>ปี :  {data.year}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="hn-select-btn-spacing" style={{ marginLeft: '10px', width: "100%" }}>
                                        <Select
                                            style={{ width: '100%' }}
                                            onChange={(e: string) => handleChangeProjct(e, 'right')}
                                            value={projectValueRight}
                                            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                                        >
                                            {projectRight?.map((data, id) => (
                                                <Option key={id} value={data.id} name={data.title}>{data.title}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </Row>
                        </Row>
                    </Col>
                </Row>
            </div>
            <ChartCom variableOne={variableOne} variableTwo={variableTwo} />
        </div>
    )
}
export default CompairProject;
