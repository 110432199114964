import React, { useEffect, useState } from 'react'
import { Button, Input, Row, Col, Select, Form } from 'antd';
// import { role, group } from './mockData'
import { FormFilterOnSearch } from '@interface/pages/userManagement'
import { _isEmpty } from '@yim/util-tool-ts'
import { GetUserRole } from '@services/api/FromUser';
import { useAuthContextState } from '@authContext/store'
import { useHistory } from 'react-router-dom';
interface Props {
  onClick: (event: FormFilterOnSearch) => void;
  onReset: () => void;
}
interface dataObject {
  [key: string]: any
}

const Main = ({ onClick, onReset }: Props) => {

  const { token } = useAuthContextState()
  const history = useHistory()

  const [form] = Form.useForm();
  const [role, setRole] = useState<{ label: string; value: string }[]>([{
    "label": "แอดมิน",
    "value": "superuser"
  },])

  const handleClick = (fieldValue: { email: string; group: string; role: string; }) => {
    let data: dataObject = {}
    for (const [key, value] of Object.entries(fieldValue)) {
      console.log(`${key}: ${value}`);
      if (!_isEmpty(value)) {
        data[key] = value;
      }
    }
    onClick(data)
  }
  useEffect(() => {
    getRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRoles = async () => {
    try {
      const { res } = await GetUserRole(token.accesstoken)
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        setRole(res.result)
      }
    } catch (error: any) {
      history.push(error.message)
    }
  }
  return (
    <Form form={form} onFinish={handleClick}>
      <Row>
        <Col sm={8} md={8} className="w-100" >
          <div >
            <div className="user-formSearch">
              <div className="user-form-font" >
                <span >ค้นหา</span></div>
              <Form.Item
                className="user-form  user-fromBox"
                name="email"
              >
                <Input className="user-input-font"
                  style={{ height: '45px' }}
                  placeholder="ค้นหา / อีเมล์" />
              </Form.Item>

            </div>
          </div>

        </Col>
        {/* <Col sm={8} md={8} className="w-100" >

          <span className="user-form-font " >กลุ่ม</span>
          <Form.Item className="user-form user-fromBox w-100" name="group">
            <Select
              className="form-button-arrow "
              placeholder="เลือก">
              {group.map(item => (
                <Select.Option
                  className="user-select-input"
                  value={item.company}> {item.company}
                </Select.Option>))}
            </Select >
          </Form.Item>

        </Col> */}
        <Col sm={8} md={8} className="w-100" >
          <span className="user-form-font" >บทบาท</span>
          <div>
            <Form.Item className="user-form user-fromBox" name="role">
              <Select
                className="form-button-arrow w-100"
                placeholder="เลือก"  >
                {role.map((item, ind) => (
                  <Select.Option value={item.value} key={ind}> {item.label} </Select.Option>))}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <div className="user-button w-100">
        <Button
          className="user-btn-search"
          style={{ margin: '3px', }}
          htmlType="submit"
        // suffix={suffix}
        // onClick={onSearch}
        >ค้นหา
        </Button>
        <Button
          className="user-btn-reset w-100"
          style={{ margin: '3px' }}
          onClick={() => {
            form.resetFields();
            onReset()
          }}
        >รีเซ็ต</Button>
      </div>
    </Form>
  )
}

export default Main
