import React from 'react'
import ListMenu from './MenuList';
import { Row, Col, Skeleton } from 'antd';
import CardLists from './CardList/CardList';
import { useHistory } from 'react-router-dom';
import { useAuthContextState } from '@authContext/store';
import { GetAssessmentApi } from '@services/api/assessment'
import { AssessmentProps, AssessmentInfoProps, SportItem } from '@interface/pages/assessment'
// interface Props {
// }

const AssessmentList = (props: AssessmentProps) => {
  const [AssessmentInfo, setAssessmentInfo] = React.useState<AssessmentInfoProps | null>(null)
  const [sportList, setSportList] = React.useState<SportItem | null>(null)
  const [menuActive, setMenuActive] = React.useState<string>("สมาคม")
  const [loading, setLoading] = React.useState<boolean>(true)
  const { token } = useAuthContextState()
  const history = useHistory()
  const getyear = new Date()

  const getAssessmentInfo = async () => {

    try {
      const { res } = await GetAssessmentApi(token.accesstoken, getyear.getFullYear())
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.status === 'success') {
        setAssessmentInfo(res.result.data[0])
        FilterSportByRole(menuActive, res.result.data[0])
        setLoading(false)
      }
    } catch (error: any) {
      history.push(error.message)
    }
  }
  React.useEffect(() => {
    getAssessmentInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    AssessmentInfo && FilterSportByRole(menuActive, AssessmentInfo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuActive])

  const FilterSportByRole = (role: string, values: AssessmentInfoProps) => {
    const newValues = values.consensus.filter(value => value.assessorType === role)
    setSportList(newValues[0])
  }

  const handleChangeMenu = (value: SportItem) => {
    setSportList(value)
  }

  return (
    <Row gutter={[4, 4]} >
      <Col xs={24} md={6}>
        <div className="boxleft-ListAssessment">
          {!!loading && <div style={{ padding: 20 }}>
            <Skeleton active />
            <Skeleton active />
          </div>}
          {AssessmentInfo && <ListMenu setAssessmentInfo={setAssessmentInfo} values={AssessmentInfo} onChange={handleChangeMenu} menuActive={menuActive} setMenuActive={setMenuActive} />}
        </div>
      </Col>
      <Col xs={24} md={18} >
        <div className="boxright-ListAssessment" style={{ height: "85vh", overflow: "auto" }}>
          {!!loading && <div style={{ padding: 20 }}>
            <Skeleton active />
            <Skeleton active />
          </div>}
          {!!sportList && <CardLists year={AssessmentInfo?.year} value={sportList} />}
        </div>
      </Col>
    </Row>

  )
}
export default AssessmentList