import React, { useState } from 'react'
import { Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { useAuthContextState } from '@authContext/store';
import { sendAnswerSubmit } from '@services/api/assessment';
import { useHistory } from 'react-router-dom';

interface Props {
  values: ItemProps;
}
interface ItemProps {
  disableBottonSend: boolean;
  id: string;
  status?: string;
  percent?: number;
  icon: React.ReactNode;
  // color?: "yellow" | "green" | "blue" | "done" | "gray";
}
const AssessmentButtonSubmit = ({ values }: Props) => {

  const { token } = useAuthContextState()
  const [isLoading, setIsloading] = useState(false)
  const history = useHistory()

  const sendQuistion = async (id: string) => {
    setIsloading(true)
    const { res } = await sendAnswerSubmit(token.accesstoken, Number(id))
    if (res.code === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'บันทึกข้อมูลสำเร็จ',
        showConfirmButton: false,
        timer: 1000
      }).then(() => {
        setIsloading(false)
        history.goBack()
      })
    }
  }

  return (
    <Button disabled={values.percent !== 100 || values.status === 'success' || values.disableBottonSend} loading={isLoading} onClick={() => { sendQuistion(values.id) }} className="submit-done indicator-textsubmit" icon={< SendOutlined />} >
      ส่ง
    </Button >
  )
}
export default AssessmentButtonSubmit