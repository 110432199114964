import { Answer, Option } from "@interface/assessment";
import { Col, Row, InputNumber, Form } from "antd"
export interface Props {
  values: Answer | Option;
  disabled: boolean;
  variablePoint?: boolean;

}
const InputDecimalNumber = ({ values, disabled, variablePoint }: Props) => {

  const html = values["description"] || ``
  return (
    <div >
      <div className="text-Answer">
        {values["title"] || "ใส่ข้อมูลตัวเลข"}
      </div>
      <Row gutter={[6, 6]} align="middle">
        <Col>
          <Form.Item name={values["keyName"]} rules={[{ required: (values["keyName"] === "inputNumber" || values["keyName"] === "inputNumber1") && !variablePoint ? true : false, message: 'กรุณากรอกข้อมูล' }]}>
            <InputNumber<string> min={"0"} step="0.01" disabled={disabled} />
          </Form.Item>
        </Col>
        <Col>{values["unit"] || "ใส่ข้อมูลตัวเลข"}</Col>
      </Row>

      <span dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

export default InputDecimalNumber
