import { Steps } from 'antd'
import { StepIndex } from '@interface/assessment';

const { Step } = Steps;
interface Props {
  stepInfo: StepIndex[]
  current: number
}

const StepsComponent = ({ stepInfo, current }: Props) => {
  return (
    <div className="indicator-Steps">
      <Steps className="content-step" current={current} >
        {stepInfo.map((item, ind) => (
          <Step
            key={item.stapIndex}
            status={(current > (ind)) ? "finish" : current === (ind) ? "process" : "wait"}
            icon={((current > (ind)) || ((stepInfo[ind].status === "success") && current !== (ind))) ? <div className="step-item" style={{ width: 48, height: 48, color: "#fff" }}>{ind + 1}</div> : null}
          />
        ))}
      </Steps>
    </div>
  )
}

export default StepsComponent
