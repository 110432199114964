import React, { useState } from 'react'
import { Row, Col, Form } from 'antd';
import Swal from 'sweetalert2'
import Formfilter from '@components/form/FormUser/formfilter'
import ContentPage from '@layout/MainLayout/Header/Content';
import { FormUserItem } from '@interface/pages/userManagement'
import { CreateFromUserApi } from '@services/api/FromUser'
import User from '@components/form/FormUser/index'
import { useAuthContextState } from '@authContext/store'
import { ROLE_ENUM } from '@interface/role'
import { useHistory } from 'react-router-dom'
interface Props {

}

const CreateUser = (props: Props) => {
  const { token } = useAuthContextState()
  const history = useHistory()
  const [form] = Form.useForm();
  const [isLoading, setIsloading] = React.useState<boolean>(false);
  const [role, setRole] = useState(ROLE_ENUM.ASSESSOR)

  const handleFinish = async (values: FormUserItem) => {
    if (values.file !== undefined) {
      setIsloading(true)
      const form = new FormData()
      form.append('file', values.file.originFileObj)
      form.append('address', values.address)
      form.append('contactName', values.contactName)
      form.append('contactPhone', values.contactPhone)
      form.append('phone', values.phone)
      form.append('role', values.role)
      form.append('title', values.title)
      form.append('password', values.password)
      form.append('email', values.email)
      if (values.role !== 'assessor') {
        form.append('sport', "")
        form.append('assessorType', "")
        form.append('isCreateForm', "")
      } else {
        form.append('sport', values.sport)
        form.append('assessorType', values.assessorType)
        form.append('isCreateForm', values.isCreateForm)
      }

      const res = await CreateFromUserApi(form, token.accesstoken);
      if (res.code !== 200) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: res.message,
          showConfirmButton: false,
          timer: 1500
        })
        setIsloading(false)
        return
      }
      if (res.code === 200) {
        const ConfirmButton = await Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'บันทึกข้อมูลสำเร็จ',
          showConfirmButton: false,
          timer: 1500
        })
        setIsloading(false)
        ConfirmButton && history.push('/admin/user')
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: res.message,
          showConfirmButton: false,
          timer: 1500
        })
        setIsloading(false)
        return
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'โปรดเลือกรูปภาพ',
        showConfirmButton: false,
        timer: 1500
      })
      setIsloading(false)
      return
    }
  };

  const handleReset = () => {
    console.log("onReset");
  };

  const onFieldsChange = (_: any[], allFields: any[]) => {
    if (_[0].name[0] === "role") {
      console.log("_", _);
      setRole(_[0].value)
    }
  }

  return (
    <ContentPage
      headerContent={
        <div className="hn-title-center" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div className="hn-title-text" >
            <div className="text-title-white">จัดการผู้ใช้</div>
            <div className="header-font-subtitle">{role}</div>
          </div>
        </div>
      }
    >
      <div>
        <Form onFinish={handleFinish} form={form} onFieldsChange={onFieldsChange} >
          <Row gutter={[0, 12]}>
            <div className="form-box ">
              <Row >
                <Col xs={24} sm={12} md={12} lg={12} >
                  <div className="text-title title-text " >เพิ่มจัดการผู้ใช้</div>
                </Col>
                <Col xs={24} sm={10} md={12} lg={12} className="w-100"  >
                  <Formfilter isLoading={isLoading} onReset={handleReset} onFinish={handleFinish} />
                </Col>
              </Row>
            </div>
            <Col sm={24} md={24}>
              <div className="form-box " >
                <User valueImage={null} onFinish={handleFinish} page="default" role={role} form={form} />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </ContentPage>
  )
}

export default CreateUser
