import { Col, Row, Avatar, Badge, Button } from "antd";
import AssessmentIcon from "@components/icon/RenderIcon/assessmentIcon";
import { YearOfAchievement } from "@interface/pages/assessment";
import appConfig from "@config/app.config";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuthContextState } from "@authContext/store";
import { Icons } from "@components/icon/RenderIcon/iconStatus";
import DownloadFile from "@page/admin/assessment/download/downloadFile";
import { motion } from "framer-motion";
import { ROLE_ENUM, ASSESSOR_TYPE_ENUM } from "@interface/role";
import { userInfo } from "os";
import { useEffect, useState } from "react";

interface Props {
  values: YearOfAchievement;
  icon: React.ReactNode;
}
// const statusKey = {
//   draft: "สร้างแบบประเมินล่วงหน้า",
//   success: "เสร็จสมบูรณ์",
//   grading: "อยู่ระหว่างเจ้าหน้าที่ประเมิณ",
//   document: "อยูระหว่างแนบหลักฐาน",
//   sector_doing: "อยู่ระหว่างทุกภาคส่วนประเมิณ",
// }

const ListItem = ({ values, icon }: Props) => {
  let history = useHistory();

  const { userInfo } = useAuthContextState();


  const EditButton = () => {
    if ((userInfo.assessorType === "organizer") && (userInfo.role === ROLE_ENUM.ASSESSOR)) {
      return (
        <div>
          <Button
            onClick={() => history.push(`/member/assessment/edit/${values.id}`)}
          >
            แก้ไข
          </Button>
        </div>
      );
    }else{
      return null;
    }
  };

  const handleDoubleClick = (path: string, status: string) => {
    status !== "draft" && history.push(path);
  };
  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <button
        onDoubleClick={() =>
          handleDoubleClick(`/assessment/${values.id}`, values.status)
        }
        style={{
          backgroundColor: "transparent",
          border: "unset",
          width: "100%",
        }}
      >
        <Row className="text-black assessmentListMember-form">
          <Col xs={13} xl={14} className="assessmentListMember-textsport">
            <div className="assessmentListMember-title-list">
              <Col xl={3} style={{ display: "flex" }}>
                <div className="assessmentListMember-list-imgBorder">
                  <Avatar
                    size={60}
                    src={` ${
                      values.image?.url
                        ? appConfig.apiURL + "/" + values.image?.url
                        : "/images/logo-defalut.png"
                    } `}
                  />
                </div>
              </Col>
              <Col xl={22}>
                <div className="assessmentListMember-list-programDetail">
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="assessmentListMember-list-programName">
                      {values.title}
                    </div>
                    <div className="assessmentListMember-list-red">
                      ( การประเมินผลสัมฤทธิ์ {values.year} )
                    </div>
                  </div>
                  <Row className="assessmentListMember-list-date">
                    {userInfo.assessorType === "association" ? (
                      <Col lg={24} xl={8} className="list-date-title">
                        <div className="list-date-detail">
                          วันที่สร้างแบบประเมิน{" "}
                        </div>
                        <div>{values.startDate || "-"}</div>
                      </Col>
                    ) : (
                      <Col lg={24} xl={8} className="list-date-title">
                        <div className="list-date-detail">วันเริ่มโครงการ </div>
                        <div>{values.startDate || "-"}</div>
                      </Col>
                    )}

                    {userInfo.assessorType === "organizer" && (
                      <Col lg={24} xl={8} className="list-date-title">
                        <div className="list-date-detail">
                          วันสิ้นสุดโครงการ
                        </div>
                        <div>{values.endDate || "-"}</div>
                      </Col>
                    )}
                    {userInfo.assessorType === "association" ? (
                      <Col lg={24} xl={8} className="list-date-title">
                        <div className="list-date-detail">
                          วันที่ประเมินเสร็จสมบูรณ์
                        </div>
                        <div>{values.evaluationEndDate || "-"}</div>
                      </Col>
                    ) : (
                      <Col lg={24} xl={8} className="list-date-title">
                        <div className="list-date-detail">
                          วันสิ้นสุดการประเมิน
                        </div>
                        <div>{values.evaluationEndDate || "-"}</div>
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>
            </div>
          </Col>
          <Col xs={9} xl={9}>
            <Row
              gutter={[5, 5]}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col xs={10} xl={10}>
                <DownloadFile id={values.id} value={values.pdfButton} />
              </Col>

              <EditButton/>

              <Col
                xs={10}
                xl={10}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Badge
                  dot={values?.comment.officer !== null}
                  size="default"
                  offset={[-2, 2]}
                >
                  <div className="assessmentListMember-boxsuccess">
                    <AssessmentIcon
                      values={{
                        status: values.status,
                        path: `/assessment/${values.id}`,
                        title: values.status,
                        icon: Icons.find(
                          (icon) => icon.status === values.status
                        )?.icon,
                      }}
                    />
                  </div>
                </Badge>
              </Col>
            </Row>
            <Row></Row>
          </Col>
        </Row>
      </button>
    </motion.div>
  );
};

export default ListItem;
