import React, { useState } from 'react';
import { Select } from 'antd';
import Swal from 'sweetalert2';
import { name_type } from '../List/CardList/CardListIcon';
/* import { LoadingOutlined } from '@ant-design/icons'; */
import { CaretDownOutlined } from '@ant-design/icons';
import { useAuthContextState } from '@authContext/store';
import { GetAnserPdf, GetAnserPdfAndGen } from '@services/api/assessment';
const { Option } = Select;
interface Props {
    value: any
    id: number
}

function DownloadFile({ value, id }: Props) {

    const { token } = useAuthContextState()
    const [loading, setLoading] = useState(false)
    /* const [valueName, setValueName] = useState<string | null>() */

    const getPdfFile = async (role: string) => {
        /* setValueName(name_type.find((name) => name.role === role)?.roleTH) */
        setLoading(true)
        try {
            const { res } = await GetAnserPdf(token.accesstoken, role, id)
            if (!res) throw new Error("/500");
            if (res.code === 401) throw new Error("/401");
            if (res.code === 200) {
                const res = await GetAnserPdfAndGen(token.accesstoken, role, id)
                if (!res) throw new Error("/500");
                if (res.message === "success") {
                    const url = window.URL.createObjectURL(new Blob([res.res]))
                    const link = document.createElement('a');
                    link.href = url
                    link.setAttribute("download", "ข้อมูลรายการประเมิน.pdf")
                    document.body.appendChild(link);
                    link.click()
                    setLoading(false)
                }
            }
            setLoading(false)
        } catch (error: any) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: error.message,
                showConfirmButton: false,
                timer: 1500
            })
            setLoading(false)
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <Select
                size="middle"
                value={!!loading ? 'กำลังโหลด...' : 'ดาวน์โหลดไฟล์'}
                style={{ width: '150px' }}
                disabled={!!loading}
                defaultValue="ดาวน์โหลดไฟล์"
                onChange={(valueSelect: any) => getPdfFile(valueSelect)}
                suffixIcon={<CaretDownOutlined style={{ color: '#000', height: '100px' }} />}
            >
                {!!value && !loading && value.map((data: { role: string }, index: any) => (
                    <Option key={String(index)} value={data.role} >{name_type.find((name) => name.role === data.role)?.roleTH}</Option>
                ))}
            </Select>
        </div>
    )
}

export default DownloadFile;
