import React, { useEffect, useState } from 'react'
import { Row, Card, Col, Typography, Badge } from 'antd';
import { SportItem } from '@interface/pages/assessment'
import { useHistory } from "react-router-dom";
import { SportImages } from './CardListIcon';
import ListAchive from './ListAchievementIcon';
import DownloadFile from '../../download/downloadFile';
import AssessmentIcon from '@components/icon/RenderIcon/assessmentIcon';
import { Icons } from '@components/icon/RenderIcon/iconStatus';
import { motion } from 'framer-motion'

interface Props {
  value: SportItem;
  year?: any;
}

const CardList = ({ value, year }: Props) => {
  const history = useHistory()
  const [openList, setOpenList] = useState<boolean>(false)
  const [valueOfListAchiv, setValueOfListAchiv] = useState<any>({
    title: '',
    year: 0,
  })

  const handleOpen = (title: string, year: number) => {
    setValueOfListAchiv({
      title: title,
      year: year - 543,
    })
    setOpenList(!openList)
  }

  const getStyles = (ind: number,) => {
    const styles = {
      top: { borderRadius: '10px 10px 0px 0px', borderBottom: '0px solid' },
      one: { borderRadius: '10px 10px 10px 10px', border: '1px solid #f0f0f0' },
      center: { borderRadius: '0px', borderBottom: '0px solid' },
      botton: { borderRadius: '0px 0px 10px 10px', borderBottom: '1px solid #f0f0f0' },
    }
    return value.consensusSport.length === 1 ? styles['one'] : ind === 0 ? styles['top'] : ind === value.consensusSport.length - 1 ? styles['botton'] : styles['center']
  }
  const handleDoubleClick = (path: string, status: string) => {
    (status !== "draft") && history.push(path)
  }
  useEffect(() => {
    setOpenList(false)
  }, [value])

  return (
    !openList ? (
      <div style={{ marginTop: '10px' }}>
        <h1 className="text-title">เลือกประเภทกีฬา</h1>
        {!!value.consensusSport.length && value.consensusSport.map((sport, ind) => (
          <React.Fragment key={ind}>
            {value.assessorTypeENG !== 'organizer' ? (
              <motion.div key={1} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <button
                  onDoubleClick={() => handleDoubleClick(`assessment/${sport.id}`, sport.status)}
                  style={{ backgroundColor: 'transparent', width: '100%', borderStyle: 'none' }}
                >
                  <Card
                    style={getStyles(ind)}
                  >
                    <Row className="assessmentList-contentincard" >
                      <Col xs={5} xl={7} style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                        <img
                          alt="iconsport"
                          width={30}
                          src={SportImages.find(img => img.title === sport.key)?.icon} />
                        <div className="assessmentList-textsport" >
                          {sport.title}
                        </div>
                      </Col>
                      <Col xs={15} md={13} xl={11} xxl={13} lg={14} >
                        <Row gutter={[8, 8]} justify="space-between">
                          <Col xs={23} xl={8} md={8} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                            <DownloadFile id={sport.id} value={sport.pdfButton} />
                          </Col>
                          <Col xs={23} md={15} xl={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Badge dot={!!sport?.comment} size="default" offset={[-2, 2]}>
                              <Typography.Text
                                key={ind}
                                onClick={() => { value.assessorTypeENG !== 'organizer' ? history.push(`assessment/${sport.id}`) : handleOpen(sport.title, year) }}
                              >
                                <div className="assessmentList-boxsuccess" >
                                  <AssessmentIcon values={{
                                    status: sport.status,
                                    path: 'none',
                                    title: sport.status,
                                    icon: Icons.find((icon) => icon.status === sport.status)?.icon
                                  }}
                                  />
                                </div>
                              </Typography.Text>
                            </Badge>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </button>
              </motion.div>
            ) : (
              <motion.div key={2} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <button
                  style={{ borderStyle: 'none', backgroundColor: 'transparent', width: '100%' }}
                  key={ind}
                  onClick={() => { value.assessorTypeENG !== 'organizer' ? history.push(`assessment/${sport.id}`) : handleOpen(sport.title, year) }}
                >
                  <Card
                    className="assessmentList-crad"
                    style={getStyles(ind)}
                  >
                    <Row className="assessmentList-contentincard">
                      <Col >
                        <img
                          alt="iconsport"
                          width={30}
                          src={SportImages.find(img => img.title === sport.key)?.icon} />
                        <span className="assessmentList-textsport" >
                          {sport.title}
                        </span>
                      </Col>
                    </Row>
                  </Card>
                </button>
              </motion.div>
            )}
          </React.Fragment>
        ))}
        {!value.consensusSport.length && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Row className="assessmentListMember-boxcontent" justify="center" style={{ height: '90%' }}>
              <Col className="center-column-admin" >
                <h2 className="title-FormAssessmentList" style={{ position: 'absolute', top: "0px" }}>แบบประเมินผลสัมฤทธิ์</h2>
                <img src="/images/no-data.png" alt="no data" style={{ opacity: 0.65, width: '250px', marginTop: '25px' }} />
                <h2 className="text-primary" style={{ position: 'absolute', bottom: "5px", marginLeft: '15px' }}>ยังไม่มีแบบประเมินผลสัมฤทธิ์</h2>
              </Col>
            </Row>
          </motion.div>
        )}
      </div >
    ) : (
      <ListAchive setOpen={setOpenList} valueOfListAchiv={valueOfListAchiv} />
    )
  )
}
export default CardList