import React, { Suspense } from 'react';
import { AuthContextProvider } from './context/Auth/store'
import { HashRouter } from 'react-router-dom'
import RenderRoute from '@routes/RenderRoute';
import { AnimatePresence } from "framer-motion";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
function App() {

  return (
    <AnimatePresence>
      <AuthContextProvider>
        <HashRouter >
          <Suspense fallback={loading}>
            <RenderRoute />
          </Suspense>
        </HashRouter>
      </AuthContextProvider >
    </AnimatePresence>
  );
}

export default App;