import React from "react";
import Swal from "sweetalert2";
import { Switch, Route, Redirect } from "react-router-dom";

import { TypesLayout } from '@interface/theme'
import { RoutesInterface, GetRoutes } from '@routes/interface/router'
import { getRoutes, getMenuSidebar } from './index'

import MainLayout from '@layout/MainLayout'
import DefaultLayout from '@layout/DefaultLayout'
import { useAuthContextDispatch, useAuthContextState } from '@authContext/store'
import { isTokenExpired } from "@utils/utils";

interface Props {
  routePermissions?: GetRoutes
}

const RenderRoute = ({ routePermissions = "auth" }: Props) => {
  const { userInfo, auth, token } = useAuthContextState()
  const { _signOut } = useAuthContextDispatch()
  const _authRouters = getRoutes("auth");
  const routers = getRoutes(userInfo.role);
  const menuSidebar = getMenuSidebar(userInfo.role);

  React.useEffect(() => {
    if (!!auth) {
      const isExpired = isTokenExpired(token.accesstoken)
      if (isExpired) {
        Swal.fire({
          position: 'center',
          icon: "warning",
          title: 'รหัสของคุณหมดอายุ!',
          text: "กรุณาเข้าระบบใหม่อีกครั้ง",
          confirmButtonColor: 'red'
        }).then(() => {
          _signOut()
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, _signOut])

  return (
    <Switch>
      {!auth && _authRouters.map(route => (
        !route.redirectTo ? <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
          : <Route key={route.path} exact={route.exact} path={route.path} component={() => <Redirect to={{ pathname: route.redirectTo, state: { from: route.path } }} />} />
      ))}
      {/* {auth && <Route path="/" component={() => <MainLayout router={routers} sidebar={menuSidebar} />} />} */}
      {auth && (
        routers.map(route => (
          route.layout === TypesLayout.none ?
            !!route.redirectTo
              ? (<Route key={route.path} exact={route.exact} path={route.path} component={() => <Redirect to={{ pathname: route.redirectTo, state: { from: route.path } }} />} />)
              : (<Route key={route.path} exact={route.exact} path={route.path} component={route.component} />)
            : (<Route key={route.path} exact={route.exact} path={route.path} component={() => (route.layout === TypesLayout.main) ? <MainLayout router={routers} sidebar={menuSidebar} /> : <DefaultLayout router={routers} />} />)
        ))
      )}
    </Switch >
  )
}
export default React.memo(RenderRoute)

/* export const RenderMainRoute = ({ routePermissions = "auth" }: Props) => {
  const { userInfo } = useAuthContextState()
  const routers = getRoutes(userInfo.role);
  const menuSidebar = getMenuSidebar(userInfo.role);

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Switch>
      {routers.map((route, ind) => (route.layout === TypesLayout.none
        ? !!route.redirectTo
          ? (<Route key={route.path} exact={route.exact} path={route.path} component={() => <Redirect to={{ pathname: route.redirectTo, state: { from: route.path } }} />} />)
          : (<Route key={route.path} exact={route.exact} path={route.path} component={route.component} />)
        : (<Route key={route.path} exact={route.exact} path={route.path} component={() => (route.layout === TypesLayout.main) ? <MainLayout router={routers} sidebar={menuSidebar} /> : <DefaultLayout router={routers} />} />)
      ))}
    </Switch>
  )
}
 */
export const RenderLayoutRoute = ({ router }: { router: RoutesInterface[] }) => {
  return (
    <Switch>
      {router.map((route, ind) => !!route.redirectTo
        ? (<Route key={route.path} exact={route.exact} path={route.path} component={() => <Redirect to={{ pathname: route.redirectTo, state: { from: route.path } }} />} />)
        : (<Route key={route.path} exact={route.exact} path={route.path} component={route.component} />))}
    </Switch>
  )
}


