import { lazy } from "react";
import { TypesLayout } from "@interface/theme";
import { RoutesInterface } from "./interface/router";

// import LoginPage from "@page/login/LoginPage";
// const Page404 = lazy(() => import("@page/404"));
// const Page403 = lazy(() => import("@page/403"));
// const Page500 = lazy(() => import("@page/500"));
const LoginPage = lazy(() => import("@page/login/LoginPage"));

export default [
  {
    path: "/",
    exact: true,
    layout: TypesLayout.none,
    sidebar: false,
    redirectTo: "/login",
  },
  {
    path: "/login",
    exact: true,
    layout: TypesLayout.none,
    sidebar: false,
    component: LoginPage,
  },
  {
    path: "**",
    exact: true,
    layout: TypesLayout.none,
    sidebar: false,
    redirectTo: "/login",
  },
] as RoutesInterface[];
