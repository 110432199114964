import adminRoutes from "./admin";
import officerRoutes from "./officerAndSector";
import sectorRoutes from "./officerAndSector";
import memberRoutes from "./member";
import defaultRoutes from "./default";
import authRoutes from "./auth";
import { Routers, RoutesInterface, GetRoutes } from "./interface/router";

const routers: Routers = {
  auth: authRoutes,
  sector: sectorRoutes,
  officer: officerRoutes,
  superuser: adminRoutes,
  assessor: memberRoutes,
};

export const getRoutes = (permissions: GetRoutes): RoutesInterface[] => {
  if (permissions === "auth") {
    return [...routers["auth"]];
  } else if (
    permissions === "assessor" ||
    permissions === "superuser" ||
    permissions === "sector" ||
    permissions === "officer"
  ) {
    return [...routers[permissions], ...defaultRoutes];
  } else {
    return [...routers["auth"]];
  }
};
export const getMenuSidebar = (permissions: GetRoutes): RoutesInterface[] => {
  const routerPermissions = getRoutes(permissions);
  return routerPermissions.filter((route) => !!route.sidebar);
};
