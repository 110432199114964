import React from 'react';
import { Col, Form, Row } from 'antd';

import { FormItemType } from '@components/form/CreateFormItem/interface';
import RenderFormItem from '@components/form/CreateFormItem/_RenderFormItem';
import { DataResults, Questions, ViewsAnswer } from '@interface/assessment';
import appConfig from '@config/app.config';
import FormItemAction from '../FormItemAction';
import { CurrentSection, StatusQuestion } from '@interface/pages/assessment';
import { useAuthContextState } from '@authContext/store';

type Props = {
  rederAction?: boolean;
  view: Questions;
  values: ViewsAnswer;
  formProcessStatus: "onProcess" | "success" | "finish";
  assessmentInfo: DataResults | null;
  currentSection: CurrentSection;
  _getQuestionService: _GetQuestionService;
  editStatus: boolean;
  index?: number

  onStatusChang: (evaluationStatus: StatusQuestion[], subIndicatorStatus: StatusQuestion[]) => void;
  setValuesInfo: React.Dispatch<React.SetStateAction<Questions | null>>;
  setPercentProps: React.Dispatch<React.SetStateAction<number>>;
  setStatusQuestion: React.Dispatch<React.SetStateAction<StatusQuestion[] | null>>;
  setCurrentSection: React.Dispatch<React.SetStateAction<CurrentSection | null>>;
  setFormProcessStatus: React.Dispatch<React.SetStateAction<"onProcess" | "success" | "finish">>;
};
interface _GetQuestionService {
  getQuestionBySubIdApi: (evaluationID: string, subIndicatorID: string) => void;
  getQuestionBySubIdAndQuestionIndexApi: (evaluationID: string, subIndicatorID: string, indexQuestion: number) => void;
}

const FormAudienceView = (props: Props) => {
  const [form] = Form.useForm<FormItemType>();
  const [variableAttachment, setVariableAttachment] = React.useState<boolean>(false)
  const { userInfo } = useAuthContextState()

  React.useEffect(() => {
    initData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values])
  const initData = () => {
    if (props.values.answers !== null) {
      const valueMap = props.values.answers.map((val) => {
        if (val.type !== "attachment" && val.results) {
          if (Array.isArray(val.results)) {
            return val.results.map((re) => {
              if (re.value !== null) {
                setVariableAttachment(JSON.parse(re.value));
                return { [val.keyName]: JSON.parse(re.value) };
              } else {
                setVariableAttachment(false);
                return { [val.keyName]: re.value };
              }
            });
          }
        }
        return val.options.map((op) => {
          if (Array.isArray(op.results)) {
            const file = op.results.map((reOp) => {
              return {
                uuid: Number(reOp.id),
                name: reOp.fileName,
                status: 'done',
                url: `${appConfig.apiURL}/${reOp.value}`,
                thumbUrl: `${appConfig.apiURL}/${reOp.value}`,
              }
            });
            return { [op.keyName]: file };
          } else if (op.keyName === "inputLink") {
            if (Array.isArray(op.results?.value)) {
              const link = op.results?.value.map((dataLink: any) => {
                return dataLink;
              });
              return { [op.keyName]: link || [] };
            } else {
              return { [op.keyName]: [] };
            }
          } else {
            return { [op.keyName]: op.results?.value };
          }
        });
      });
      const renderValue = valueMap?.flat().reduce((data, cur) => {
        return { ...data, ...cur };
      }, {});
      form.setFieldsValue(renderValue)
    }
  }

  return (
    <Form name={`view-${props.values.type}`} form={form}  >
      <Col span={24} className="content-boxmaincontent" >
        <Row>
          <div className="view-content-boxsmall content-textcenter" style={{ fontSize: '20px' }}> {props.values.roleName} </div>
        </Row>
        <div className={props.values.type === userInfo.assessorType || props.values.type === userInfo.role ? "content-boxcontentstep" : "content-boxcontentstep view"} >
        <Row justify='end'  style={{position:"relative"}}>
      <Col className="text-point">คะแนน :  {props.values?.point_role||0}</Col>
      </Row> 
          <RenderFormItem values={props.values.answers} variableAttachment={variableAttachment} disabled />
        </div>
      </Col>
      {(!!props.assessmentInfo && !!props.view?.stapIndex
        &&
        ((props.formProcessStatus === "success" && props.values.type === 'sector') || !props.editStatus))
        &&
        !props.rederAction
        &&
        (props.values.type === userInfo.role
          || props.values.type === userInfo.assessorType
          || (!!props.view.views?.length && props.view.views?.length - 1 === props.index))
        &&
        (<FormItemAction
          answer={props.view.answer}
          editStatus={props.editStatus}
          rederAction={props.rederAction}
          assessmentInfo={props.assessmentInfo}
          stepValue={props.view.stapIndex}
          currentStep={props.view.currentIndex}
          setFormProcessStatus={props.setFormProcessStatus}
          currentSection={{ parentId: props.view.idIndicator.toString(), subIndicatorID: props.view.subIndicatorID.toString(), stepIndex: props.view.currentIndex }}
          setCurrentSection={props.setCurrentSection}
          _getQuestionService={props._getQuestionService}
        />
        )}
    </Form>
  );
};

export default FormAudienceView;
