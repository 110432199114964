import { variable } from '@interface/pages/dashboard';
import React from 'react'
import { Bar } from "react-chartjs-2";
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface Props {
  variableOne: variable[];
  variableTwo: variable[];
}
const ChartCom: React.FunctionComponent<Props> = ({ variableOne, variableTwo }: Props) => {
  Chart.register(ChartDataLabels);
  const [valuesTop, setValuesTop] = React.useState<any>({
    totalAchievement: { inProcess: null },
    criterionDetail: [{
      totalPercent: null,
      inProcess: null
    },
    {
      totalPercent: null,
      inProcess: null
    },
    {
      totalPercent: null,
      inProcess: null
    }]
  })
  const [valuesBottom, setValuesBottom] = React.useState<any>({
    totalAchievement: { inProcess: null },
    criterionDetail: [{
      totalPercent: null,
      inProcess: null
    },
    {
      totalPercent: null,
      inProcess: null
    },
    {
      totalPercent: null,
      inProcess: null
    }]
  })

  React.useEffect(() => {
    if (variableOne?.length > 0) {
      const variableOnefilter = variableOne.map(item => item)
      setValuesTop(variableOnefilter[0])
    }
    if (variableTwo?.length > 0) {
      const variableTwofilter = variableTwo.map(item => item)
      setValuesBottom(variableTwofilter[0]);
    }

  }, [variableOne, variableTwo])

  return (
    <Bar
      data={{
        labels: ['ตัวแปรที่ 1', 'ตัวแปรที่ 2'],
        datasets: [
          {
            label: 'หลักเกณฑ์ที่ 1',
            data: [(valuesTop.criterionDetail[0].inProcess > 0 ? valuesTop.criterionDetail[0].inProcess : null),
            (valuesBottom.criterionDetail[0].inProcess > 0 ? valuesBottom.criterionDetail[0].inProcess : null)],
            backgroundColor: ['#EB2227B3', '#2A2866B3'],
            barThickness: 70,
            datalabels: {
              display: true,
              anchor: 'center',
              offset: 20,
              font: { size: 24 },
              color: "white",
              formatter: (value: number, ctx: any) => {
                let percentage = (value) + "%";
                if (value !== null) {
                  return percentage;
                }
              },
            }
          },
          {
            label: 'หลักเกณฑ์ที่ 2',
            data: [(valuesTop.criterionDetail[1].inProcess > 0 ? valuesTop.criterionDetail[1].inProcess : null),
            (valuesBottom.criterionDetail[1].inProcess > 0 ? valuesBottom.criterionDetail[1].inProcess : null)],
            backgroundColor: ['#EB2227CC', '#2A2866CC'],
            barThickness: 70,
            datalabels: {
              display: true,
              anchor: 'center',
              offset: 20,
              font: { size: 24 },
              color: "white",
              formatter: (value: number, ctx: any) => {
                let percentage = (value) + "%";
                if (value !== null) {
                  return percentage;
                }
              },
            }
          },
          {
            label: 'หลักเกณฑ์ที่ 3',
            data: [(valuesTop.criterionDetail[2].inProcess > 0 ? valuesTop.criterionDetail[2].inProcess : null)
              , (valuesBottom.criterionDetail[2].inProcess > 0 ? valuesBottom.criterionDetail[2].inProcess : null)],
            backgroundColor: ['#EB2227', '#2A2866'],
            barThickness: 70,
            datalabels: {
              display: true,
              anchor: 'center',
              offset: 20,
              font: { size: 24 },
              color: "white",
              formatter: (value: number, ctx: any) => {
                let percentage = (value) + "%";
                if (value !== null) {
                  return percentage;
                }
              },
            }
          },
          {
            label: 'total',
            data: [(valuesTop.totalAchievement.inProcess > 0 ? valuesTop.totalAchievement.inProcess : null),
            (valuesBottom.totalAchievement.inProcess > 0 ? valuesBottom.totalAchievement.inProcess : null)],
            backgroundColor: ['transparent', 'transparent'],
            barThickness: 70,
            datalabels: {
              anchor: 'start',
              align: 'end',
              offset: 10,
              display: true,
              font: { size: 24 },
              color: "black",

              formatter: (value: number, ctx: any) => {
                let percentage = (value) + "%";
                if (value !== null) {
                  return percentage;
                }
              },
            },
          },
        ],
      }}
      options={{
        indexAxis: 'y',
        responsive: true,
        plugins: {
          legend: {
            display: false,
          }
        },
        scales: {
          yAxes: {
            stacked: true,
            ticks: {
              display: true,
              minRotation: -90,
              maxRotation: 90,
              padding: 30,
              color: '#000',
              font: {
                size: 17,
                family: 'FCIconic',
                weight: 'bold',
              },
            }
          },
          xAxes: {
            stacked: true,
            min: 0,
            max: 110,
            beginAtZero: true,
            ticks: {
              stepSize: 20,
              callback: function (value) { return value + "%" },
              color: '#000',
              font: {
                size: 17,
                family: 'FCIconic',
                weight: 'bold',
              }
            },
          },
        },
      }
      }
    />
  );
};

export default ChartCom;