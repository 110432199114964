import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  values: ItemProps;
}
interface ItemProps {
  path: string;
  title?: string;
  status?: string;
  icon?: React.ReactNode;
  color?: "yellow" | "green" | "blue" | "done" | "gray";
}

const AssessmentIcon = ({ values }: Props) => {
  return (
    values.status === "draft" ? (
      <div className="assessmentListMember-status">
        <div className="assessmentListMember-iconStatus" >
          {values.icon}
        </div>
        <div className="assessmentListMember-textStatus">สร้างแบบประเมินล่วงหน้า</div>
      </div>
    ) : values.status === "timeout" ? (
      values.path === 'none' ? (
        <div className="assessmentListMember-status">
          <div className="assessmentListMember-iconStatus">
            {values.icon}
          </div>
          <div className="assessmentListMember-textStatus" style={{ color: '#2A2866' }} >หมดเวลาการประเมิน</div>
        </div>
      ) : (
        <Link to={values.path}>
          <div className="assessmentListMember-status">
            <div className="assessmentListMember-iconStatus">
              {values.icon}
            </div>
            <div className="assessmentListMember-textStatus" style={{ color: '#2A2866' }} >หมดเวลาการประเมิน</div>
          </div>
        </Link>
      )
    ) : values.status === "document" ? (
      values.path === 'none' ? (
        <div className="assessmentListMember-status">
          <div className="assessmentListMember-iconStatus">
            {values.icon}
          </div>
          <div className="assessmentListMember-textStatus" style={{ color: '#2A2866' }} >อยู่ระหว่างแนบหลักฐาน</div>
        </div>
      ) : (
        <Link to={values.path}>
          <div className="assessmentListMember-status">
            <div className="assessmentListMember-iconStatus">
              {values.icon}
            </div>
            <div className="assessmentListMember-textStatus" style={{ color: '#2A2866' }} >อยู่ระหว่างแนบหลักฐาน</div>
          </div>
        </Link>
      )
    ) : values.status === "grading" ? (
      values.path === 'none' ? (
        <div className="assessmentListMember-status">
          <div className="assessmentListMember-iconStatus">
            {values.icon}
          </div>
          <div className="assessmentListMember-textStatus" style={{ color: '#2A2866' }} >อยู่ระหว่างเจ้าหน้าที่ประเมิน</div>
        </div>
      ) : (
        <Link to={values.path}>
          <div className="assessmentListMember-status">
            <div className="assessmentListMember-iconStatus">
              {values.icon}
            </div>
            <div className="assessmentListMember-textStatus" style={{ color: '#2A2866' }} >อยู่ระหว่างเจ้าหน้าที่ประเมิน</div>
          </div>
        </Link>
      )
    ) : values.status === "sector_doing" ? (
      values.path === 'none' ? (
        <div className="assessmentListMember-status">
          <div className="assessmentListMember-iconStatus">
            {values.icon}
          </div>
          <div className="assessmentListMember-textStatus" style={{ color: '#2A2866' }} >อยู่ระหว่างผู้บริหารประเมิน</div>
        </div>
      ) : (
        <Link to={values.path}>
          <div className="assessmentListMember-status">
            <div className="assessmentListMember-iconStatus">
              {values.icon}
            </div>
            <div className="assessmentListMember-textStatus" style={{ color: '#2A2866' }} >อยู่ระหว่างผู้บริหารประเมิน</div>
          </div>
        </Link>
      )
    ) : values.status === "success" ? (
      values.path === 'none' ? (
        <div className="assessmentListMember-status">
          <div className="assessmentListMember-iconStatus">
            {values.icon}
          </div>
          <div className="assessmentListMember-textStatus" style={{ color: '#2A2866' }} >เสร็จสมบูรณ์</div>
        </div>
      ) : (
        <Link to={values.path}>
          <div className="assessmentListMember-status">
            <div className="assessmentListMember-iconStatus">
              {values.icon}
            </div>
            <div className="assessmentListMember-textStatus" style={{ color: '#2A2866' }} >เสร็จสมบูรณ์</div>
          </div>
        </Link>
      )
    ) : (
      <div className="assessmentListMember-status">
        <div className="assessmentListMember-iconStatus">
          -
        </div>
      </div>
    )
  )
}
export default AssessmentIcon
