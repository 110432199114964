// import { BrowserRouter, Switch, Route, Redirect, } from "react-router-dom";
import { RoutesInterface } from '@routes/interface/router'
// import { RenderLayoutRoute } from '@routes/RenderRoute'
import { RenderLayoutRoute } from '../../routes/RenderRoute'
interface Props {
  router: RoutesInterface[];
}

const DefaultLayout = ({ router }: Props) => {
  return (
    <div>
      DefaultLayout
      <RenderLayoutRoute router={router} />
    </div>
  )
}
export default DefaultLayout
