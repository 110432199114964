import React from 'react'
import { Row, Col, Skeleton } from 'antd'
import { DashboardMember } from '@interface/pages/dashboard';

import SpeedChart from '@assets/images/icon/speedchart.svg';
import PieChart from '@assets/images/icon/piechart.svg';

interface Props {
  values: DashboardMember[] | null;
  isLoading: boolean;
}

const IconBox = ({ values, isLoading }: Props) => {
  return (
    <Row gutter={[8, 2]}>
      <Col xs={24} sm={12} lg={6}>
        <div className="DashMember-percent-assess-background DashMember-icon-block">
          <Row className="DashMember-percent-assess-block">
            <Col span={24} className="DashMember-box-title ">
              <div className="DashMember-text-title">{!isLoading && !!values ? `ผลสัมฤทธิ์รวม (100%)`
                :
                <Skeleton.Input className='skeleton-id' size='small' active />}</div>
              <div className="DashMembe-role">{!isLoading && !!values && !!values[0]?.criterion.totalAchievement.status ? values[0]?.criterion.totalAchievement.status
                :
                <Skeleton.Input className='skeleton-status' active size='small' />}
              </div>
            </Col>
            <Col span={24}>
              <Row className="DashMember-icon-img ">
                <div className="DashMember-percent-text" >
                  {!isLoading && !!values && values[0]?.criterion.totalAchievement.inProcess !== undefined ? `${values[0]?.criterion.totalAchievement.inProcess} %`
                    :
                    <Skeleton.Button size='small' style={{ height: '60px' }} active />}
                </div>
                <div className="DashMember-img-content">
                  <img
                    src={PieChart}
                    alt="chart-percent"
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>

      <Col xs={24} sm={12} lg={6}>
        <div className="DashMember-percent-assess-background DashMember-icon-block">
          <Row className="DashMember-percent-assess-block">
            <Col span={24} className="DashMember-box-title ">
              <div className="DashMember-text-title">{!isLoading && !!values && values[0]?.criterion.criterionDetail[0]?.id !== undefined && values[0]?.criterion.criterionDetail[0]?.totalPercent !== undefined ? `หลักเกณฑ์ที่ ${values[0]?.criterion.criterionDetail[0]?.id} (${values[0]?.criterion.criterionDetail[0]?.totalPercent}%)`
                :
                <Skeleton.Input className='skeleton-id' size='small' active />}
              </div>
              <div className="DashMembe-role">{!isLoading && !!values && !!values[0]?.criterion.criterionDetail[0]?.status ? values[0]?.criterion.criterionDetail[0]?.status
                :
                <Skeleton.Input className='skeleton-status' active size='small' />}
              </div>
            </Col>
            <Col span={24}>
              <Row className="DashMember-icon-img ">
                <div className="DashMember-percent-text" >
                  {!isLoading && !!values && values[0]?.criterion.criterionDetail[0]?.inProcess !== undefined ? `${values[0]?.criterion.criterionDetail[0]?.inProcess}%`
                    :
                    <Skeleton.Button size='small' style={{ height: '60px' }} active />
                  }
                </div>

                <div className="DashMember-img-content ">
                  <img
                    src={SpeedChart}
                    alt="chart-percent"
                    className="DashMember-box-test-img"
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs={24} sm={12} lg={6}>
        <div className="DashMember-percent-assess-background DashMember-icon-block">
          <Row className="DashMember-percent-assess-block">
            <Col span={24} className="DashMember-box-title ">
              <div className="DashMember-text-title">{!isLoading && !!values && values[0]?.criterion.criterionDetail[1]?.id !== undefined && values[0]?.criterion.criterionDetail[1]?.totalPercent !== undefined ? `หลักเกณฑ์ที่ ${values[0]?.criterion.criterionDetail[1]?.id} (${values[0]?.criterion.criterionDetail[1]?.totalPercent}%)`
                :
                <Skeleton.Input className='skeleton-id' size='small' active />}
              </div>
              <div className="DashMembe-role">{!isLoading && !!values && !!values[0]?.criterion.criterionDetail[1]?.status ? values[0]?.criterion.criterionDetail[1]?.status
                :
                <Skeleton.Input className='skeleton-status' active size='small' />}
              </div>
            </Col>
            <Col span={24}>
              <Row className="DashMember-icon-img ">
                <div className="DashMember-percent-text" >
                  {!isLoading && !!values && values[0]?.criterion.criterionDetail[1]?.inProcess !== undefined ? `${values[0]?.criterion.criterionDetail[1]?.inProcess}%`
                    :
                    <Skeleton.Button size='small' style={{ height: '60px' }} active />
                  }
                </div>

                <div className="DashMember-img-content ">
                  <img
                    src={SpeedChart}
                    alt="chart-percent"
                    className="DashMember-box-test-img"
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs={24} sm={12} lg={6}>
        <div className="DashMember-percent-assess-background DashMember-icon-block">
          <Row className="DashMember-percent-assess-block">
            <Col span={24} className="DashMember-box-title ">
              <div className="DashMember-text-title">{!isLoading && !!values && values[0]?.criterion.criterionDetail[2]?.id !== undefined && values[0]?.criterion.criterionDetail[2]?.totalPercent !== undefined ? `หลักเกณฑ์ที่ ${values[0]?.criterion.criterionDetail[2]?.id} (${values[0]?.criterion.criterionDetail[2]?.totalPercent}%)`
                :
                <Skeleton.Input className='skeleton-id' size='small' active />}
              </div>
              <div className="DashMembe-role">{!isLoading && !!values && !!values[0]?.criterion.criterionDetail[2]?.status ? values[0]?.criterion.criterionDetail[2]?.status
                :
                <Skeleton.Input className='skeleton-status' active size='small' />}
              </div>
            </Col>
            <Col span={24}>
              <Row className="DashMember-icon-img ">
                <div className="DashMember-percent-text" >
                  {!isLoading && !!values && values[0]?.criterion.criterionDetail[2]?.inProcess !== undefined ? `${values[0]?.criterion.criterionDetail[2]?.inProcess}%`
                    :
                    <Skeleton.Button size='small' style={{ height: '60px' }} active />
                  }
                </div>

                <div className="DashMember-img-content ">
                  <img
                    src={SpeedChart}
                    alt="chart-percent"
                    className="DashMember-box-test-img"
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
export default IconBox


