import React from 'react';
import { Col, Modal, Row } from 'antd';
import { sportInformation } from '@interface/pages/dashboard';
import AssessmentIcon from '@components/icon/RenderIcon/assessmentIcon';
import { SportImages } from '../CardList/CardItem';
import { Icons } from '@components/icon/RenderIcon/iconStatus';
import { useHistory } from 'react-router-dom';
import { useAuthContextState } from '@authContext/store';


interface Props {
    isModalVisible: boolean;
    setIsModalVisible: (value: boolean) => void;
    sportInformation?: sportInformation;
}

const SportDetial = ({ isModalVisible, setIsModalVisible, sportInformation }: Props) => {
    const history = useHistory()
    const { userInfo, auth, token } = useAuthContextState()

    return (
        <Modal
            title={<div className="dashmin-cardlist-title">
                <img
                    alt="icon"
                    src={SportImages.find(img => img.title === sportInformation?.title)?.icon}
                    className="dashmin-img-style"
                />
                {sportInformation?.title}
            </div>}
            visible={isModalVisible}
            onCancel={() => { setIsModalVisible(false) }}
            footer={null}
            width={1200}
        >
            <Row gutter={{ xs: 8, sm: 10, md: 20, lg: 13 }}>
                {sportInformation?.result.map((sportInformation, index) => (
                    <React.Fragment key={index}>
                        <Row style={{ width: '100%' }}>
                            <div className='dashmin-role-name'>
                                {sportInformation.name}
                            </div>
                        </Row>
                        <Row style={{ width: '100%' }}>
                            {sportInformation.data.map((data: any, index: number) => (
                                <Col key={index} className='col-content-sport' span={6}>
                                    <div className='card-content-sport' onClick={() => history.replace(`${userInfo.role === 'superuser' ? '/admin' : '/officerAndSector'}/assessment/${data.id}`)}>
                                        <div className='card-content-sport-titlt' >
                                            {data.title}
                                        </div>
                                        <div className='card-content-sport-name' >
                                            {data.program}
                                        </div>
                                        <div className='card-content-sport-icon sport-hover-modal' >
                                            <div style={{ marginTop: '10px' }}>
                                                <AssessmentIcon
                                                    values={{
                                                        status: data.status,
                                                        path: 'none',
                                                        icon: Icons.find((icon) => icon.status === data.status)?.icon
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </React.Fragment>
                ))}
            </Row>
        </Modal>
    )
}
export default SportDetial;
