import { Fetch, FetchFormData2 } from "../../utils/fetch/fetch";

export const GetFromUserApi = async <T = any>() => {
  const res = await Fetch<T>({
    method: "GET",
    path: "/admin/user/crate:",
    // api Backend
    // path: "/user",
  });
  return res;
};

export const CreateFromUserApi = async <T = any>(data: any, token?: string) => {
  const res = await FetchFormData2<T>(
    {
      method: "POST",
      path: "/user",
      data: data,
      token: token,
    },
    data
  );
  return res;
};
export const GetUserRole = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: "/role",
    token: token,
  });
  return res;
};
export const GetUserAssessorTypeApi = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: "/role/assessorType",
    token: token,
  });
  return res;
};

export const GetSportApi = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: "/sport",
    token: token,
  });
  return res;
};
