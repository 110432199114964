// import { lazy } from "react";
import { RoutesInterface } from "./interface/router";
import { TypesLayout } from "../interface/theme";

import Dashboard from "@page/admin/Dashboard";
import Assessment from "@page/admin/assessment";
import AssessmentForm from "@page/admin/assessment/Form";
import Document from "../page/Document";

import dashboard from "@assets/images/icon/side-dash.svg";
import assessment from "@assets/images/icon/side-asses.svg";
import document from "../assets/images/icon/side-doc.svg";
import AssessmentForm2 from "@page/member/assessment/Form";

export default [
  {
    path: "/",
    exact: true,
    layout: TypesLayout.main,
    sidebar: false,
    redirectTo: "/officerAndSector/dashboard",
  },
  {
    name: "Dashboard",
    path: "/officerAndSector/dashboard",
    icon: dashboard,
    exact: true,
    layout: TypesLayout.main,
    sidebar: true,
    component: Dashboard,
  },
  {
    name: "Assessment",
    path: "/officerAndSector/assessment",
    icon: assessment,
    exact: true,
    layout: TypesLayout.main,
    sidebar: true,
    component: Assessment,
  },
  {
    name: "Form Assessment",
    path: "/officerAndSector/assessment/:assessmentid",
    exact: true,
    layout: TypesLayout.main,
    sidebar: false,
    component: AssessmentForm,
  },
  {
    name: "Document",
    path: "/document",
    icon: document,
    exact: true,
    layout: TypesLayout.main,
    sidebar: true,
    component: Document,
  },

  {
    name: "Form Assessment Edit",
    path: "/officerAndSector/assessment/edit/:id",
    exact: true,
    layout: TypesLayout.main,
    sidebar: false,
    component: AssessmentForm2,
  },
] as RoutesInterface[];
