import { Upload, Button, Form } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Answer, Option } from '@interface/assessment';
import { FilePdfFilled } from '@ant-design/icons'
import config from '@config/app.config';
import { ROLE_ENUM } from '@interface/role';
import { useAuthContextState } from '@authContext/store';

// import UploadFilesCustom from '@components/form/CreateFormItem/CustomItem/UploadFiles';
export interface Props {
  values: Answer | Option;
  disabled: boolean;
  variablePoint?: boolean;
}

const UploadFiles = ({ values, disabled, variablePoint }: Props) => {

  const { userInfo } = useAuthContextState()

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const rules: { [key: string]: any } = {
    inputFiles: [{ required: !variablePoint && (userInfo.role === ROLE_ENUM.ASSESSOR || userInfo.role === ROLE_ENUM.ASSESSOR) ? true : false, message: "กรุณาเลือกไฟล์" },
    () => ({
      validator(_: any, value: any) {
        if (Object.entries(!!values?.results && values?.results).length > 0) {
          return Promise.resolve()
        } else {
          if (value.every((data: { originFileObj: { type: string; }; }) => data.originFileObj.type === "application/pdf")) {
            return Promise.resolve()
          }
          return Promise.reject(new Error('ประเภทไฟล์ไม่ถูกต้อง กรุณาป้อนประเภท PDF ไฟล์เท่านั้น'));
        }
      },
    })]
  }

  return (
    <div>
      <div className="text-Answer">
        {values["title"]}&nbsp;
        <Button disabled={!values["example"]} type='link' href={`${config.apiURL}/${values["example"]}`} target="_blank" rel="noreferrer noopener">
          <FilePdfFilled style={{ color: '#f50303' }} />
        </Button>&nbsp;
        {values["remark"]}
      </div>
      <Form.Item
        name={values["keyName"]}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={rules.inputFiles}
      >
        <Upload
          disabled={disabled}
          listType="picture"
          customRequest={({ _, onSuccess }: any) => onSuccess('ok')}
        >
          <Button icon={<CloudUploadOutlined />} disabled={disabled}>อัพโหลดไฟล์</Button>
        </Upload>
      </Form.Item>
    </div >
  )
}
export default UploadFiles