import { Form } from "antd";
import { Answer, Option } from '@interface/assessment'
import InputList from "./InputList";

export interface Props {
  values: Answer | Option;
  disabled: boolean;
  variablePoint?: boolean;

}
const InputLink = ({ values, disabled, variablePoint }: Props) => {
  const html = values["description"] || ``
  return (
    <div >
      <div className="text-Answer">
        {values["title"] || "เลือกคำตอบ"}
      </div>
      <div className="todo__items">
        <Form.Item name={values["keyName"]} rules={[{ required: false }]}>
          <InputList disabled={disabled} />
        </Form.Item>
      </div>
      <span dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}
export default InputLink 