import React from "react";
import { Row, Col, Button, Skeleton, Badge } from "antd";
import NoData from "@components/NoData";
import FormQuestionAndAnswer from "@components/form/Assessments/Content/FormQuestionAndAnswer";
import ContentPage from "@layout/MainLayout/Header/Content";
import FormIndicators from "@components/form/Assessments/Sider/Indicators";
import { NotificationOutlined } from "@ant-design/icons";

import { GetIndicatorApi, GetQuestionApiById, GetQuestionBySubIdApi } from "@services/api/assessment";
import { ContainerOutlined } from "@ant-design/icons";
import { CaretLeftOutlined } from "@ant-design/icons";
import { useAuthContextState } from "@authContext/store";
import { _findObjectOfArrayById } from '@utils/utils'
import { useHistory, useParams } from "react-router-dom";
import { CurrentSection, StatusQuestion } from "@interface/pages/assessment";
import { DataResults, Questions, ResIndicator, ResQuestionsById, } from "@interface/assessment";
import AssessmentIcon from "@components/icon/RenderIcon/assessmentIcon";
import { Icons } from "@components/icon/RenderIcon/iconStatus";
import RejectMomalMember from "./rejectModal/RejectMomalMember";


/* const res = {
  code: 200,
  status: "success",
  result: [
    {
      "processingStatus": 100,
      "status": "sector_doing",
      "evaluation": [
        {
          "id": "1",
          "title": "หลักเกณฑ์ที่ 1 การปฏิบัติตาม พ.ร.บ. ส่งเสริมกีฬาอาชีพ พ.ศ. 2556 และกฎระเบียบที่เกี่ยวข้อง",
          "index": 0,
          "role": "organizer",
          "subIndicator": [
            {
              "id": "2",
              "title": "1.1 การปฏิบัติตาม พ.ร.บ. ส่งเสริมกีฬาอาชีพ พ.ศ. 2556 และกฎระเบียบที่เกี่ยวข้อง",
              "index": 0,
              "role": "organizer",
              "questions": [
                {
                  "id": "1",
                  "index": 0,
                  "question": "1.1.1 หลักฐานการจัดทําแผนบริหารจัดการเกี่ยวกับการจัดการแข่งขันอาชีพ รวมถึงการแบ่งผลประโยชน์หรือรายได้ต่อนายทะเบียนก่อนการจัดการแข่งขันตามที่นายทะเบียนกําหนด (ครอบคลุมข้อกําหนดตาม พ.ร.บ. เป็นอย่างน้อยตาม (1) ถึง (6))",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "1",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1309",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "2",
                  "index": 1,
                  "question": "1.1.2 หลักฐานมาตรฐานการจัดการแข่งขันกีฬาอาชีพ (สามารถอ้างอิงกับมาตรฐานของสมาคมกีฬาอาชีพ หรือมาตรฐานองค์กรกีฬานานาชาติ)",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "5",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1311",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "3",
                  "index": 2,
                  "question": "1.1.3 หลักฐานมาตรฐานความปลอดภัยในการจัดการแข่งขันกีฬาอาชีพ (สามารถอ้างอิงกับมาตรฐานของสมาคมกีฬาอาชีพ หรือมาตรฐานองค์กรกีฬานานาชาติ) ที่ครอบคลุมนักกีฬา บุคลากร ผู้เข้าชมการแข่งขัน และกองเชียร์",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "9",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1313",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "4",
                  "index": 3,
                  "question": "1.1.4 หลักฐานการจัดให้มีแพทย์ตรวจสุขภาพนักกีฬาอาชีพที่เข้าร่วมการแข่งขันทุกคนโดยแพทย์ แผนปัจจุบัน (ตรวจก่อนการแข่งขัน หรือในระหว่างการจัดการแข่งขัน โดยมีใบรับรองแพทย์)",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "13",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1315",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "5",
                  "index": 4,
                  "question": "1.1.5 หลักฐานแนวทางการป้องกันการล้มกีฬา และการปฏิบัติหน้าที่โดยมิชอบของนักกีฬาอาชีพ และบุคลากรกีฬาอาชีพ",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "17",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1317",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "3",
              "title": "1.2 การกำหนดให้มีมาตรการการป้องกันการล้มกีฬา หรือการปฏิบัติหน้าที่โดยมิชอบของนักกีฬาอาชีพ และบุคลากรกีฬาอาชีพ",
              "index": 1,
              "role": "organizer",
              "questions": [
                {
                  "id": "6",
                  "index": 0,
                  "question": "1.2.1 เอกสารหลักฐานที่แสดงถึงกิจกรรมการสร้างการรับรู้เกี่ยวกับข้อกําหนดของกฎหมาย และบทลงโทษตาม พรบ.ส่งเสริมกีฬาอาชีพ พ.ศ. 2556, สร้างความตระหนักถึงผลเสียที่จะเกิดขึ้นต่อนักกีฬา บุคลากรกีฬา สโมสร สมาคมกีฬา สร้างการมีส่วนร่วม ในการป้องกันการล้มกีฬา",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "21",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1319",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "7",
                  "index": 1,
                  "question": "1.2.2 เอกสารหลักฐานที่แสดงถึงการกําหนดแนวทางหรือมาตฐานการป้องกันการล้มกีฬา หรือการปฏิบัติหน้าที่โดยมิชอบของนักกีฬาอาชีพ และบุคลากรกีฬาอาชีพที่ชัดเจน ทั้งในส่วนของแนวทางเชิงป้องกันเชิงเฝ้าระวัง และการสร้างระบบที่สามารถตรวจสอบย้อนหลังได้",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "25",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1321",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "8",
                  "index": 2,
                  "question": "1.2.3 เอกสารหลักฐานแสดงถึงกระบวนการเฝ้าระวังป้องกันไม่ให้เกิดการล้มกีฬา หรือการปฏิบัติหน้าที่โดยมิชอบของนักกีฬาอาชีพ และบุคลากรกีฬาอาชีพ",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "29",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1323",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "9",
                  "index": 3,
                  "question": "1.2.4 เอกสารหลักฐานภาพถ่ายแสดงตําแหน่งการติดตั้งกล้องบันทึกภาพเคลื่อนไหว (Video) ของการแข่งขันกีฬาอาชีพจํานวนไม่น้อยกว่า 10 ภาพ ที่แสดงการติดตั้งกล้องบันทึกภาพในตําแหน่งต่างๆ ทั้งในภาพรวม และเฉพาะจุด พร้อมทั้งการจัดเก็บไว้เป็นหลักฐานเพื่อสามารถตรวจสอบการแข่งขันย้อนหลัง ทั้งในส่วนของคุณภาพการตัดสินตลอดจนจริยธรรม และจรรยาบรรณของนักกีฬาอาชีพ และผู้ตัดสินในขณะปฏิบัติหน้าที่ระหว่างการแข่งขัน",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "33",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1325",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "10",
                  "index": 4,
                  "question": "1.2.5 เอกสารหลักฐานระเบียบวิธีการปฏิบัติหากเกิดกรณี “การล้มกีฬา” เพื่อให้ผู้เกี่ยวข้องสามารถนําไปปฏิบัติได้ทันที",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "37",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1327",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "4",
              "title": "1.3 การป้องกันมิให้ใช้สารต้องห้ามทางการกีฬาของนักกีฬาอาชีพ",
              "index": 2,
              "role": "organizer",
              "questions": [
                {
                  "id": "11",
                  "index": 0,
                  "question": "1.3.1 เอกสารหลักฐานคู่มือหรือเอกสารเพื่อให้นักกีฬา และบุคลากรกีฬาศึกษาด้วยตนเองเพื่อให้มีความรู้ความเข้าใจเกี่ยวกับสารต้องห้าม การป้องกันไม่ให้มีการใช้สารต้องห้ามโดยไม่ตั้งใจ และมีรายชื่อสารต้องห้ามของแต่ละชนิด/ประเภทกีฬา เผยแพร่ผ่านทางเว็บไซต์ของผู้จัดการแข่งขันกีฬาอาชีพ",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "41",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1329",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "12",
                  "index": 1,
                  "question": "1.3.2 เอกสารหลักฐานรจัดกิจกรรมหรือเผยแพร่ประชาสัมพันธ์ให้นักกีฬา และหรือบุคลากรกีฬามีความรู้ความเข้าใจเกี่ยวกับสารต้องห้าม การป้องกันไม่ให้มีการใช้สารต้องห้ามโดยไม่ตั้งใจ บทลงโทษในการใช้สารต้องห้าม รายชื่อสารต้องห้ามของแต่ละชนิด/ประเภทกีฬา โดยดําเนินการอย่างน้อยปีละ 1 ครั้ง (ภาพถ่ายไม่น้อยกว่า 10 ภาพ)",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "45",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1331",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "13",
                  "index": 2,
                  "question": "1.3.3 เอกสารหลักฐานกิจกรรมหรือเผยแพร่ประชาสัมพันธ์ให้นักกีฬา และหรือบุคลากรกีฬามีความรู้ความเข้าใจเกี่ยวกับสารต้องห้าม การป้องกันไม่ให้มีการใช้สารต้องห้ามโดยไม่ตั้งใจ บทลงโทษในการใช้สารต้องห้าม รายชื่อสารต้องห้ามของแต่ละชนิด/ประเภทกีฬา โดยดําเนินการทุกรายการแข่งขันกีฬาอาชีพ (ภาพถ่ายไม่น้อยกว่า 10 ภาพ/การแข่งขัน)",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "49",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1333",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "14",
                  "index": 3,
                  "question": "1.3.4 เอกสารหลักฐานจัดทํา และประกาศกลไก และกระบวนการการตรวจสอบสารต้องห้ามในรายการการแข่งขันกีฬาอาชีพ พร้อมทั้งแผนงานการตรวจสอบสารต้องห้ามประจําปี",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "53",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1335",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "15",
                  "index": 4,
                  "question": "1.3.5 เอกสารหลักฐานการตรวจสารต้องห้ามซึ่งอ้างอิงกลไก และกระบวนการที่ประกาศไว้ เป็นตามจํานวนนักกีฬาที่จะต้องถูกสุ่มตรวจในแต่ละรายการแข่งขันกีฬาอาชีพที่กําหนดไว้ในแผนงานการตรวจสอบสารต้องห้ามประจําปี",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "57",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1337",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "5",
              "title": "1.4 มาตรฐานการจัดการแข่งขันและความปลอดภัย (ม.37) ",
              "index": 3,
              "role": "organizer",
              "questions": [
                {
                  "id": "16",
                  "index": 0,
                  "question": "1.4.1 เอกสารหลักฐานมีการดําเนินการตามมาตรฐานการจัดการแข่งขันกีฬาอาชีพ และมาตรฐานความปลอดภัยด้านความปลอดภัย และมาตรฐานของอาคาร สถานที่ที่จัดการแข่งขันกีฬาอาชีพ ทางเข้าออกอาคาร สิ่งอํานวยความสะดวกสถานที่ และระบบที่จําเป็นต่อการควบคุม หรือดําเนินการจัดการแข่งขันกีฬาอาชีพ โดยมีเอกสารหลักฐานประกอบที่สามารถยืนยันได้ว่าการดําเนินการมีมาตรฐาน พร้อมรูปถ่ายไม่น้อยกว่า 10 ภาพ",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "61",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1339",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "17",
                  "index": 1,
                  "question": "1.4.2 เอกสารหลักฐานมีการดําเนินการตามมาตรฐานการจัดการแข่งขันกีฬาอาชีพ และมาตรฐานความปลอดภัยด้านมาตรฐานการควบคุม ดูแล และรักษาความสงบเรียบร้อยในสถานที่จัดการแข่งขัน หรือประกอบกิจกรรมที่เกี่ยวเนื่องกับการแข่งขันกีฬาอาชีพ โดยมีเอกสารหลักฐานประกอบที่สามารถยืนยันได้ว่าการดําเนินการมีมาตรฐาน พร้อมรูปถ่ายไม่น้อยกว่า 10 ภาพ",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "65",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1341",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "18",
                  "index": 2,
                  "question": "1.4.3 เอกสารหลักฐานมีการดําเนินการตามมาตรฐานการจัดการแข่งขันกีฬาอาชีพ และมาตรฐานความปลอดภัยด้านการสอดส่อง ดูแลการปฏิบัติงานของบุคคลที่เกี่ยวข้องกับการแข่งขันกีฬาอาชีพ หรือประกอบกิจกรรมที่เกี่ยวเนื่องกับการแข่งขันกีฬาอาชีพมิให้มีพฤติกรรมที่สร้างความเสียหายแก่นักกีฬาอาชีพ และบุคลากรกีฬาอาชีพ โดยมีเอกสารหลักฐานประกอบที่สามารถยืนยันได้ว่าการดําเนินการมีมาตรฐาน พร้อมรูปถ่ายไม่น้อยกว่า 10 ภาพ",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "69",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1343",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "19",
                  "index": 3,
                  "question": "1.4.4 เอกสารหลักฐานมีการดําเนินการตามมาตรฐานการจัดการแข่งขันกีฬาอาชีพ และมาตรฐานความปลอดภัยด้านการตรวจสุขภาพนักกีฬาอาชีพโดยแพทย์แผนปัจจุบัน และรับรองด้วยว่านักกีฬาอาชีพนั้นมีสุขภาพแข็งแรงเพียงพอที่จะเข้าร่วมการแข่งขันกีฬาอาชีพ หรือประกอบกิจการที่เกี่ยวเนื่องกับการแข่งขันกีฬาอาชีพได้อย่างปลอดภัยโดยมีเอกสารหลักฐานประกอบที่สามารถยืนยันได้ว่าการดําเนินการมีมาตรฐาน พร้อมรูปถ่ายไม่น้อยกว่า 10 ภาพ",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "73",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1345",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                },
                {
                  "id": "20",
                  "index": 4,
                  "question": "1.4.5 เอกสารหลักฐานมีการดําเนินการตามมาตรฐานการจัดการแข่งขันกีฬาอาชีพ และมาตรฐานความปลอดภัยด้านการจัดให้มีบุคลากรการแพทย์ อุปกรณ์ทางการแพทย์ที่จําเป็น และเหมาะสมต่อการรักษาสุขภาพร่างกายของนักกีฬาอาชีพ และบุคลากรกีฬาอาชีพอยู่ประจําสนามแข่งขัน หรือสถานที่จัดกิจกรรมกีฬาอาชีพ ตลอดเวลาที่มีการแข่งขันกีฬาอาชีพ หรือประกอบกิจกรรมที่เกี่ยวเนื่องกับการแข่งขันกีฬาอาชีพ และการอํานวยความสะดวกแก่นักกีฬาอาชีพ บุคลากรกีฬอาชีพ และผู้เกี่ยวข้องกับการแข่งขันกีฬาอาชีพ โดยมีเอกสารหลักฐานประกอบที่สามารถยืนยันได้ว่าการดําเนินการมีมาตรฐาน พร้อมรูปถ่ายไม่น้อยกว่า 10 ภาพ",
                  "maxPoint": 1,
                  "questionType": [
                    {
                      "id": "77",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1347",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "6",
              "title": "1.5 ผลการประเมินแผนการบริหารจัดการเกี่ยวกับการจัดการแข่งขันของผู้จัดการแข่งขันกีฬาอาชีพ",
              "index": 4,
              "role": "organizer",
              "questions": [
                {
                  "id": "21",
                  "index": 0,
                  "question": "1.5.1 ผลการประเมินแผนการบริหารจัดการเกี่ยวกับการจัดการแข่งขันในปีก่อนหน้า ",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "81",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1349",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            }
          ],
          "status": "success",
          "endDate": "2022-03-16",
          "dayRemaining": 46
        },
        {
          "id": "7",
          "title": "หลักเกณฑ์ที่ 2 ความสอดคล้องกับยุทธศาสตร์ชาติ 20 ปีด้านศักยภาพการกีฬา",
          "index": 1,
          "role": "organizer",
          "subIndicator": [
            {
              "id": "8",
              "title": "2.1 จํานวนผู้ตัดสินการแข่งขันกีฬาอาชีพที่มีคุณภาพมาตรฐานสากล สําหรับผู้จัดการแข่งขันกีฬาอาชีพ",
              "index": 0,
              "role": "organizer",
              "questions": [
                {
                  "id": "22",
                  "index": 0,
                  "question": "2.1.1 เอกสารหลักฐานจํานวนผู้ตัดสินการแข่งขันกีฬาอาชีพที่มีคุณภาพมาตรฐานสากลที่เสนอโดยสมาคมกีฬาอาชีพที่ทําหน้าที่ผู้ตัดสินพร้อมแนบรายชื่อผู้ตัดสินของรายการแข่งขัน, รายชื่อผู้ตัดสินที่ขึ้นทะเบียนของสมาคมกีฬาได้ผ่านการฝึกอบรมถูกต้องตามเกณฑ์การแข่งขันระดับนานาชาติ และภาพถ่ายขณะปฏิบัติหน้าที่",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "86",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1352",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "9",
              "title": "2.2 จำนวนบุคลากรกีฬาอาชีพที่จดแจ้งการเป็นบุคลากรกีฬาอาชีพ",
              "index": 1,
              "role": "organizer",
              "questions": [
                {
                  "id": "23",
                  "index": 0,
                  "question": "2.2.1 เอกสารหลักฐานรายชื่อบุคลากรกีฬาอาชีพที่ได้รับการจดแจ้งการเป็นบุคลากรกีฬาอาชีพต่อนายทะเบียนกับการกีฬาแห่งประเทศไทย (ตามมาตรา 29) ที่ได้เข้าร่วมรายการแข่งขัน และรายชื่อบุคลากรกีฬาอาชีพที่ได้เข้าร่วมรายการแข่งขันทั้งหมด พร้อมภาพถ่าย หรือ/และ วิดีโอ การเข้าร่วมรายการแข่งขันที่จดแจ้งฯ",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "92",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1356",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "10",
              "title": "2.3 จำนวนนักกีฬาอาชีพที่จดแจ้งการเป็นนักกีฬาอาชีพที่ได้เข้าร่วมการแข่งขันกีฬาอาชีพ",
              "index": 2,
              "role": "organizer",
              "questions": [
                {
                  "id": "24",
                  "index": 0,
                  "question": "2.3.1 เอกสารหลักฐานรายชื่อนักกีฬาที่จัดแจ้งเป็นนักกีฬาอาชีพต่อนายทะเบียนกับการกีฬาแห่งประเทศไทยที่ได้เข้าร่วมการแข่งขันกีฬาอาชีพ และรายชื่อนักกีฬาอาชีพที่ได้เข้าร่วมการแข่งขันกีฬาอาชีพทั้งหมด พร้อมภาพถ่าย หรือ/และ วิดีโอ การเข้าร่วมการแข่งขันของนักกีฬาอาชีพที่จดแจ้งฯ",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "99",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1361",
                          "result": "true",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "11",
              "title": "2.4 การพัฒนากีฬาอาชีพสู่ความยั่งยืน",
              "index": 3,
              "role": "organizer",
              "questions": [
                {
                  "id": "25",
                  "index": 0,
                  "question": "2.4.1 หลักฐานการดําเนินการที่เกี่ยวข้องกับการพัฒนากีฬาอาชีพ เช่น การศึกษาวิจัยเพื่อนําผลการวิจัยมาเพิ่มศักยภาพของนักกีฬา/พัฒนาการบริหารจัดการกีฬาอาชีพ การปรับรูปแบบการจัดแข่งขันกีฬาอาชีพในเหมาะสมกับกลุ่มเป้าหมายใหม่ๆ การนําวิทยาศาสตร์การกีฬามาใช้ในการพัฒนานักกีฬา การจัดทําฐานข้อมูลสถิติการแข่งขันเพื่อนํามาพัฒนาทีมและนักกีฬา การจ้างผู้เชี่ยวชาญมาให้คําปรึกษาแนะนําการพัฒนากีฬาให้มีมาตรฐานสูงขึ้น การนําเทคโนโลยีดิจิทัลมาพัฒนา การบริหารจัดการกีฬาของหน่วยงาน เป็นต้น อาจมีการแนบเอกสารภาพถ่าย หรือคลิปวิดีโอ",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "106",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1366",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "12",
              "title": "2.5 ความมั่นคงของฐานะทางการเงิน",
              "index": 4,
              "role": "organizer",
              "questions": [
                {
                  "id": "26",
                  "index": 0,
                  "question": "2.5.1 เอกสารหลักฐานงบการเงินย้อนหลัง 2 ปี",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "112",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1370",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            }
          ],
          "status": "success"
        },
        {
          "id": "13",
          "title": "หลักเกณฑ์ที่ 3 การเพิ่มมูลค่าทางเศรษฐกิจและสังคมให้กับประเทศไทย",
          "index": 2,
          "role": "organizer",
          "subIndicator": [
            {
              "id": "14",
              "title": "3.1 รายได้จากการแข่งขัน สําหรับผู้จัดการแข่งขัน",
              "index": 0,
              "role": "organizer",
              "questions": [
                {
                  "id": "27",
                  "index": 0,
                  "question": "3.1.1 เอกสารหลักฐานที่มาของรายได้ (ไม่รวมเงินสนับสนุนจาก กกท.) โดยแสดงที่มาของรายได้ของแต่ละรายการ หรือจํานวนเงินรายได้รวม",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "117",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1373",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "15",
              "title": "3.2 การสร้างผลกระทบทางเศรษฐกิจ (Economic impact) สําหรับผู้จัดการแข่งขัน",
              "index": 1,
              "role": "organizer",
              "questions": [
                {
                  "id": "28",
                  "index": 0,
                  "question": "3.2.1 เอกสารหลักฐานการประเมินผลกระทบทางเศรษฐกิจของการจัดการแข่งขันกีฬาอาชีพ โดยมีรายละเอียดเกี่ยวกับค่าใช้จ่ายของผู้ชมกีฬา และบุคลากรที่เกี่ยวข้องกับการจัดการแข่งขันกีฬาอาชีพ รวมถึงเงินลงทุนของผู้จัดการแข่งขัน เงินสนับสนุนของผู้สนับสนุน (Sponsors) และเงินลงทุน/ค่าใช้จ่ายของผู้เข้าร่วมจัดกิจกรรมภายในงานแข่งขันกีฬาอาชีพโดยผู้จัดการแข่งขันกีฬาอาชีพ เป็นผู้ทําหน้าที่สํารวจรวบรวมจากผู้เกี่ยวข้องตามรายละเอียดที่การกีฬาแห่งประเทศไทยกําหนด รวมถึงเอกสารหลักฐานประกอบรายการค่าใช้จ่ายสําหรับรายการสําคัญๆ ที่มีมูลค่าสูง",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "124",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1378",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "16",
              "title": "3.3 จำนวนเงินรางวัลการแข่งขัน",
              "index": 2,
              "role": "organizer",
              "questions": [
                {
                  "id": "29",
                  "index": 0,
                  "question": "3.3.1 เอกสารหลักฐานเอกสารแสดงรายละเอียดเกี่ยวกับเงินรางวัลที่ผู้จัดการแข่งขันกีฬาอาชีพ (ไม่รวมเงินรางวัลส่วนที่การกีฬาแห่งประเทศไทยให้การสนับสนุน) มอบให้กับนักกีฬา หรือสโมสรกีฬาอาชีพที่ชนะการแข่งขัน โดยอย่างน้อยต้องแสดงรายละเอียดเกี่ยวกับจํานวนรางวัล ชื่อรางวัล จํานวนเงินในแต่ละชื่อรางวัล รายชื่อนักกีฬา หรือสโมสรกีฬาอาชีพที่ได้รับเงินรางวัล วันเวลา และสถานที่มอบเงินรางวัล สักขีพยานการมอบรางวัล หลักฐานที่ยืนยันการรับเงินรางวัลของผู้ที่ได้รับเงินรางวัล และภาพถ่ายพิธีการมอบเงินรางวัลให้กับนักกีฬา หรือสโมสรกีฬาอาชีพทุกรายจํานวนไม่น้อยกว่า 10 ภาพ และคลิปวีดีโอพิธีการมอบเงินรางวัลให้กับนักกีฬา หรือสโมสรกีฬาอาชีพทุกรายความยาวไม่เกิน 5 นาที",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "129",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1381",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "17",
              "title": "3.4 การส่งเสริมอุตสาหกรรมกีฬา",
              "index": 3,
              "role": "organizer",
              "questions": [
                {
                  "id": "30",
                  "index": 0,
                  "question": "3.4.1 เอกสารหลักฐานเอกสารรายละเอียดการจัดกิจกรรมเพื่อส่งเสริมอุตสาหกรรมกีฬา ซึ่งอย่างน้อยต้องประกอบด้วยชื่อกิจกรรม สถานที่จัดกิจกรรม ช่วงเวลาจัดกิจกรรม รายชื่อผู้ประกอบการที่เข้าร่วม และประเภทสินค้า รายละเอียดกิจกรรม กลุ่มเป้าหมายเข้าร่วมกิจกรรม จํานวนผู้เข้าชม/ซื้อสินค้า ภาพถ่ายกิจกรรมส่งเสริมอุตสาหกรรมกีฬา ณ สถานที่จัดกิจกรรม จํานวนไม่น้อยกว่า 10 ภาพ ที่แสดงให้เห็นภาพรวมของงาน ภาพรวมของกิจกรรมส่งเสริมอุตสาหกรรมกีฬา และผู้เข้าร่วมกิจกรรมกิจกรรมส่งเสริมอุตสาหกรรมกีฬา และคลิปวีดีโอการจัดกิจกรรมส่งเสริมอุตสาหกรรมกีฬาที่เห็นภาพรวมการจัดงาน ภาพรวมการจัดกิจกรรมส่งเสริมอุตสาหกรรมกีฬา ความยาวไม่เกิน 5 นาที",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "136",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1386",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "18",
              "title": "3.5 การประชาสัมพันธ์กีฬาอาชีพสําหรับผู้จัดการแข่งขันกีฬาอาชีพ",
              "index": 4,
              "role": "organizer",
              "questions": [
                {
                  "id": "31",
                  "index": 0,
                  "question": "3.5.1 เอกสารหลักฐานประชาสัมพันธ์การดําเนินงานของผู้จัดการแข่งขันผ่านสื่อต่างๆ เพื่อสร้างการรับรู้ สร้างความนิยม สร้างความเชื่อมั่น สร้างภาพลักษณ์ เชิญชวนเข้าร่วมกิจกรรม หรืออื่นๆที่เกี่ยวกับกีฬาอาชีพ",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "143",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1391",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "19",
              "title": "3.6 จำนวนผู้ชมการแข่งขัน",
              "index": 5,
              "role": "organizer",
              "questions": [
                {
                  "id": "32",
                  "index": 0,
                  "question": "3.6.1 เอกสารหลักฐานเอกสารแสดงถึงจํานวนผู้ชมการแข่งขัน ณ สนามแข่งขัน และผู้ชมการแข่งขันผ่านช่องทางต่างๆ ได้แก่ ผู้ชมที่รับชมการถ่ายทอดสดทั้งภาพและเสียงผ่านเครือข่ายอินเทอร์เน็ต (Streaming) การถ่ายทอดสดผ่านทีวีดิจิทัล และถ่ายทอดสดผ่านช่องทางอื่นๆเอกสารหลักฐานภาพถ่ายผู้ชมการแข่งขัน ณ สนามแข่งขัน จํานวนไม่น้อยกว่า 10 ภาพ ที่แสดงให้เห็นภาพรวมของสนาม และผู้ชมการแข่งขัน ณ สนามแข่งขันเอกสารหลักฐานคลิปวีดีโอการแข่งขันที่เห็นภาพรวมของสนาม การแข่งขันและผู้ชมการแข่งขัน ณ สนามแข่งขัน ความยาวไม่เกิน 5 นาที",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "151",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1397",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "20",
              "title": "3.7 ระดับความสุขของผู้ชมการแข่งขัน",
              "index": 6,
              "role": "organizer",
              "questions": [
                {
                  "id": "33",
                  "index": 0,
                  "question": "3.7.1 เอกสารหลักฐานรายงานผลการจัดทําระดับความสุข ซึ่งอย่างน้อยต้องแสดงรายละเอียดวิธีการสํารวจ กรอกคะแนนเฉลี่ยของแต่ละตัวชี้วัดย่อย ค่าเฉลี่ยรวม โดยแสดงผลเป็นตารางข้อมูล และกราฟ พร้อมคําบรรยาย และวิเคราะห์สาเหตุที่ทําให้ระดับความสุขสูง หรือตํ่ากว่าปกติ โดยแยกเล่มรายงาน การประเมินตามรายการการแข่งขันกีฬาอาชีพ ในกรณีที่มีหลายรายการแข่งขันและจัดทําในรายงาน เล่มเดียวกัน จะต้องแยกแต่ละรายการแข่งขันออกจากกันอย่างชัดเจน",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "158",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1402",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            },
            {
              "id": "21",
              "title": "3.8 กิจกรรมสร้างสรรค์กีฬาอาชีพ",
              "index": 7,
              "role": "organizer",
              "questions": [
                {
                  "id": "34",
                  "index": 0,
                  "question": "3.8.1 เอกสารหลักฐานแสดงรายละเอียดการจัดกิจกรรมสร้างสรรค์ซึ่งอย่างน้อยต้องประกอบด้วยชื่อกิจกรรม สถานที่จัดกิจกรรม รายละเอียดกิจกรรม ระยะเวลาการจัดกิจกรรม กลุ่มผู้เข้าร่วมกิจกรรม จํานวนผู้เข้าร่วมกิจกรรม เป็นต้น และภาพถ่ายระหว่างจัดกิจกรรม จํานวนไม่น้อยกว่า 10 ภาพ/กิจกรรม ที่สามารถสื่อให้เข้าใจถึงกิจกรรมที่จัด, คลิปวิดีโอกิจกรรมความยาวไม่เกิน 5 นาที/กิจกรรม หรือเอกสารอื่นๆ ประกอบการพิจารณา",
                  "maxPoint": 5,
                  "questionType": [
                    {
                      "id": "163",
                      "type": "choice",
                      "title": "เลือกคำตอบ",
                      "keyName": "choice",
                      "option": [
                        {
                          "id": 1,
                          "value": true,
                          "title": "มี"
                        },
                        {
                          "id": 2,
                          "value": false,
                          "title": "ไม่มี"
                        }
                      ],
                      "result": [
                        {
                          "id": "1405",
                          "result": "false",
                          "status": "success",
                          "typerole": "organizer"
                        }
                      ]
                    }
                  ]
                }
              ],
              "status": "success"
            }
          ],
          "status": "success"
        }
      ],
      "currentSection": {
        "parentId": "1",
        "stepIndex": 0,
        "subIndicatorID": "2"
      }
    }
  ]
} */

const Form = (props: ResIndicator) => {
  const { assessmentid } = useParams<any>();
  const history = useHistory();
  const { token, userInfo } = useAuthContextState();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLoadingAnswer, setIsLoadingAnswer] = React.useState<boolean>(true);
  const [disableBottonSend, setDisableBottonSend] = React.useState<boolean>(true);
  const [percentProps, setPercentProps] = React.useState<number>(0);
  const [assessmentStatus, setAssessmentStatus] = React.useState<string>('draft');
  const [statusQuestion, setStatusQuestion] = React.useState<{ id: string; status: string }[] | null>(null);
  const [currentSection, setCurrentSection] = React.useState<CurrentSection | null>(null);
  const [formAssessmentInfo, setFormAssessmentInfo] = React.useState<DataResults | null>(null);
  const [questionsInfo, setQuestionsInfo] = React.useState<Questions | null>(null);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

  const getFormAssessmentInfo = async (assessmentid: string) => {
    try {
      const { res } = await GetIndicatorApi(token.accesstoken, assessmentid);
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        setIsLoading(false);
        setPercentProps(res.result[0].processingStatus);
        setAssessmentStatus(res.result[0].status);
        setFormAssessmentInfo(res.result[0]);
        const dataStatus: { id: any; status: any }[] = [];
        res?.result[0].evaluation.map((data: any) => {
          return data.subIndicator.map((dataStepIndi: any) => {
            dataStatus.push({ id: dataStepIndi.id, status: dataStepIndi.status });
            return { id: dataStepIndi.id, status: dataStepIndi.status }
          });
        });
        setStatusQuestion(dataStatus);
        const newCurrentSection = {
          parentId: res?.result[0]?.currentSection["parentId"],
          stepIndex: res?.result[0]?.currentSection["stepIndex"],
          subIndicatorID: res?.result[0]?.currentSection["subIndicatorID"],
        };
        setCurrentSection(newCurrentSection);
        getQuestionBySubIdAndQuestionIndexApi(assessmentid, newCurrentSection.subIndicatorID, newCurrentSection.stepIndex)
      }
    } catch (error: any) {
      history.push(error.message)
    }
  };

  const goBack = () => {
    history.goBack();
  };
  React.useEffect(() => {
    getFormAssessmentInfo(assessmentid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeMenu = (parentId: string, subIndicatorID: string) => {
    getQuestionBySubIdApi(assessmentid, subIndicatorID)
  };
  const handleEvaluationStatus = (evaluationStatus: StatusQuestion[], subIndicatorStatus: StatusQuestion[]) => {
    if (!formAssessmentInfo) return false;
    const newEvaluation = formAssessmentInfo.evaluation.map((item) => {
      const newStatus = _findObjectOfArrayById(evaluationStatus, item.id);
      if (!!newStatus) return { ...item, status: newStatus.status };
      return item
    })
    setFormAssessmentInfo({ ...formAssessmentInfo, evaluation: newEvaluation })
  };
  const getQuestionBySubIdApi = (evaluationID: string, subIndicatorID: string, indexQuestion: number = 0) => {
    getQuestion(evaluationID, subIndicatorID, indexQuestion, GetQuestionBySubIdApi)
  }
  const getQuestionBySubIdAndQuestionIndexApi = (evaluationID: string, subIndicatorID: string, indexQuestion: number) => {
    getQuestion(evaluationID, subIndicatorID, indexQuestion, GetQuestionApiById)
  }
  const getQuestion = async (
    evaluationID: string,
    subIndicatorID: string,
    indexQuestion: number,
    GetQuestionService: <T = any>(token: string, evaluationID: string, subIndicatorID: string, questionID: number) => Promise<{
      res: T | null;
      message: string;
    }>
  ) => {
    try {
      const { res } = await GetQuestionService<ResQuestionsById>(
        token.accesstoken,
        evaluationID,
        subIndicatorID,
        indexQuestion
      );
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res?.status === "success") {
        const result = res?.result[0]
        setCurrentSection({ parentId: result.idIndicator.toString(), subIndicatorID: result.subIndicatorID.toString(), stepIndex: result.currentIndex });
        setQuestionsInfo(res?.result[0]);
        setIsLoadingAnswer(false);
      }
    } catch (error: any) {
      history.push(error.message)
    }
  };

  return (
    <ContentPage
      headerContent={
        <div
          className="hn-title-center"
          style={{ display: "flex", flexWrap: "nowrap" }}
        >
          <Button
            style={{
              backgroundColor: "white",
              height: "40px",
              width: "40px",
              borderRadius: "5px",
            }}
            onClick={() => {
              goBack();
            }}
            icon={<CaretLeftOutlined />}
          ></Button>
          <Row style={{ width: '770px' }}>
            <Col xs={8} xl={8} >
              <div className="hn-title-text">
                <div className="text-title-white">การประเมินผลสัมฤทธิ์</div>
                <div className="header-font-subtitle">{userInfo.title}</div>
              </div>
            </Col>
            <Col xs={10} xl={10} >
              <Row justify="space-between" align="middle">
                <Col>
                  <div className="content-form-status">
                    <div>สถานะ</div>
                    <div>&nbsp;:&nbsp;</div>
                    <div>
                      <AssessmentIcon
                        values={{
                          path: 'none',
                          status: assessmentStatus,
                          icon: Icons.find((icon) => icon.status === assessmentStatus)?.icon
                        }}
                      />
                    </div>
                  </div >
                </Col>
                <Col>
                  <RejectMomalMember comment={formAssessmentInfo?.comment} setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />
                  <Button
                    style={{
                      backgroundColor: "white",
                      height: "45px",
                      width: "45px",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setIsModalVisible(true)
                    }}
                    icon={
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Badge dot={!!formAssessmentInfo?.comment.officer} size="default" offset={[-2, 2]}>
                          <NotificationOutlined />
                        </Badge>
                      </div>
                    }
                  ></Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div >
      }
    >
      <Row gutter={[4, 4]} style={{ height: "100%" }} justify="end">
        <Col xs={24} md={6}>
          <div style={{ position: "absolute", width: "100%", paddingRight: 10 }}>
            <FormIndicators
              disableBottonSend={disableBottonSend}
              values={formAssessmentInfo}
              onChange={handleChangeMenu}
              IsLoading={isLoading}
              percentProps={percentProps}
              currentSection={currentSection}
              statusQuiestion={statusQuestion}
            />
          </div>
        </Col>
        <Col
          xs={24}
          md={18}
          className="boxleft-container"
          style={{ paddingBottom: 10 }}
        >
          {!!isLoadingAnswer && (
            <div
              className="boxright-FormAssessment"
              style={{ minHeight: "80vh", padding: 20 }}
            >
              <Skeleton active />
            </div>
          )}
          {(currentSection && !isLoadingAnswer && !!questionsInfo) && (
            <FormQuestionAndAnswer
              setDisableBottonSend={setDisableBottonSend}
              setAssessmentStatus={setAssessmentStatus}
              values={questionsInfo}
              onStatusChang={handleEvaluationStatus}
              setValuesInfo={setQuestionsInfo}
              currentSection={currentSection}
              assessmentInfo={formAssessmentInfo}
              setPercentProps={setPercentProps}
              percentProps={percentProps}
              setCurrentSection={setCurrentSection}
              setStatusQuestion={setStatusQuestion}
              _getQuestionService={{
                getQuestionBySubIdApi: getQuestionBySubIdApi,
                getQuestionBySubIdAndQuestionIndexApi: getQuestionBySubIdAndQuestionIndexApi
              }}
            />
          )}
          {(!isLoadingAnswer && !questionsInfo) && (
            <div
              className="boxright-FormAssessment center"
              style={{ minHeight: "80vh" }}
            >
              <NoData icon={<ContainerOutlined />} />
            </div>
          )}
        </Col>
      </Row>
    </ContentPage >
  );
};

export default Form;
