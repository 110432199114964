export enum FormItemType {
  INPUTTEXT = "inputChannel",
  CHOICE = "choice",
  INPUT_LINK = "inputLink",
  ATTACHMENT = "attachment",
  UPLOAD_FILES = "uploadFiles",
  INPUT_NUMBER = "inputNumber",
  INPUT_DECIMAL_NUMBER = "inputDecimalNumber",
  INPUT_CHANNEL = "inputChannel",
  TEXT_AREA = "textArea",
}
export type AttachmentType = {
  [FormItemType.INPUT_LINK]: FormItemType.INPUT_LINK;
  [FormItemType.UPLOAD_FILES]: FormItemType.UPLOAD_FILES;
};
