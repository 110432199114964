import { useAuthContextState } from '@authContext/store';
import { ROLE_ENUM } from '@interface/role';
import { Modal } from 'antd';
import React from 'react';

interface props {
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isModalVisible: boolean;
  comment?: {
    sector: string,
    officer: string
  } | null
}

function RejectMomalMember({ setIsModalVisible, isModalVisible, comment }: props) {

  const { userInfo } = useAuthContextState()
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <Modal footer={null} title="ความคิดเห็น" visible={isModalVisible} onCancel={handleCancel}>
      {userInfo.role !== ROLE_ENUM.OFFICER && <p>เจ้าหน้าที่ : {comment?.officer}</p>}
      <p>ผู้บริหาร : {comment?.sector}</p>
    </Modal>
  );
}

export default RejectMomalMember;
