import React from 'react';
import { Button, Result, Row } from 'antd';
import { useHistory } from "react-router-dom";
import { useAuthContextDispatch } from '@authContext/store';

const NoFoundPage: React.FC = () => {

  const history = useHistory()
  const { _signOut } = useAuthContextDispatch()
  const handleSignOut = async () => {
    await _signOut()
    history.push("/#/login")
  }
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Row>
          <Row justify='center' align='middle' style={{ width: '100%', height: '40px' }}>
            <Button type="primary" style={{ width: '100px' }} onClick={() => history.push('/')}>
              Back Home
            </Button>
          </Row>
          <Row justify='center' align='middle' style={{ width: '100%', height: '40px' }}>
            <Button style={{ width: '100px' }} type="default" onClick={handleSignOut}>
              Log Out
            </Button>
          </Row>
        </Row>
      }
    />
  )
};

export default NoFoundPage;
