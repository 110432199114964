export enum ROLE_ENUM {
  ADMIN = "superuser", // admin
  OFFICER = "officer", // เจ้าหน้าที่
  SECTOR = "sector", // ทุกภาคส่วน
  ASSESSOR = "assessor", //ผู้ประเมิน
}
export enum ASSESSOR_TYPE_ENUM {
  ORGANIZER = "organizer", // ผู้จัด
  CLUB = "club", // สโมสร
  ASSOCIATION = "association", // สมาคม
}

export enum TYPE_ENUM {
  CLUB = "club", // สโมสร
  ORGANIZER = "organizer", // ผู้จัด
  ASSOCIATION = "association", // สมาคม

  ADMIN = "superuser", // admin
  SECTOR = "sector", // ทุกภาคส่วน
  OFFICER = "officer", // เจ้าหน้าที่
  ASSESSOR = "assessor",
}
export enum ASSOCIATION_ENUM_TH {
  OFFICER = "เจ้าหน้าที่", // เจ้าหน้าที่
  SECTOR = "ผู้บริหาร", // ทุกภาคส่วน
  ASSESSOR = "ผู้ประเมิน", //ผู้ประเมิน
  CLUB = "สโมสร", // สโมสร
  ORGANIZER = "ผู้จัด", // ผู้จัด
  ASSOCIATION = "สมาคม", // สมาคม
}
