import React from 'react'
import { Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
interface Props {
  icon?: React.ReactNode;
  title?: string;
  subTitle?: string;
}

const NoData = ({ icon, title, subTitle }: Props) => {
  return (
    <Result
      icon={icon || <SmileOutlined />}
      title={title || "แบบประเมินผลสัมฤทธิ์"}
      subTitle={subTitle || "สมาคมกีฬาฟุตบอลแห่งประเทศไทย"}
    // extra={<Button type="primary">Next</Button>}
    />
  )
}

export default NoData
