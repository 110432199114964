import React from "react";
import Swal from "sweetalert2";
import { Col, Form, Row } from "antd";
import appConfig from "@config/app.config";
import FormItemAction from "../FormItemAction";
import { useParams } from "react-router-dom";
import { useAuthContextState } from "@authContext/store";
import { InsertAnswerById } from "@services/api/assessment";
import { DataResults, Questions } from "@interface/assessment";
import { FormItemType } from "@components/form/CreateFormItem/interface";
import RenderFormItem from "@components/form/CreateFormItem/_RenderFormItem";
import { CurrentSection, StatusQuestion } from "@interface/pages/assessment";

type Props = {
  values: Questions;
  formProcessStatus: "onProcess" | "success" | "finish";
  assessmentInfo: DataResults | null;
  currentSection: CurrentSection;
  _getQuestionService: _GetQuestionService;
  editStatus: boolean;

  onStatusChang: (evaluationStatus: StatusQuestion[], subIndicatorStatus: StatusQuestion[]) => void;
  setValuesInfo: React.Dispatch<React.SetStateAction<Questions | null>>;
  setPercentProps: React.Dispatch<React.SetStateAction<number>>;
  setStatusQuestion: React.Dispatch<React.SetStateAction<StatusQuestion[] | null>>;
  setCurrentSection: React.Dispatch<React.SetStateAction<CurrentSection | null>>;
  setFormProcessStatus: React.Dispatch<React.SetStateAction<"onProcess" | "success" | "finish">>;
};

interface _GetQuestionService {
  getQuestionBySubIdApi: (evaluationID: string, subIndicatorID: string) => void;
  getQuestionBySubIdAndQuestionIndexApi: (evaluationID: string, subIndicatorID: string, indexQuestion: number) => void;
}
const FormAnswer = (props: Props) => {

  const [form] = Form.useForm<FormItemType>();
  const { token } = useAuthContextState();
  const { assessmentid } = useParams<any>()
  const [insertLoading, setInsertLoading] = React.useState<boolean>(false)
  const [variableAttachment, setVariableAttachment] = React.useState<boolean>(false)
  const [variableDescription, setVariableDescription] = React.useState<boolean>(true)
  const [variablePoint, setVariablePoint] = React.useState<boolean>(false)

  React.useEffect(() => {
    initData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values])
  const initData = () => {
    if (props.values.answer !== null) {
      const valueMap = props.values.answer.map((val) => {
        if (val.type !== "attachment" && val.results) {
          if (Array.isArray(val.results)) {
            return val.results.map((re) => {
              if (re.value !== null) {
                setVariableAttachment(JSON.parse(re.value));
                return { [val.keyName]: JSON.parse(re.value) };
              } else {
                setVariableAttachment(false);
                return { [val.keyName]: re.value };
              }
            });
          }
        }
        return val.options.map((op) => {
          if (Array.isArray(op.results)) {
            if (op.keyName === 'choice1') {
              return { [op.keyName]: JSON.parse(op.results[0].value) };
            } else {
              const file = op.results.map((reOp) => {
                return {
                  uuid: Number(reOp.id),
                  name: reOp.fileName,
                  status: 'done',
                  url: `${appConfig.apiURL}/${reOp.value}`,
                  thumbUrl: `${appConfig.apiURL}/${reOp.value}`,
                }
              });
              return { [op.keyName]: file };
            }
          } else if (op.keyName === "inputLink") {
            if (Array.isArray(op.results?.value)) {
              const link = op.results?.value.map((dataLink: any) => {
                // return { todo: dataLink, isCompleted: true };
                return dataLink;
              });
              return { [op.keyName]: link || [] };
            } else {
              return { [op.keyName]: [] };
            }
          } else {
            if (op.keyName === 'point') {
              if (!!op.results?.value) {
                setVariablePoint(true);
              } else {
                setVariablePoint(false);
              }
              return { [op.keyName]: op.results?.value };
            } else {
              return { [op.keyName]: op.results?.value };
            }
          }
        });
      });
      const renderValue = valueMap?.flat().reduce((data, cur) => {
        return { ...data, ...cur };
      }, {});
      form.setFieldsValue(renderValue)
    }
  }
  const handleNext = () => {
    const stepValue = props.values.stapIndex
    if (!!stepValue && !!props.currentSection && ((stepValue.length - 1) > props.currentSection.stepIndex) && !!props.currentSection) {
      const newCurrentSection = { parentId: props.currentSection.parentId, subIndicatorID: props.currentSection.subIndicatorID, stepIndex: props.currentSection.stepIndex + 1 }
      props.setCurrentSection(newCurrentSection)
      props._getQuestionService.getQuestionBySubIdApi(assessmentid, newCurrentSection.subIndicatorID)
    }
  };
  const handleOnFinish = async (values: any) => {
    setInsertLoading(true)
    const formFile = new FormData()
    let result: { answer: any, file: any, idFile: any } = { answer: [], file: [], idFile: [] };
    if (!props.values) return false

    /* if (uploadFiles !== undefined && uploadFiles.length > 0) {
      uploadFiles.forEach((img: any) => {
        if (img?.originFileObj !== undefined) {
          formFile.append('file', img.originFileObj)
        } else {
          if (img?.uploadFiles !== undefined) {
            result.idFile.push({
              "fileId": img.uuid
            })
          } else {
            result.idFile.push({
              "fileId": img.uuid
            })
          }
        }
      })

    } else if (uploadFiles !== undefined) {
      uploadFiles.forEach((img: any) => {
        result.idFile.push({
          "fileId": img.uuid
        })
      })
    } */

    // props.values.answer.map((item) => {
    //   if (item.keyName === 'choice') {
    //     result.answer.push({
    //       "result": choice,
    //       "resultId": item.resultId
    //     })
    //     return {
    //       "result": choice, "resultId": item.resultId
    //     }
    //   }
    //   if (item.keyName === 'choice1') {
    //     result.answer.push({
    //       "result": choice1,
    //       "resultId": item.resultId
    //     })
    //     return {
    //       "result": choice1,
    //       "resultId": item.resultId
    //     }
    //   }
    //   return item
    // })
    // const [attachment] = props.values.answer.filter((item) => item.keyName === "attachment");
    // attachment.options.forEach((item) => {
    //   const { answer } = result
    //   if (item.type === "uploadFiles") {
    //     result = {
    //       ...result, answer: [...answer, {
    //         "result": '-',
    //         "resultId": item.resultId
    //       }]
    //     }
    //     result = {
    //       ...result, file: formFile
    //     }
    //   }
    //   if (item.type === "inputNumber") {
    //     result = {
    //       ...result, answer: [...answer, {
    //         "result": inputNumber,
    //         "resultId": item.resultId
    //       }]
    //     }
    //   }
    //   if (item.type === "inputChannel") {
    //     result = {
    //       ...result, answer: [...answer, {
    //         "result": inputChannel,
    //         "resultId": item.resultId
    //       }]
    //     }
    //   }
    //   if (item.type === "inputLink") {
    //     let linkList = []
    //     for (const property in inputLink) {
    //       linkList.push(inputLink[property])
    //     }
    //     let newarr = JSON.stringify(linkList)
    //     result = {
    //       ...result, answer: [...answer, {
    //         "result": newarr,
    //         "resultId": item.resultId
    //       }]
    //     }
    //   }
    // })
    props.values.answer.forEach((item) => {
      if (item.keyName === 'attachment') {
        Object.entries(values).forEach((data: any) => {
          item.options.forEach((dataOption) => {
            if (dataOption.keyName === data[0]) {
              if (data[0] === 'inputLink') {
                let newarr = JSON.stringify(data[1])
                result.answer.push({
                  "result": newarr,
                  "resultId": dataOption.resultId
                })

              } else if (data[0] === 'uploadFiles') {
                data[1].forEach((dataImage: any) => {
                  if (!!dataImage.originFileObj) {
                    formFile.append('file', dataImage.originFileObj)
                  } else {
                    result.idFile.push({
                      "fileId": dataImage.uuid
                    })
                  }
                });
                result.answer.push({
                  "result": "มี",
                  "resultId": dataOption.resultId
                })
              } else {
                if (data[0] === 'point') {
                  result.answer.push({
                    "result": data[1],
                    "resultId": dataOption.resultId
                  })
                } else {
                  if (data[0] === 'choice1') {
                    result.answer.push({
                      "result": String(data[1]),
                      "resultId": dataOption.resultId
                    })
                  } else {
                    result.answer.push({
                      "result": data[1] || null,
                      "resultId": dataOption.resultId
                    })
                  }

                }
              }
            }
          })
        })
      } else {
        Object.entries(values).forEach((data) => {
          if (item.keyName === data[0]) {
            result.answer.push({
              "result": String(data[1]),
              "resultId": item.resultId
            })
          }
        })
      }
    })
    console.log('props.values.answer', props.values.answer);
    console.log('value', values);
    console.log('idFile', result.idFile);
    console.log('answer', result.answer);
    formFile.append('answer', JSON.stringify(result.answer))
    formFile.append('idFile', JSON.stringify(result.idFile))
    const res = await InsertAnswerById(token.accesstoken, assessmentid, formFile)
    if (res.code === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'บันทึกข้อมูลสำเร็จ',
        showConfirmButton: false,
        timer: 1000
      }).then(() => {
        handleNext()
        props.onStatusChang(res.result.titleStatus, res.result.stastusLeft)
        props.setPercentProps(res.result.processingStatus)
        props.setStatusQuestion(res.result.stastusLeft)
        if (props.currentSection.stepIndex === (props.values.stapIndex.length - 1)) {
          const newQuestion = props.values.stapIndex.map((item, ind) => {
            if (props.currentSection.stepIndex === ind) {
              return { ...item, status: "success" }
            }
            return item
          })
          props.setValuesInfo({ ...props.values, stapIndex: newQuestion })
          props._getQuestionService.getQuestionBySubIdApi(assessmentid, props.values.subIndicatorID.toString())
        }
      })
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: res.message,
        showConfirmButton: true,
      })
    }
    setInsertLoading(false)
  }

  const onFormLayoutChange = (changedFields: any) => {
    if (changedFields.length > 0) {

      if (changedFields[0].name[0] === "choice") {
        setVariableAttachment(changedFields[0].value)
      }
      if (changedFields[0].name[0] === "choice1") {
        setVariableDescription(changedFields[0].value)
      }
      if (changedFields[0].name[0] === "point") {
        if (!!changedFields[0].value) {
          setVariablePoint(true);
        } else {
          setVariablePoint(false)
        }
      }
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleOnFinish}
      onFieldsChange={onFormLayoutChange}
    >
      <Col span={24} className="content-boxmaincontent">
        <Row>
          <div className="content-boxsmalltopcontent content-textcenter" style={{ fontSize: '20px' }}>  {props.values.roleName || "-"}  </div>
        </Row>
        <div className="content-boxcontentstep">
          <RenderFormItem variablePoint={variablePoint} values={props.values.answer} variableDescription={variableDescription} variableAttachment={variableAttachment} />
        </div>
      </Col>
      {(!!props.assessmentInfo && !!props.values?.stapIndex && (props.formProcessStatus !== "success" || !props.editStatus)) && (
        <FormItemAction
          editStatus={props.editStatus}
          assessmentInfo={props.assessmentInfo}
          stepValue={props.values.stapIndex}
          currentStep={props.values.currentIndex}
          insertLoading={insertLoading}
          setFormProcessStatus={props.setFormProcessStatus}
          currentSection={{ parentId: props.values.idIndicator.toString(), subIndicatorID: props.values.subIndicatorID.toString(), stepIndex: props.values.currentIndex }}
          setCurrentSection={props.setCurrentSection}
          _getQuestionService={props._getQuestionService}
        />
      )}
    </Form>
  )
};

export default FormAnswer;
