import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Col, Menu, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useAuthContextState } from '@authContext/store';
import { GetAssessmentApi } from '@services/api/assessment';
import { GetYearOfAdminDashboard } from '@services/api/dashboard';
import { AssessmentInfoProps, SportItem, YearOfAchievement } from '@interface/pages/assessment'

interface Props {
  values: AssessmentInfoProps;
  setMenuActive: (value: string) => void;
  setAssessmentInfo?: (value: AssessmentInfoProps) => void;
  menuActive: string;
  onChange: (value: SportItem) => void;
}

const ListMenu = ({ values, setMenuActive, menuActive, setAssessmentInfo }: Props) => {

  const [yearOfAchievementInfo, setYearOfAchievementInfo] = React.useState<YearOfAchievement[] | []>([])
  const [year, setYear] = React.useState<string>('')
  const history = useHistory()
  const { token } = useAuthContextState()

  const filterByYear = async (e: any) => {
    
    try {
      const { res } = await GetAssessmentApi(token.accesstoken, e - 543)
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        !!setAssessmentInfo && setAssessmentInfo(res.result.data[0])
      }
    } catch (error: any) {
      history.push(error.message)
    }
    setYear(e);
  }

  const getAllYearOfAssessment = async () => {
    try {
      const { res } = await GetYearOfAdminDashboard(token.accesstoken)
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        setYearOfAchievementInfo(res.result || [])
        setYear(String(values.year))
      }
    } catch (error: any) {
      history.push(error.message)
    }
  }

  const handleClick = (menu: string) => {
    setMenuActive(menu)
  }

  const menu_years_lock = (
    <Menu >
      {yearOfAchievementInfo.map((data) => (
        <Menu.Item key="1" onClick={() => { filterByYear(data.year) }}>
          พ.ศ. {data.year}
          {/* <img alt="iconLock" width={10} src={Lock} style={{ marginLeft: 10 }} /> */}
        </Menu.Item>
      ))}
    </Menu>
  );
  useEffect(() => {
    getAllYearOfAssessment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Col className="assessmentList-DownStyle">
        <Dropdown overlay={menu_years_lock} trigger={['click']}>
          <Button className="assessmentList-CaretDown"
            style={{ width: '100%', height: '3rem' }} >
            <div className="assessmentList-dropdown">
              <b>ปี {year} </b>
              <CaretDownOutlined />
            </div>
          </Button>
        </Dropdown>
      </Col>
      {values.consensus.map((role, index) => (
        <Button
          key={`${index}-${role.id}`}
          type="link"
          onClick={() => handleClick(role.assessorType)}
          // className="assessmentList-boxmenupic"
          className={`assessmentList-boxmenupic ${menuActive === role.assessorType ? 'active' : ""}`}
        >
          <Col md={6}>
            <div className="assessmentList-textrole">{role.assessorType}</div>
          </Col>
        </Button>
      ))}
    </>
  )
}
export default ListMenu


