import React from "react";
import { Row, Col, Button, Skeleton, Badge } from "antd";
import NoData from "@components/NoData";
import FormQuestionAndAnswer from "@components/form/Assessments/Content/FormQuestionAndAnswer";
import ContentPage from "@layout/MainLayout/Header/Content";
import FormIndicators from "@components/form/Assessments/Sider/Indicators";
import { GetIndicatorApi, GetQuestionApiById, GetQuestionBySubIdApi } from "@services/api/assessment";
import { ContainerOutlined, NotificationOutlined, CaretLeftOutlined } from "@ant-design/icons";
import { useAuthContextState } from "@authContext/store";
import { _findObjectOfArrayById } from '@utils/utils'
import { useHistory, useParams } from "react-router-dom";
import { CurrentSection, StatusQuestion } from "@interface/pages/assessment";
import { ReloadOutlined } from "@ant-design/icons";
import { DataResults, Questions, ResIndicator, ResQuestionsById, } from "@interface/assessment";
import AssessmentIcon from "@components/icon/RenderIcon/assessmentIcon";
import { Icons } from "@components/icon/RenderIcon/iconStatus";
import RejectMomalAdmin from "./rejectModal/rejectMomalAdmin";
import { ROLE_ENUM } from "@interface/role";
import RejectMomalMember from "@page/member/assessment/assesmentDetial/rejectModal/RejectMomalMember";
import DownloadExcel from "../download/downloadExcel";


const Form = (props: ResIndicator) => {

  const { assessmentid } = useParams<any>();
  const history = useHistory();
  const { token, userInfo } = useAuthContextState();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLoadingAnswer, setIsLoadingAnswer] = React.useState<boolean>(true);
  const [percentProps, setPercentProps] = React.useState<number>(0);
  const [statusQuestion, setStatusQuestion] = React.useState<{ id: string; status: string }[] | null>(null);
  const [currentSection, setCurrentSection] = React.useState<CurrentSection | null>(null);
  const [formAssessmentInfo, setFormAssessmentInfo] = React.useState<DataResults | null>(null);
  const [assessmentStatus, setAssessmentStatus] = React.useState<string>('draft');
  const [disableBottonSend, setDisableBottonSend] = React.useState<boolean>(true);
  const [questionsInfo, setQuestionsInfo] = React.useState<Questions | null>(null);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [isModalVisibleReject, setIsModalVisibleReject] = React.useState<boolean>(false);

  const getFormAssessmentInfo = async (assessmentid: string) => {
    try {
      const { res } = await GetIndicatorApi(token.accesstoken, assessmentid);
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        setIsLoading(false);
        setPercentProps(res.result[0].processingStatus);
        setFormAssessmentInfo(res.result[0]);
        setAssessmentStatus(res.result[0].status);
        const dataStatus: { id: any; status: any }[] = [];
        res?.result[0].evaluation.map((data: any) => {
          return data.subIndicator.map((dataStepIndi: any) => {
            dataStatus.push({ id: dataStepIndi.id, status: dataStepIndi.status });
            return { id: dataStepIndi.id, status: dataStepIndi.status }
          });
        });
        setStatusQuestion(dataStatus);
        const newCurrentSection = {
          parentId: res?.result[0]?.currentSection["parentId"],
          stepIndex: res?.result[0]?.currentSection["stepIndex"],
          subIndicatorID: res?.result[0]?.currentSection["subIndicatorID"],
        };
        setCurrentSection(newCurrentSection);
        getQuestionBySubIdAndQuestionIndexApi(assessmentid, newCurrentSection.subIndicatorID, newCurrentSection.stepIndex)
      }
    } catch (error: any) {
      history.push(error.message)
    }
  };
  const goBack = () => {
    history.goBack();
  };

  React.useEffect(() => {
    getFormAssessmentInfo(assessmentid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeMenu = (parentId: string, subIndicatorID: string) => {
    getQuestionBySubIdApi(assessmentid, subIndicatorID)
  };
  const handleEvaluationStatus = (evaluationStatus: StatusQuestion[], subIndicatorStatus: StatusQuestion[]) => {
    if (!formAssessmentInfo) return false;
    const newEvaluation = formAssessmentInfo.evaluation.map((item) => {
      const newStatus = _findObjectOfArrayById(evaluationStatus, item.id);
      if (!!newStatus) return { ...item, status: newStatus.status };
      return item
    })
    setFormAssessmentInfo({ ...formAssessmentInfo, evaluation: newEvaluation })
  };
  const getQuestionBySubIdApi = (evaluationID: string, subIndicatorID: string, indexQuestion: number = 0) => {
    getQuestion(evaluationID, subIndicatorID, indexQuestion, GetQuestionBySubIdApi)
  }
  const getQuestionBySubIdAndQuestionIndexApi = (evaluationID: string, subIndicatorID: string, indexQuestion: number) => {
    getQuestion(evaluationID, subIndicatorID, indexQuestion, GetQuestionApiById)
  }
  const getQuestion = async (
    evaluationID: string,
    subIndicatorID: string,
    indexQuestion: number,
    GetQuestionService: <T = any>(token: string, evaluationID: string, subIndicatorID: string, questionID: number) => Promise<{
      res: T | null;
      message: string;
    }>
  ) => {
    const { res } = await GetQuestionService<ResQuestionsById>(
      token.accesstoken,
      evaluationID,
      subIndicatorID,
      indexQuestion
    );
    if (!res) return false
    if (res?.status === "success") {
      const result = res?.result[0]
      setCurrentSection({ parentId: result.idIndicator.toString(), subIndicatorID: result.subIndicatorID.toString(), stepIndex: result.currentIndex });
      setQuestionsInfo(res?.result[0]);
      setIsLoadingAnswer(false);
    }
  };

  return (
    <ContentPage
      headerContent={
        <div
          className="hn-title-center"
          style={{ display: "flex", flexWrap: "nowrap" }}
        >
          <Button
            style={{
              backgroundColor: "white",
              height: "40px",
              width: "40px",
              borderRadius: "5px",
            }}
            onClick={() => {
              goBack();
            }}
            icon={<CaretLeftOutlined />}
          ></Button>
          <Row style={{ width: '770px' }}>
            <Col xs={8} xl={8} >
              <div className="hn-title-text">
                <div className="text-title-white">การประเมินผลสัมฤทธิ์</div>
                <div className="header-font-subtitle">{userInfo.title}</div>
              </div>
            </Col>
            <Col xs={userInfo.role === ROLE_ENUM.ADMIN || userInfo.role === ROLE_ENUM.SECTOR ? 11 : 13} xl={userInfo.role === ROLE_ENUM.ADMIN || userInfo.role === ROLE_ENUM.SECTOR ? 10 : 13}>
              <Row justify="space-between" align="middle">
                <Col >
                  <div className="content-form-status">
                    <div>สถานะ</div>
                    <div>&nbsp;:&nbsp;</div>
                    <div>
                      <AssessmentIcon
                        values={{
                          path: 'none',
                          status: assessmentStatus,
                          icon: Icons.find((icon) => icon.status === assessmentStatus)?.icon
                        }}
                      />
                    </div>
                  </div >
                </Col>
                {(userInfo.role === ROLE_ENUM.SECTOR || userInfo.role === ROLE_ENUM.OFFICER) && (
                  <Col>
                    <RejectMomalAdmin id={assessmentid} setIsModalVisible={setIsModalVisibleReject} isModalVisible={isModalVisibleReject} />
                    <Button
                      disabled={(formAssessmentInfo?.processingStatus === 100 && !!disableBottonSend)}
                      style={{
                        backgroundColor: "white",
                        height: "45px",
                        width: "45px",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        setIsModalVisibleReject(true)
                      }}
                      icon={<ReloadOutlined />}
                    />
                  </Col>
                )}
                {userInfo.role === ROLE_ENUM.ADMIN && (
                  <Col >
                    <DownloadExcel assessmentid={assessmentid} />
                  </Col>
                )}
                {userInfo.role === ROLE_ENUM.OFFICER && (
                  <Col>
                    <RejectMomalMember comment={formAssessmentInfo?.comment} setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />
                    <Button
                      style={{
                        backgroundColor: "white",
                        height: "45px",
                        width: "45px",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        setIsModalVisible(true)
                      }}
                      icon={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Badge dot={!!formAssessmentInfo?.comment.sector} size="default" offset={[-2, 2]}>
                            <NotificationOutlined />
                          </Badge>
                        </div>
                      }
                    ></Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div >
      }
    >
      <Row gutter={[4, 4]} style={{ height: "100%" }} justify="end">
        <Col xs={24} md={6}>
          <div style={{ position: "absolute", width: "100%", paddingRight: 10 }}>
            <FormIndicators
              disableBottonSend={disableBottonSend}
              values={formAssessmentInfo}
              onChange={handleChangeMenu}
              IsLoading={isLoading}
              percentProps={percentProps}
              currentSection={currentSection}
              statusQuiestion={statusQuestion}
            />
          </div>
        </Col>
        <Col
          xs={24}
          md={18}
          className="boxleft-container"
          style={{ paddingBottom: 10 }}
        >
          {!!isLoadingAnswer && (
            <div
              className="boxright-FormAssessment"
              style={{ minHeight: "80vh", padding: 20 }}
            >
              <Skeleton active />
            </div>
          )}
          {(currentSection && !isLoadingAnswer && !!questionsInfo) && (
            <FormQuestionAndAnswer
              setDisableBottonSend={setDisableBottonSend}
              setAssessmentStatus={setAssessmentStatus}
              values={questionsInfo}
              onStatusChang={handleEvaluationStatus}
              setValuesInfo={setQuestionsInfo}
              currentSection={currentSection}
              assessmentInfo={formAssessmentInfo}
              setPercentProps={setPercentProps}
              percentProps={percentProps}
              setCurrentSection={setCurrentSection}
              setStatusQuestion={setStatusQuestion}
              _getQuestionService={{
                getQuestionBySubIdApi: getQuestionBySubIdApi,
                getQuestionBySubIdAndQuestionIndexApi: getQuestionBySubIdAndQuestionIndexApi
              }}
            />
          )}
          {(!isLoadingAnswer && !questionsInfo) && (
            <div
              className="boxright-FormAssessment center"
              style={{ minHeight: "80vh" }}
            >
              <NoData icon={<ContainerOutlined />} />
            </div>
          )}
        </Col>
      </Row>
    </ContentPage>
  );
};

export default Form;
