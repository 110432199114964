import React  from 'react'
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Button, Select, Skeleton } from 'antd';

import Content from '@layout/MainLayout/Header/Content';
import { CaretDownOutlined } from '@ant-design/icons';
import { AssessmentProps, YearOfAchievement } from '@interface/pages/assessment'
import { GetAchievementByYear, GetAchievementInfo, GetYearOfAsses } from '@services/api/assessment'

import ListMember from "./Lists";
import { useAuthContextState } from '../../../context/Auth/store'

const { Option } = Select;

const AssessmentList = (props: AssessmentProps) => {
  const { token, userInfo } = useAuthContextState()
  const history = useHistory()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [AchievementInfo, setAchievementInfo] = React.useState<YearOfAchievement[] | []>([])
  const [YearOfAchievementInfo, setYearOfAchievementInfo] = React.useState<YearOfAchievement[] | []>([])
  const [IsCreate,setIsCreate] = React.useState<boolean>(false)

  const getAssessmentMemberInfo = async () => {
    try {
      const { res } = await GetAchievementInfo(token.accesstoken)
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        setIsCreate(res.result.isCreate)
        setAchievementInfo(res.result.data || [])
        setLoading(false)
      }
    } catch (error: any) {
      history.push(error.message)
    }
  }
  const getYeraOfAssec = async () => {
    try {
      const { res } = await GetYearOfAsses(token.accesstoken)
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        setYearOfAchievementInfo(res.result.items || [])
      }
    } catch (error: any) {
      history.push(error.message)
    }
  }

  const selecByYear = async (value: string) => {
    try {
      const { res } = await GetAchievementByYear(token.accesstoken, Number(value)- 543)
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {       
        setIsCreate(res.result.isCreate)
        setAchievementInfo(res.result.data || [])
        setLoading(false)
      }
    } catch (error: any) {
      history.push(error.message)
    }
  }

  React.useEffect(() => {
    let isMount = true
    if (isMount) {
      getAssessmentMemberInfo()
      getYeraOfAssec()
    }
    return () => { isMount = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Content
      headerContent={
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="hn-title-text">
            <div className="text-title-white">สร้างแบบประเมินผลสัมฤทธิ์</div>
            <div className="header-font-subtitle">{userInfo.title}</div>

          </div>
          <div className='member-select-year'>
            <Select
              size='large'
              style={{ width: '150px' }}
              defaultValue="ทั้งหมด"
              onChange={(value: any) => selecByYear(value)}
              suffixIcon={<CaretDownOutlined style={{ color: '#000', height: '100px' }} />}
            >
              {YearOfAchievementInfo.map((data, index) => (
                <Option key={String(index)} value={String(data.year)}>ปี :  {data.year}</Option>
              ))}
            </Select>
          </div>
        </div>

      }
    >
      <Row style={{ height: '100%' }}>
        <Col xs={24} className="assessmentListMember-mainbox">
          <Row style={{ height: '100%' }}>
            <Col xs={24} className="assessmentListMember-mainbox">
              {!!AchievementInfo.length && (
                <Row className="assessmentMember-textAchievement text-title-black ">
                  <Col className="text-primary">
                    ปีการประเมินผลสัมฤทธิ์
                  </Col>
                  <Col>
                    {IsCreate ? <Link to="assessment/create" >
                      <Button className="assessmentList-boxCreateAchievement"
                        style={{ color: '#fff', fontSize: '18px' }}>
                        สร้างแบบประเมินผลสัมฤทธิ์
                      </Button>
                    </Link> : <Button disabled className="assessmentList-boxCreateAchievement"
                      style={{ color: '#000', fontSize: '18px' }}>
                      ไม่อนุญาตสร้างแบบประเมินผลสัมฤทธิ์
                    </Button>}

                  </Col>
                </Row>
              )}
              {!!AchievementInfo.length && (
                <Row className="assessmentListMember-boxcontent">
                  <Col span={24}>
                    <ListMember values={AchievementInfo} />
                  </Col>
                </Row>
              )}
              {!!loading && <div style={{ padding: 20 }}><Skeleton active /></div>}
              {(!AchievementInfo.length && !loading) && (
                <Row className="assessmentListMember-boxcontent" justify="center" style={{ height: '100%' }}>
                  <Col className="center column" >
                    <h2 className="title-FormAssessmentList">แบบประเมินผลสัมฤทธิ์</h2>
                    <img src="/images/no-data.png" alt="no data" style={{ opacity: 0.65 }} />
                    <h2 className="text-primary" style={{ margin: 0 }}>ยังไม่มีแบบประเมินผลสัมฤทธิ์</h2>
                    <h3>กรุณา<span className="text-success" >สร้างแบบประเมิน</span></h3>
                    <Link to="assessment/create" style={{ width: '100%' }}><Button className="submit-done submit-primary indicator-textsubmit " style={{ width: '100%' }}>สร้างแบบประเมิน</Button></Link>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row >
    </Content >
  )
}

export default AssessmentList
