import React from "react";
import { Button } from "antd";
import ListMember from "./Lists";
import { YearOfAchievement } from "@interface/pages/assessment";
import { GetAchievementByYearAndTitle } from "@services/api/assessment";
import { useAuthContextState } from "@authContext/store";
import { CaretLeftOutlined } from "@ant-design/icons";

// import AssessmentForm from './Form';
interface Props {
  valueOfListAchiv: {
    title: string;
    year: number;
    assessorType: string;
  };
  setOpen: (value: boolean) => void;
}
const AssessmentList = ({ valueOfListAchiv, setOpen }: Props) => {
  const [AchievementInfo, setAchievementInfo] = React.useState<
    YearOfAchievement[] | []
  >([]);
  const { token } = useAuthContextState();

  const getAssessmentMemberInfo = async () => {
    const { res } = await GetAchievementByYearAndTitle(
      token.accesstoken,
      valueOfListAchiv.year,
      valueOfListAchiv.title
    );
    if (res.status === "success") {
      setAchievementInfo(res.result || []);
    } else {
      setAchievementInfo([]);
    }
  };
  const goBack = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    let isMount = true;
    if (isMount) getAssessmentMemberInfo();
    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="hader-box-List-admin">
        <Button
          style={{
            backgroundColor: "transparent",
            height: "40px",
            width: "40px",
            borderStyle: "none",
          }}
          onClick={() => {
            goBack();
          }}
          icon={<CaretLeftOutlined />}
        />
        <div className="text-title-black">เลือกโครงการกีฬา</div>
      </div>
      <ListMember
        values={AchievementInfo}
        getAssessmentMemberInfo={getAssessmentMemberInfo}
      />
    </React.Fragment>
  );
};
export default AssessmentList;
