import { FormInstance } from 'antd'
import { ROLE_ENUM } from '@interface/role'
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Select, } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons'
import UploadImg from '@components/UploadImg/UploadImg'
import { useAuthContextState } from '@authContext/store'
import { GetUserAssessorTypeApi, GetUserRole, GetSportApi } from '@services/api/FromUser'

interface Props {
  onFinish: (values: any) => void
  role?: string;
  form?: FormInstance;
  page: "default" | "isEdit" | "isMyprofile",
  valueImage?: any
}

const FromUser = ({ page, role: rolePorps, valueImage }: Props) => {

  const { token } = useAuthContextState()
  const history = useHistory()
  const [sport, setSport] = useState<{ label: string; value: string }[]>([{ label: "", value: "" }])
  const [assessorType, setAssessorType] = useState<{ label: string; value: string }[]>([{ label: "", value: "" }])
  const [role, setRole] = useState<{ label: string; value: string }[]>([{ "label": "แอดมิน", "value": "superuser" },])

  const getUserAssessorType = async () => {
    try {
      const { res } = await GetUserAssessorTypeApi(token.accesstoken)
      if (!res) throw Error("/500");
      if (res.code === 200) {
        setAssessorType(res.result)
      }
    } catch (error: any) {
      history.push(error.message)
    }

  }

  const getRoles = async () => {
    try {
      const { res } = await GetUserRole(token.accesstoken)
      if (!res) throw Error("/500");
      if (res.code === 200) {
        setRole(res.result)
      }
    } catch (error: any) {
      history.push(error.message)
    }

  }

  const getSport = async () => {
    const { res } = await GetSportApi(token.accesstoken)
    try {
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        setSport(res.result.items)
      }
    } catch (error: any) {
      history.push(error.message)
    }
  }

  useEffect(() => {
    getUserAssessorType()
    getRoles()
    getSport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rules: { [key: string]: any } = {
    password: [{ required: (page === 'default' ? true : false), message: 'โปรดป้อนหรัสผ่านให้ครบทุกช่อง!' },
    () => ({
      validator(_: any, value: any) {
        if (!value) return Promise.resolve()
        if (page !== 'isMyprofile') {
          if (value.length >= 6) {
            if (value.search(/[0-9]/) === -1) {
              return Promise.reject(new Error('รหัสผ่านต้องประกอบด้วยตัวอักษร และตัวเลขอย่างน้อย 1 ตัว!'));
            } else {
              /*eslint-disable-next-line*/
              if (value.search(/[!\@\$\#\^\&\(\)\_\+\.\-\:\,\;\*\~\=\{\[\]\"\}\'\<\>\?]/) === -1) {
                return Promise.reject(new Error('รหัสผ่านต้องประกอบด้วยตัวอักษรพิเศษอย่างน้อย 1 ตัว!'));
              } else {
                return Promise.resolve()
              }
            }
          } else {
            return Promise.reject(new Error('รหัสผ่านต้องมีตวามยาวมากกว่าหรือเท่ากับ 6 ตัวอักษร!'));
          }
        } else {
          return Promise.resolve()
        }
      },
    })],
    newpassword: [{ required: (false), message: 'โปรดป้อนหรัสผ่านให้ครบทุกช่อง!' },
    () => ({
      validator(_: any, value: any) {
        if (!value) return Promise.resolve()
        if (value.length >= 6) {
          if (value.search(/[0-9]/) === -1) {
            return Promise.reject(new Error('รหัสผ่านต้องประกอบด้วยตัวอักษร และตัวเลขอย่างน้อย 1 ตัว!'));
          } else {
            /*eslint-disable-next-line*/
            if (value.search(/[!\@\$\#\^\&\(\)\_\+\.\-\:\,\;\*\~\=\{\[\]\"\}\'\<\>\?]/) === -1) {
              return Promise.reject(new Error('รหัสผ่านต้องประกอบด้วยตัวอักษรพิเศษอย่างน้อย 1 ตัว!'));
            } else {
              return Promise.resolve()
            }
          }
        } else {
          return Promise.reject(new Error('รหัสผ่านต้องมีตวามยาวมากกว่าหรือเท่ากับ 6 ตัวอักษร!'));
        }
      },
    })],
    confirmpassword: [{ required: (page === 'default' ? true : false), message: 'โปรดป้อนหรัสผ่านให้ครบทุกช่อง!' },
    ({ getFieldValue }: { getFieldValue: (key: any) => any }) => ({
      validator(_: any, value: any) {
        if (getFieldValue((page === 'default' ? 'password' : 'newpassword')) === value) {
          if (page === 'isMyprofile' && !getFieldValue('password') && !!getFieldValue('newpassword')) {
            return Promise.reject(new Error('โปรดป้อนหรัสผ่านให้ครบทุกช่อง!'));
          }
          if (page === 'isMyprofile' && !!getFieldValue('password') && !getFieldValue('newpassword')) {
            return Promise.reject(new Error('โปรดป้อนหรัสผ่านให้ครบทุกช่อง!'));
          }
          return Promise.resolve()
        }
        return Promise.reject(new Error('รหัสผ่านของคุณไม่สอดคล้องกัน!'));
      },
    })]
  }

  const formItemPassword = {
    default: [
      { label: "รหัสผ่าน", name: "password", rules: rules.password },
      { label: "ยืนยันรหัสผ่าน", name: "confirmpassword", rules: rules.confirmpassword },
    ],
    isEdit: [
      { label: "รหัสผ่านใหม่", name: "newpassword", rules: rules.newpassword },
      { label: "ยืนยันรหัสผ่านใหม่", name: "confirmpassword", rules: rules.confirmpassword },
    ],
    isMyprofile: [
      { label: "รหัสผ่านเก่า", name: "password", rules: [{ required: false, message: 'Please input your password!' }] },
      { label: "รหัสผ่านใหม่", name: "newpassword", rules: rules.newpassword },
      { label: "ยืนยันรหัสผ่านใหม่", name: "confirmpassword", rules: rules.confirmpassword },
    ],
  }

  return (
    <Row gutter={[0, 12]}>
      <Col sm={23} md={23}>
        <Row>
          <Col sm={12} md={12} className="w-100" >
            <div className="form-col-left" style={{ padding: '50px 0px' }} >
              <Form.Item
                className="form-password password"
                name="file"
                valuePropName="file"
              >
                <UploadImg valueImage={valueImage} />
              </Form.Item>
            </div>
            <h1 className="text-title FromUser-title-text">รหัสผ่าน</h1>
            {formItemPassword[page].map((item, ind) => (
              <span key={ind}>
                <div className="form-item-password"><span>{item.label}</span></div>
                <Form.Item
                  className="form-password password"
                  name={item.name}
                  rules={rules[item.name]}
                >
                  <Input.Password />
                </Form.Item>
              </span>
            ))}
          </Col>
          <Col sm={12} md={12}>
            <h1 className="text-title title" style={{ padding: '30px 0px 0' }}>  ข้อมูลผู้ใช้ </h1>
            <span className="form-font ">ชื่อ</span>
            <Form.Item
              className=" form-input"
              name="title"
              rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
            >
              <Input placeholder="กรอกข้อมูล" />
            </Form.Item>
            <Row gutter={[8, 8]}>
              <Col sm={12} md={12} className="w-100" >
                <span className="form-font ">เบอร์ติดต่อ</span>
                <Form.Item
                  className="form-input"
                  name="phone"
                  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                >
                  <Input placeholder="กรอกข้อมูล" />
                </Form.Item>
              </Col>
              <Col sm={12} md={12} className="w-100" >
                <span className="form-font ">อีเมล์</span>
                <Form.Item
                  className="form-input"
                  name="email"
                  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={12} md={12} className="w-100"  >
                <span className="form-font ">บทบาท</span>
                <Form.Item
                  className="select"
                  name="role"
                  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                >
                  <Select
                    disabled={page === 'isMyprofile' || page === 'isEdit'}
                    className="form-button-icon-arrow "
                    placeholder="เลือก"
                    suffixIcon={<CaretDownOutlined />}
                  >
                    {role.map((item, ind) => (
                      <Select.Option value={item.value} key={ind}> {item.label} </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {rolePorps === ROLE_ENUM.ASSESSOR && (
                <>
                  <Col sm={12} md={12} className="w-100" >
                    <span className="form-font ">ประเภทผู้ประเมิน</span>
                    <Form.Item
                      className="select"
                      name="assessorType"
                      rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                    >
                      <Select
                        disabled={page === 'isMyprofile' || page === 'isEdit'}
                        className="form-button-icon-arrow"
                        placeholder="เลือก"
                        suffixIcon={<CaretDownOutlined />}
                      >
                        {assessorType.map((item, ind) => (
                          <Select.Option value={item.value} key={ind}> {item.label} </Select.Option>))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={12} md={12} className="w-100" >
                    <span className="form-font ">ประเภทกีฬา</span>
                    <Form.Item
                      className="select"
                      name="sport"
                      rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                    >
                      <Select
                        disabled={page === 'isMyprofile' || page === 'isEdit'}
                        className="form-button-icon-arrow"
                        placeholder="เลือก"
                        suffixIcon={<CaretDownOutlined />}
                      >
                        {sport.map((item, ind) => (
                          <Select.Option value={item.value} key={ind}> {item.label} </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>                
                    <Col sm={12} md={12} className="w-100" >
                      <span className="form-font ">สถานะการสร้างแบบประเมิน</span>
                      <Form.Item
                        className="select"
                        name="isCreateForm"
                        rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}                      
                      >
                        <Select
                          disabled={page === 'isMyprofile'}
                          className="form-button-icon-arrow"
                          placeholder="เลือก"
                          suffixIcon={<CaretDownOutlined />}
                        >
                          <Select.Option value={String(true)} key={1}> อนุญาต </Select.Option>
                          <Select.Option value={String(false)} key={2}> ไม่อนุญาต </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>                 
                </>
              )}
            </Row>
            <span className="form-font ">ที่อยู่</span>
            <Form.Item
              className="form-address"
              name="address"
              rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
            >
              <Input.TextArea placeholder="กรอกข้อมูล" />
            </Form.Item>
            <Row gutter={[8, 8]}>
              <Col sm={24} md={12} className="w-100"  >
                <span className="form-font ">ผู้ประสานงาน</span>
                <Form.Item
                  className="form-input"
                  name="contactName"
                  rules={[{ required: rolePorps === ROLE_ENUM.ASSESSOR, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                >
                  <Input placeholder="กรอกข้อมูล" />
                </Form.Item>
              </Col>
              <Col sm={12} md={12} className="w-100" >
                <span className="form-font ">เบอร์โทรศัพท์</span>
                <Form.Item
                  className="form-input"
                  name="contactPhone"
                  rules={[{ required: rolePorps === ROLE_ENUM.ASSESSOR, message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }]}
                >
                  <Input placeholder="กรอกข้อมูล" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col >
    </Row >
  )
}

export default React.memo(FromUser)
