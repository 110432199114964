import React, { useState } from 'react';
import { useAuthContextState } from '@authContext/store';
import { sendAnswerSubmit } from '@services/api/assessment';
import { Button, Col, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { name_type } from '@page/admin/assessment/List/CardList/CardListIcon';

interface Props {
  setEditstatus: React.Dispatch<React.SetStateAction<boolean>>;
  nestep: string

}
const FormSuccess = ({ setEditstatus, nestep }: Props) => {
  const history = useHistory()
  const { token } = useAuthContextState()
  const { assessmentid } = useParams() as { assessmentid: string }
  const [loading, setLoading] = useState<boolean>(false)

  const sendQuistion = async () => {
    setLoading(true)
    const { res } = await sendAnswerSubmit(token.accesstoken, assessmentid)
    if (res.code === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'บันทึกข้อมูลสำเร็จ',
        showConfirmButton: false,
        timer: 1000
      }).then(() => {
        setLoading(false)
        history.goBack()
      })
    }
  }
  return (
    <Row justify="center" align="middle" style={{ height: '100%' }}>
      <Col className="form-success center column">
        <div><h2 style={{ fontSize: '2rem' }}>รอดำเนินการ</h2></div>
        <img src="/images/flie-sucess.png" alt="form-success" />
        <div><h2 style={{ margin: 0 }}>คุณกรอกข้อมูลเสร็จสมบูรณ์แล้ว กรุณากด<span className="text-success" >ส่งข้อมูล</span></h2></div>
        <div><h3 className="text-black">เพื่อส่งให้{name_type.find((nameRole) => nameRole.role === nestep)?.roleTH}ประเมิน</h3></div>
        <Button loading={loading} onClick={sendQuistion} className="submit-done indicator-textsubmit shadow" style={{ width: '100%', marginTop: '10px', height: '40px' }}>ส่งข้อมูล</Button>
        <Button onClick={() => { setEditstatus(false) }} type='default' style={{ width: '100%', marginTop: '15px', height: '40px' }}>แก้ไข</Button>
      </Col>
    </Row >
  )
}
export default FormSuccess