import React from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useAuthContextState } from '@authContext/store';
import { Modal, Form, Input, Button, Row, Col } from 'antd';
import { InsertCommentReject } from '@services/api/assessment';

const { TextArea } = Input

interface props {
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isModalVisible: boolean;
  id: number;
}

function RejectMomalAdmin({ setIsModalVisible, isModalVisible, id }: props) {

  const history = useHistory()
  const { token } = useAuthContextState()

  const handleOk = async (values: any) => {
    try {
      Swal.fire({
        title: 'คุณต้องการยืนยันการทำรายการต่อ...หรือไม่ ?',
        text: "หากทำการตีแล้วจะไม่สามารถย้อนกลับได้",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: 'ยกเลิก'
      }).then(async (result) => {
        if (!!result.isConfirmed) {
          const { res } = await InsertCommentReject(token.accesstoken, values.comment || "", id)
          if (!res) throw new Error("/500");
          if (res.code === 400) {
            Swal.fire({
              title: res.message,
              icon: 'warning',
              showConfirmButton: true,
            })
          }
          if (res.code === 401) throw new Error("/401");
          if (res.code === 200) {
            setIsModalVisible(false);
            Swal.fire({
              title: 'บันทึกข้อมูลเรียบร้อย',
              icon: 'success',
            }).then(() => {
              history.goBack()
            })
          }
        }
      })
    } catch (error: any) {
      history.push(error.message)
    }
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (

    <Modal footer={false}
      title="ความคิดเห็น" visible={isModalVisible} onCancel={handleCancel}>
      <Form
        onFinish={handleOk}
      >
        <Form.Item name="comment" >
          <TextArea showCount maxLength={255} rows={5} />
        </Form.Item>
        <Row style={{ width: '100%' }} justify="end">
          <Col >
            <Button
              htmlType="submit"
            >
              ส่ง
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default RejectMomalAdmin;
