import { FetchConfig } from "./interface";
import appConfig from "../../config/app.config";

export const Fetch = async <T>(
  fetchConfig: FetchConfig
): Promise<{ res: T | null; message: string }> => {
  try {
    const path = !!fetchConfig.params
      ? `${fetchConfig.path}${convertSearchParams(fetchConfig.params)}`
      : fetchConfig.path;
    const url = `${appConfig.apiURL}${path}`;
    const res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: fetchConfig.isBasic
          ? "Basic " + btoa("DMTUSER:DMTPASS")
          : `Bearer ${fetchConfig.token}`,
      },
      method: fetchConfig.method,
      body:
        fetchConfig.method === "POST" ? JSON.stringify(fetchConfig.data) : null,
    });
    const dataResponse = await res.json();
    return { res: dataResponse, message: dataResponse.message };
  } catch (error) {
    return { res: null, message: "error" };
  }
};
export const Fetchfile = async (
  fetchConfig: FetchConfig
): Promise<{ res: any | null; message: string }> => {
  try {
    const path = !!fetchConfig.params
      ? `${fetchConfig.path}${convertSearchParams(fetchConfig.params)}`
      : fetchConfig.path;
    const url = `${appConfig.apiURL}${path}`;
    const res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: fetchConfig.isBasic
          ? "Basic " + btoa("DMTUSER:DMTPASS")
          : `Bearer ${fetchConfig.token}`,
      },
      method: fetchConfig.method || "POST",
    });
    const dataResponse = await res.blob();
    return { res: dataResponse, message: "success" };
  } catch (error) {
    return { res: null, message: "error" };
  }
};

const convertSearchParams = (param: { [key: string]: any }) => {
  if (!param || Object.keys(param).length === 0 || param.constructor !== Object)
    return "";
  const newParam = new URLSearchParams(Object.entries(param)).toString();
  return "?" + newParam;
};

export const FetchFormData2 = async <T>(
  fetchConfig: FetchConfig,
  formData: FormData
): Promise<{ res: T | null; message: string; code: number; result: any }> => {
  try {
    const path = !!fetchConfig.params
      ? `${fetchConfig.path}${convertSearchParams(fetchConfig.params)}`
      : fetchConfig.path;
    const url = `${appConfig.apiURL}${path}`;
    const res = await fetch(url, {
      // method: method,
      headers: {
        Authorization: `Bearer ${fetchConfig.token}`,
      },
      body: formData,
      method: fetchConfig.method,
      // body:
      //   fetchConfig.method === "POST" ? JSON.stringify(fetchConfig.data) : null,
    });
    const dataResponse = await res.json();
    // return { res: dataResponse, message: dataResponse.message };
    return dataResponse;
  } catch (error) {
    return { res: null, message: "error", code: 400, result: [] };
  }
};
