import React from 'react'
import { useParams } from 'react-router-dom';
import { Col, Button, Form, Row } from 'antd';

import SaveFilled from '@ant-design/icons/lib/icons/SaveFilled';
import { CurrentSection } from '@interface/pages/assessment';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { DataResults, Evaluation, StepIndex, SubIndicator } from '@interface/assessment';
import { _findObjectOfArrayById, _findObjectOfArrayByKeyName } from '@utils/utils'

interface Props {
  answer?: any
  editStatus?: boolean;
  rederAction?: boolean;
  stepValue: StepIndex[];
  currentStep: number;
  insertLoading?: boolean;
  assessmentInfo: DataResults;
  currentSection: CurrentSection | null;
  _getQuestionService: _GetQuestionService;
  setCurrentSection: React.Dispatch<React.SetStateAction<CurrentSection | null>>;
  setFormProcessStatus: React.Dispatch<React.SetStateAction<"onProcess" | "success" | "finish">>;

}
interface _GetQuestionService {
  getQuestionBySubIdApi: (evaluationID: string, subIndicatorID: string) => void;
  getQuestionBySubIdAndQuestionIndexApi: (evaluationID: string, subIndicatorID: string, indexQuestion: number) => void;
}

const FormItemAction = ({ answer, insertLoading, stepValue, currentStep, currentSection, assessmentInfo, setCurrentSection, setFormProcessStatus, _getQuestionService, rederAction }: Props) => {

  const { assessmentid } = useParams<{ assessmentid: string }>()

  const handleNext = () => {
    if (!!stepValue && !!currentSection && ((stepValue.length) > currentStep) && !!currentSection) {
      const newCurrentSection = { parentId: currentSection.parentId, subIndicatorID: currentSection.subIndicatorID, stepIndex: currentStep + 1 }
      setCurrentSection(newCurrentSection)
      _getQuestionService.getQuestionBySubIdAndQuestionIndexApi(assessmentid, newCurrentSection.subIndicatorID, newCurrentSection.stepIndex)
    } else if (!!stepValue && !!currentSection && (stepValue.length - 1 === currentStep)) {
      handleNextSection()
    } else {
      console.log("handleNext");
    }
  };
  const handlePrev = () => {
    if (!!stepValue && (currentStep > 0) && (stepValue.length > currentStep) && !!currentSection) {
      const newCurrentSection = { parentId: currentSection.parentId, subIndicatorID: currentSection.subIndicatorID, stepIndex: currentStep - 1 }
      setCurrentSection(newCurrentSection)
      _getQuestionService.getQuestionBySubIdAndQuestionIndexApi(assessmentid, newCurrentSection.subIndicatorID, newCurrentSection.stepIndex)
    }
  };
  const handleNextSection = () => {
    if (!!currentSection && !!assessmentInfo) {
      const evaluation = assessmentInfo.evaluation
      const currentParent = _findObjectOfArrayById(evaluation, currentSection.parentId)
      if (!currentParent) return false
      const subIndicator = evaluation[currentParent.index]["subIndicator"]
      const currentSubIndicator = _findObjectOfArrayById(subIndicator, currentSection.subIndicatorID)
      if (!!currentSubIndicator) {
        const nextSubIndicator = _findObjectOfArrayByKeyName(evaluation[currentParent.index]["subIndicator"], currentSubIndicator.index + 1, "index")
        if (!!nextSubIndicator) {
          if (subIndicator.length > nextSubIndicator.index) {
            const newCurrentSection = { parentId: currentSection.parentId, subIndicatorID: nextSubIndicator["id"], stepIndex: 0 }
            setCurrentSection(newCurrentSection)
            _getQuestionService.getQuestionBySubIdApi(assessmentid, newCurrentSection.subIndicatorID)
          }
        } else {
          if (currentParent.index < evaluation.length) {
            const nextEvaluation = _findObjectOfArrayByKeyName(evaluation, currentParent.index + 1, "index")
            if (!!nextEvaluation) {
              if (!evaluation[nextEvaluation.index]["subIndicator"].length) return false
              const firstSubIndicator = evaluation[nextEvaluation.index]["subIndicator"][0]
              if (!firstSubIndicator) return false
              const newCurrentSection = { parentId: nextEvaluation["id"], subIndicatorID: firstSubIndicator["id"], stepIndex: 0 }
              setCurrentSection(newCurrentSection)
              _getQuestionService.getQuestionBySubIdApi(assessmentid, newCurrentSection.subIndicatorID)
            } else {
              handleCheckSectionOnProcess()
              console.log("END ")
            }
          }
        }
      }
    }
  }

  const handleCheckSubIndicatorOnProcess = (IndicatorValus: Evaluation) => {
    const draftSubIndicatorStatus = _findObjectOfArrayByKeyName(IndicatorValus.subIndicator, "draft", "status") as SubIndicator | null
    const documentSubIndicatorStatus = _findObjectOfArrayByKeyName(IndicatorValus.subIndicator, "document", "status") as SubIndicator | null
    if (!!documentSubIndicatorStatus) {
      // console.log("documentSubIndicatorStatus", documentSubIndicatorStatus);
      _getQuestionService.getQuestionBySubIdApi(assessmentid, documentSubIndicatorStatus.id)
      return true
    } else if (!!draftSubIndicatorStatus) {
      // console.log("draftSubIndicatorStatus", draftSubIndicatorStatus);
      _getQuestionService.getQuestionBySubIdApi(assessmentid, draftSubIndicatorStatus.id)
      return true
    }
  }

  const handleCheckSectionOnProcess = () => {
    console.log("finish");
    if (assessmentInfo.processingStatus !== 100) {
      const evaluation = assessmentInfo.evaluation
      const draftIndicatorStatus = _findObjectOfArrayByKeyName(evaluation, "draft", "status") as Evaluation | null
      const documentIndicatorStatus = _findObjectOfArrayByKeyName(evaluation, "document", "status") as Evaluation | null
      if (!!documentIndicatorStatus) {
        // console.log("documentIndicatorStatus>>", documentIndicatorStatus);
        handleCheckSubIndicatorOnProcess(documentIndicatorStatus)
        return true
      } else if (!!draftIndicatorStatus) {
        // console.log("draftIndicatorStatus>>", draftIndicatorStatus);
        handleCheckSubIndicatorOnProcess(draftIndicatorStatus)
        return true
      }
    } else {
      setFormProcessStatus("success")
      console.log("finish");
    }
  }

  return (
    <Row className="content-functionStep">
      <Col>
        {currentStep > 0 &&
          <Form.Item>
            <Button
              className="content-ButtonPreviousstep Button-content-center"
              onClick={handlePrev}
            >
              <CaretLeftOutlined
                className="content-iconstep"
                style={{ marginTop: "5px" }} />
              ก่อนหน้า
            </Button>
          </Form.Item>
        }
      </Col>
      <Col className="content-ButtonSaveandNext">
        {!answer && (
          <Form.Item>
            <Button
              className="content-ButtonSavestep Button-content-center"
              htmlType="submit"
              loading={insertLoading}
            >
              <SaveFilled className="content-iconstep"
                style={{ color: '#1fa361', }} />
              บันทึก
            </Button>
          </Form.Item>
        )}
        <Form.Item>
          {(stepValue[currentStep]?.status === 'success' || !!answer)
            && (currentStep !== stepValue.length - 1)
            && (<Button
              className="content-Buttonnextstep Button-content-center"
              onClick={handleNext}
              value='attachment'
              disabled={insertLoading}
            >
              ถัดไป
              <CaretRightOutlined
                className="content-iconstep"
                style={{ color: '#fff', marginTop: "5px" }} />
            </Button>
            )
          }
          {
            (stepValue[currentStep].status === 'success')
            && (currentStep === stepValue.length - 1)
            && (assessmentInfo.evaluation.slice(-1).pop()?.subIndicator.slice(-1).pop()?.id !== currentSection?.subIndicatorID)
            && (
              <Button
                className="content-Buttonnextstep Button-content-center"
                onClick={handleNextSection}
                value='attachment'
                disabled={insertLoading}
              >
                หัวข้อถัดไป
                <CaretRightOutlined
                  className="content-iconstep"
                  style={{ color: '#fff', marginTop: "5px" }} />
              </Button>
            )
          }
        </Form.Item>
      </Col>
    </Row>
  )
}

export default FormItemAction

// const _findObjectOfArrayById = (array: { id: string | number;[key: string]: any; }[], id: number | string): { [key: string]: any; } | null => {
//   const obj = array.find((x) => x.id === id);
//   return !!obj ? obj : null
// }
// const _findObjectOfArrayByKeyName = (array: { id: string | number;[key: string]: any; }[], value: number | string, keyName: string): { [key: string]: any; } | null => {
//   const obj = array.find((x) => x[keyName] === value);
//   return !!obj ? obj : null
// }
// const _findIndexOfArrayById = (array: { id: string | number;[key: string]: any; }[], id: number | string): number => {
//   const index = array.map((x) => { return x.id; }).indexOf(id)
//   return index > 0 ? index : 0
// }