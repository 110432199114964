import React from 'react';
import { Col, Row, Skeleton } from 'antd';

function LoadingDashboard() {
    return (
        <div>
            <Row gutter={[5, 8]}>
                <Col xs={24} sm={24} md={24} lg={6}>
                    <Col>
                        <div className="DashMember-col-background">
                            <div className="DashMember-text-center">
                                <Skeleton.Avatar size={150} active />
                                <div className="DashMember-user-title">
                                    <Skeleton.Button size='small' style={{ height: 17, width: 100 }} active />
                                </div>
                            </div>
                            <div style={{ fontSize: '17px' }}>
                                <Row className="DashMember-user-detail" gutter={[16, 0]}>
                                    <Col sm={10} className="DashMember-user-detail-title">
                                        เบอร์โทรติดต่อ :
                                    </Col>
                                    <Col sm={14}>
                                        <Skeleton.Button size='small' style={{ height: 17, width: 100 }} active />
                                    </Col>
                                </Row>
                                <Row className="DashMember-user-detail" gutter={[16, 0]}>
                                    <Col sm={10} className="DashMember-user-detail-title">
                                        อีเมล์ :
                                    </Col>
                                    <Col sm={14}>
                                        <Skeleton.Button size='small' style={{ height: 17, width: 100 }} active />
                                    </Col>
                                </Row>
                                <Row className="DashMember-user-detail" gutter={[16, 0]}>
                                    <Col sm={10} className="DashMember-user-detail-title">
                                        สถานที่ติดต่อ :
                                    </Col>
                                    <Col sm={14}>
                                        <Skeleton.Button size='small' style={{ height: 17, width: 100 }} active />
                                    </Col>
                                </Row>
                                <Row className="DashMember-user-detail" gutter={[16, 0]}>
                                    <Col sm={10} className="DashMember-user-detail-title">
                                        ชื่อผู้ประสานงาน :
                                    </Col>
                                    <Col sm={14}>
                                        <Skeleton.Button size='small' style={{ height: 17, width: 100 }} active />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="DashMember-col-background-processnow">
                            <div className="DashMember-col-processnow DashMember-text-center">
                                <Skeleton.Button size='small' style={{ height: 17, width: 100 }} active />
                            </div>
                            <div className="DashMember-col-processnow-date">
                                <Row className="DashMember-user-detail" gutter={[16, 0]}>
                                    <Col sm={10} className="DashMember-user-detail-title">
                                        วันสิ้นสุดการประเมิน :
                                    </Col>
                                    <Col sm={14}>
                                        <Skeleton.Button size='small' style={{ height: 17, width: 100 }} active />
                                    </Col>
                                </Row>
                                <Row className="DashMember-user-detail" gutter={[16, 0]}>
                                    <Col sm={10} className="DashMember-user-detail-title">
                                        จำนวนวันประเมิน :
                                    </Col>
                                    <Col sm={14}>
                                        <Skeleton.Button size='small' style={{ height: 17, width: 100 }} active />
                                    </Col>
                                </Row>
                                <Row className="DashMember-user-detail" gutter={[16, 0]}>
                                    <Col sm={10} className="DashMember-user-detail-title">
                                        เหลืออีก :
                                    </Col>
                                    <Col sm={14} style={{ color: 'red' }}>
                                        <Skeleton.Button size='small' style={{ height: 17, width: 100 }} active />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Col>
                <Col xs={24} sm={24} md={24} lg={18}>
                    <div className="DashMember-col-background ">
                        <div style={{ width: '100%', height: '550px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <Skeleton active />
                            <Skeleton active />
                        </div>
                    </div>

                </Col>
            </Row>
        </div>
    )
}

export default LoadingDashboard;







