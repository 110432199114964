import React from 'react';
import { Answer, Option } from '@interface/assessment';
import { FormItemType } from './interface'

import Choice from './_CustomItem/Choice'
import TextArea from './_CustomItem/TextArea'
import InputLink from './_CustomItem/InputLink'
import InputNumber from './_CustomItem/InputNumber'
import UploadFiles from './_CustomItem/UploadFiles'
import InputChannel from './_CustomItem/Input';
import InputDecimalNumber from './_CustomItem/InputDecimalNumber'

interface Props {
  values: Answer[] | Option[];
  disabled?: boolean;
  variableAttachment: boolean;
  variableDescription?: boolean;
  variablePoint?: boolean;
}


const RenderFormItem = ({ values, variableAttachment, variableDescription, disabled = false, variablePoint }: Props) => {
  return (
    <React.Fragment>
      {values.map((item, ind) => (<CreateFormItem values={item} variableAttachment={variableAttachment} key={ind} variableDescription={variableDescription} disabled={disabled} variablePoint={variablePoint} />))}
    </React.Fragment>
  )
}

const CreateFormItem = ({ values, variableAttachment, variableDescription, disabled, variablePoint }: { values: Answer | Option; variableAttachment: boolean; disabled: boolean; variableDescription?: boolean, variablePoint?: boolean }) => {
  return (
    (values.type !== FormItemType.ATTACHMENT)
      ? <CreateFormItemAnswer variableDescription={variableDescription} values={values} disabled={disabled} variablePoint={variablePoint} />
      : variableAttachment ? <CreateFormItemAttachment values={values} variableDescription={variableDescription} disabled={disabled} variablePoint={variablePoint} /> : <></>
  )
}

const Item = (value: Answer | Option, disabled: boolean, variableDescription?: boolean, variablePoint?: boolean) => {
  let type = <></>
  const optionsItem = {
    [FormItemType.INPUT_CHANNEL]: <InputChannel values={value} disabled={disabled} variablePoint={variablePoint} />,
    [FormItemType.CHOICE]: <Choice values={value} disabled={disabled} variablePoint={variablePoint} />,
    [FormItemType.INPUT_LINK]: <InputLink values={value} disabled={disabled} variablePoint={variablePoint} />,
    [FormItemType.UPLOAD_FILES]: <UploadFiles values={value} disabled={disabled} variablePoint={variablePoint} />,
    [FormItemType.INPUT_NUMBER]: <InputNumber variableDescription={variableDescription} values={value} disabled={disabled} variablePoint={variablePoint} />,
    [FormItemType.INPUT_DECIMAL_NUMBER]: <InputDecimalNumber values={value} disabled={disabled} variablePoint={variablePoint} />,
    [FormItemType.TEXT_AREA]: <TextArea values={value} disabled={disabled} variablePoint={variablePoint} />
  }
  switch (value.type) {
    case FormItemType.CHOICE:
      type = optionsItem[FormItemType.CHOICE];
      break;
    case FormItemType.INPUT_NUMBER:
      type = optionsItem[FormItemType.INPUT_NUMBER];
      break;
    case FormItemType.UPLOAD_FILES:
      type = optionsItem[FormItemType.UPLOAD_FILES];
      break;
    case FormItemType.INPUT_LINK:
      type = optionsItem[FormItemType.INPUT_LINK];
      break;
    case FormItemType.INPUT_DECIMAL_NUMBER:
      type = optionsItem[FormItemType.INPUT_DECIMAL_NUMBER];
      break;
    case FormItemType.INPUT_CHANNEL:
      type = optionsItem[FormItemType.INPUT_CHANNEL];
      break;
    case FormItemType.TEXT_AREA:
      type = optionsItem[FormItemType.TEXT_AREA];
      break;
    default:
      break;
  }
  return <div style={{ marginBottom: 8 }}>{type}</div>
}

const CreateFormItemAnswer = ({ values, disabled, variableDescription, variablePoint }: { values: Answer | Option, disabled: boolean, variableDescription?: boolean, variablePoint?: boolean }) => { return Item(values, disabled, variableDescription, variablePoint) }

const CreateFormItemAttachment = ({ values, disabled, variableDescription, variablePoint }: { values: Answer | Option, disabled: boolean, variableDescription?: boolean, variablePoint?: boolean }) => {
  return (
    <React.Fragment>
      {values.options.map((item, index) => <CreateFormItemAnswer values={item} key={index} variableDescription={variableDescription} disabled={disabled} variablePoint={variablePoint} />)}
    </React.Fragment>
  )
}


export default RenderFormItem

