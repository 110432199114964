// import { lazy } from "react";
import { RoutesInterface } from "./interface/router";
import { TypesLayout } from "../interface/theme";

import Dashboard from "../page/member/Dashboard";
import AssessmentList from "../page/member/assessment/";
import Document from "../page/Document";

import dashboard from "../assets/images/icon/side-dash.svg";
import assessment from "../assets/images/icon/side-asses.svg";
import document from '../assets/images/icon/side-doc.svg'
import assesmentDetial from "@page/member/assessment/assesmentDetial/assesmentDetial";
import AssessmentForm from "@page/member/assessment/Form";

// const Dashboard= lazy(() => import("../page/member/Dashboard"))
// const AssessmentForm = lazy(() => import("../page/member/assessment/List"))
// const AssessmentList= lazy(() => import("../page/member/assessment/Form"))

export default [
  {
    path: "/",
    exact: true,
    layout: TypesLayout.main,
    sidebar: false,
    redirectTo: "/member/dashboard",
  },
  {
    name: "Dashboard",
    path: "/member/dashboard",
    icon: dashboard,
    exact: true,
    layout: TypesLayout.main,
    sidebar: true,
    component: Dashboard,
  },
  {
    name: "Assessment",
    path: "/member/assessment",
    icon: assessment,
    exact: true,
    layout: TypesLayout.main,
    sidebar: true,
    component: AssessmentList,
  },
  {
    name: "Form Assessment",
    path: "/member/assessment/create",
    exact: true,
    layout: TypesLayout.main,
    sidebar: false,
    component: AssessmentForm,
  },
  {
    name: "Form Assessment Edit",
    path: "/member/assessment/edit/:id",
    exact: true,
    layout: TypesLayout.main,
    sidebar: false,
    component: AssessmentForm,
  },
  {
    name: "Form Assessment",
    path: "/assessment/:assessmentid",
    exact: true,
    layout: TypesLayout.main,
    sidebar: false,
    component: assesmentDetial,
  },
  {
    name: "Document",
    path: "/document",
    icon: document,
    exact: true,
    layout: TypesLayout.main,
    sidebar: true,
    component: Document,
  },


] as RoutesInterface[];
