import React from 'react'
import { Col, Layout, Row } from 'antd'
import Header from "./Header";
import { motion } from 'framer-motion';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  headerContent: React.ReactNode | React.ReactNode[];
}

const { Content } = Layout;

const ContentPage = ({ children, headerContent }: Props) => {
  return (
    <motion.div initial={{ opacity: 0.5 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Header>
        {headerContent}
      </Header>
      <Content className="content-stylee">
        {/* <Content> */}
        <Row justify="center" style={{ height: '100%' }}>
          <Col span={23}>
            {children || ""}
          </Col>
        </Row>
      </Content>
    </motion.div>
  )
}

export default ContentPage
