import { Props } from './MainLayout.interface'
import { Layout } from 'antd'
import Sidebar from './Sidebar'
import { RenderLayoutRoute } from '@routes/RenderRoute'

// const { Content } = Layout;
const MainLayout = ({ router, sidebar }: Props) => {
  return (
    <Layout className="h-100vh max-h-100vh overflow-hidden">
      <Sidebar value={sidebar} />
      <Layout className="background" >
        {/* <Header /> */}
        {/* <Content className="content-style"> */}
        <RenderLayoutRoute router={router} />
        {/* </Content> */}
      </Layout>
    </Layout>
  )
}

export default MainLayout
