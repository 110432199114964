import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { CalendarFilled } from "@ant-design/icons";
import { Col, Form, Input, Row, DatePicker, message } from "antd";
import UploadImg from "@components/UploadImg/UploadImg";
import {
  CreateAssessmentApi,
  GetEvaluationByID,
  PatchEvaluationById,
} from "@services/api/assessment";
import { useAuthContextState } from "@authContext/store";
import {
  AssessmentCreate,
  AssessmentTemplate,
  YearOfAchievement,
} from "@interface/pages/assessment";
import ButtonForm from "./ButtonForm";
import FormdetailMember from "./Formdetail";
import ButtonFormAdmin from "@page/admin/assessment/Form/ButtonFormAdmin";
import moment from "moment";
import appConfig from "@config/app.config";
import ButtonFormOfficerAndSector from "@page/admin/assessment/Form/ButtonFormOfficerAndSector";
import { ROLE_ENUM } from "@interface/role";
const { RangePicker } = DatePicker;

interface Props {
  assessmentTemplateInfo: AssessmentTemplate[] | [];
}

const FormAssessor = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useAuthContextState();
  const [form] = Form.useForm();
  const match: any = useRouteMatch();
  const id = match?.params?.id;
  let history = useHistory();
  const [url, setURL] = useState();
  const location = useLocation();
  const { userInfo } = useAuthContextState();
 
  useEffect(() => {
    IndicatorApi();
  }, []);

  const IndicatorApi = async () => {
    try {
      const { res } = await GetEvaluationByID(token.accesstoken, id);

      if (res.code === 200) {
        setURL(res?.result?.image?.url);
        console.log("res", res?.result);
        form.resetFields();
        form.setFieldsValue({
          ...res?.result,
          date: [moment(res?.result.startDate), moment(res?.result.endDate)],
          budget: moment(res?.result.year),
        });
        
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async (values: AssessmentCreate | any) => {
    const data = {
      image: values.image,
      title: values.title,
      date: values.date,
      place: values.place,
    };
    
    const startDate = values.date[0];
    const endDate = values.date[1];
    const formData = new FormData();
    formData.append("image", values.image?.originFileObj);
    formData.append("title", values.title);
    formData.append("place", values.place);
    formData.append("endDate", endDate);
    formData.append("startDate", startDate);
    formData.append("budget", values.budget._d.getFullYear());

    
    if (!id) {
      setLoading(true);
      const res = await CreateAssessmentApi(token.accesstoken, formData);
      if (res.code === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "สร้างแบบประเมินสำเร็จ",
          showConfirmButton: false,
          timer: 1000,
        });
        history.push("/member/assessment"); 
       
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: res.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      console.log(formData.getAll("endDate"));
      setLoading(true);
      const res = await PatchEvaluationById(token.accesstoken, id, formData);
      if (res.code === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "แก้ไขแบบประเมินสำเร็จ",
          showConfirmButton: false,
          timer: 1000,
        });
        //Path เมื่อแก้ไขสำเร็จ
        if (
          userInfo.assessorType == "organizer" &&
          userInfo.role == ROLE_ENUM.ASSESSOR
        ) {
          history.push("/member/assessment");
        } else if (userInfo.role == ROLE_ENUM.ADMIN) {
          history.push("/admin/assessment");
        } else if (
          userInfo.role == ROLE_ENUM.SECTOR ||
          userInfo.role == ROLE_ENUM.OFFICER
        ) {
          history.push(`/officerAndSector/assessment`);
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: res.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }

    setLoading(false);
  };

  //ปุ่มการทำงาน
  const Button = () => {
    if (userInfo.assessorType == "organizer" && userInfo.role == ROLE_ENUM.ASSESSOR) {
      return <ButtonForm loading={loading} />;
    } else if (userInfo.role == ROLE_ENUM.ADMIN) {
      return <ButtonFormAdmin loading={loading} />;
    } else if (userInfo.role == ROLE_ENUM.SECTOR || userInfo.role == ROLE_ENUM.OFFICER) {
      return <ButtonFormOfficerAndSector loading={loading} />;
    }
  };


  return (
    <div className="assess-white-backgournd">
      <Form
        name="createForm"
        size="large"
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        className="assess-form-style"
      >
        

        <div style={{ display: "flex" }} className="text-title assess-title">
          {id ? "แก้ไขแบบประเมินผลสัมฤทธิ์" : "สร้างแบบประเมินผลสัมฤทธิ์"}
         
          {Button()}
        </div>

        <div className="assess-detail-box">
          <div className="assess-detail-title ">
            {!id ? "กรอกข้อมูลรายการที่จะสร้าง" : "กรอกข้อมูลรายการที่จะแก้ไข"}
          </div>
          <div className="assess-detail-img-position">
            <Form.Item
              name="image"
              style={{ minHeight: "8rem", minWidth: "8rem" }}
              valuePropName="file"
              rules={[{ required: false, message: "กรุณากรอกเพิ่มรูป" }]}
            >
              <UploadImg
                valueImage={url ? { url: appConfig.apiURL + "/" + url } : null}
              />
            </Form.Item>
          </div>
          <Row gutter={[16, 0]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="title"
                label="ชื่อรายการ"
                className="assess-form-text"
                rules={[{ required: true, message: "กรุณากรอกข้อมูล" }]}
              >
                <Input className="assess-form-fill" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="budget"
                label="ปีงบประมาณ"
                rules={[{ required: true, message: "กรุณากรอกข้อมูล" }]}
              >
                <DatePicker
                  className="assess-form-fill"
                  picker="year"
                  placeholder="เลือกปีงบประมาณ"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="place"
                label="สถานที่จัดแข่งขัน"
                rules={[{ required: true, message: "กรุณากรอกข้อมูล" }]}
              >
                <Input className="assess-form-fill" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="date"
                label="วันที่เริ่ม-สิ้นสุดการแข่งขัน"
                rules={[{ required: true, message: "กรุณากรอกข้อมูล" }]}
              >
                <RangePicker
                  format="DD-MM-YYYY"
                  className="assess-form-fill"
                  suffixIcon={<CalendarFilled />}
                />
              </Form.Item>
            </Col>
          </Row>
          <div>
            {id ? null : (
              <FormdetailMember values={props.assessmentTemplateInfo} />
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FormAssessor;
