import React from 'react'
import AssessmentList from './List'
import ContentPage from '@layout/MainLayout/Header/Content'
import { useAuthContextState } from '@authContext/store'
import { motion } from 'framer-motion'

interface Props {
}
const Assessment = (props: Props) => {
  const { userInfo } = useAuthContextState()
  return (
    <motion.div initial={{ opacity: 0.5 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} >
      <ContentPage
        headerContent={
          <div className="hn-title-center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="hn-title-text" >
              <div className="text-title-white">การประเมินผลสัมฤทธิ์</div>
              <div className="header-font-subtitle">{userInfo.title}</div>
            </div>
          </div>
        }
      >
        <AssessmentList />
      </ContentPage>
    </motion.div>
  )
}

export default Assessment