import { Icons } from '@components/icon/RenderIcon/iconStatus';
import { YearOfAchievement } from '@interface/pages/assessment';

import ListItem from './ListItem'

interface Props {
  values: YearOfAchievement[];
}

const Lists = ({ values }: Props) => {

  const getStyles = (ind: number,) => {
    const styles = {
      top: { borderRadius: '10px 10px 0px 0px', border: '1px solid #f0f0f0', borderBottom: '1px solid #f0f0f0' },
      center: { borderRadius: '0px', border: '1px solid #f0f0f0', borderBottom: '1px solid #f0f0f0', borderTop: '0px solid #f0f0f0' },
      one: { borderRadius: '10px 10px 10px 10px', border: '1px solid #f0f0f0' },
      botton: { borderRadius: '0px 0px 10px 10px', border: '1px solid #f0f0f0', borderBottom: '1px solid #f0f0f0', borderTop: '0px solid #f0f0f0' },
    }
    return values.length === 1 ? styles['one'] : ind === 0 ? styles['top'] : ind === values.length - 1 ? styles['botton'] : styles['center']
  }

  return (
    <div className="assessmentListMember-boxcrad " >
      {values.map((item, index) => (
        <div key={index} >
          <div
            className="assessmentList-crad"
            style={{ ...getStyles(index), padding: 10 }}
          >
            <ListItem
              values={item}
              icon={Icons.find((icon) => icon.status === item.status)?.icon}
            />
          </div>
        </div>
      ))}

    </div>
  )
}

export default Lists
