import { CheckCircleFilled, SendOutlined } from '@ant-design/icons';
export const Status = [
  {
    status: "success",
    icon: <span className="Circles-indicator" ><CheckCircleFilled className="Circles-done" /></span>
  },
  {
    status: "document",
    icon: <span className="Circles-indicator Circles-doing" />
  },
  {
    status: "None",
    icon: <span className="Circles-indicator Circles-wait" />
  },
  {
    status: "draft",
    icon: <span className="Circles-indicator Circles-wait" />
  },
]
export const SubmitStatus = [
  {
    status: "success",
    icon: <div className="submit-done indicator-textsubmit"> <SendOutlined /> </div>
  },
  {
    status: "Inprocess",
    icon: <div className="indicator-sand indicator-textsand"> <SendOutlined /> </div>
  },
  {
    status: "None",
    icon: <div className="indicator-sand indicator-textsand"> <SendOutlined /> </div>
  },
]