import { useAuthContextState } from "@authContext/store";
import { Answer, Option } from "@interface/assessment";
import { ROLE_ENUM } from "@interface/role";
import { Col, Row, InputNumber as Input, Form } from "antd"

export interface Props {
  values: Answer | Option;
  disabled: boolean;
  variableDescription?: boolean;
  variablePoint?: boolean;
}

const InputNumber = ({ values, disabled, variableDescription, variablePoint }: Props) => {
  const { userInfo } = useAuthContextState()

  return (
    <div >
      <div className="text-Answer">
        {values["title"] || "ใส่ข้อมูลตัวเลข"}
      </div>
      <Row gutter={[6, 6]} align="middle">
        <Col>
          <Form.Item name={values["keyName"]} rules={[{ required: (userInfo.role===ROLE_ENUM.SECTOR?false:((values["keyName"] === "inputNumber" || values["keyName"] === "inputNumber1") && !variablePoint ? true : false)), message: 'กรุณากรอกข้อมูล' }]}>
            <Input<number> min={0} max={values["keyName"] === 'point' ? values["maxPoint"] : undefined} disabled={disabled} />
          </Form.Item>
        </Col>
        <Col>{values["unit"] || "ใส่ข้อมูลตัวเลข"}</Col>
      </Row>
      <span dangerouslySetInnerHTML={{ __html: (!variableDescription && !!values["description1"] ? values["description1"] : values["description"]) || '' }} />
    </div>
  )
}

export default InputNumber
