
import React from 'react';
import { Button, Result } from 'antd';
import { useHistory } from "react-router-dom";

const NoFoundPage: React.FC = () => {
  const history = useHistory()
  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={<Button type="primary" onClick={() => history.push('/')}>
        Back Home
      </Button>}
    />
  )
};

export default NoFoundPage;