import { Button } from "antd";
import { Link } from "react-router-dom";
interface Props {
  loading: boolean;
}
export default function ButtonFormAdmin({ loading }: Props) {
  return (
    <div>
      <Link to="/admin/assessment">
        <Button disabled={loading} className="assess-button-cencel">
          ยกเลิก
        </Button>
      </Link>
      
      <Button
        className="assess-button-build"
        htmlType="submit"
        loading={loading}
      >
        สร้าง
      </Button>
    </div>
  );
}
