import { Avatar } from 'antd';
import { CheckCircleFilled, FileTextFilled } from '@ant-design/icons';
import person from '@assets/images/icon/club-icon.svg'
import teamgroup from '@assets/images/icon/manager-icon.svg'


import Lock from '@assets/images/icon/Lock.svg'
// import waitfile from '@assets/images/doc-icon.svg';

export const Icons = [
    {
        status: "draft",
        icon: <img alt="iconLock" src={Lock} className="assessmentListMember-iconStatus" width={25} height={20} />
    },
    {
        status: "document",
        icon: <Avatar icon={<FileTextFilled />} className="assessmentListMember-iconStatus iconStatus-inProcess" />
    },
    {
        status: "grading",
        icon: <Avatar className="blue" src={person} />
    },
    {
        status: "sector_doing",
        icon: <Avatar className="green" src={teamgroup} />
    },
    {
        status: "success",
        icon: <CheckCircleFilled className="assessmentListMember-iconStatus iconStatus-done" />
    },
]