import { Form, Input } from 'antd';
import { Answer, Option } from "@interface/assessment";
export interface Props {
  values: Answer | Option;
  disabled: boolean;
  variablePoint?: boolean;
}
const inputChannel = ({ values, disabled, variablePoint }: Props) => {
  const html = values["description"] || ``
  return (
    <div >
      <div className="text-Answer">
        {values["title"] || "ใส่ข้อมูลตัวเลข"}
      </div>
      <Form.Item name={values["keyName"]} rules={[{ required: !variablePoint ? false : false }]}>
        <Input size="large" style={{ borderRadius: '4px', marginBottom: '10px' }} disabled={disabled} />
      </Form.Item>
      <span dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

export default inputChannel
