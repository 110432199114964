
import React from 'react'
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UserOutlined } from '@ant-design/icons'

interface Props {
  value?: any;
  valueImage?: any;
  onChange?: (file: any) => void;
}

const UploadImg = ({ value, onChange, valueImage }: Props) => {

  const [fileLists, setFileLists] = React.useState<any>([])

  React.useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, valueImage]);
  const initial = () => {
    if (!!valueImage && !!valueImage?.url) {
      setFileLists([
        {
          uid: valueImage.uuid,
          name: valueImage.fileName,
          status: 'done',
          url: valueImage.url
        }
      ])
    } else {
      if (typeof value === "object") return;
      setFileLists([]);
    }
  }

  const handleonChange = (newFileList: any) => {
    const { file } = newFileList;
    if (!!onChange) {
      onChange(file);
    }
    setFileLists(newFileList.fileList);
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);

    if (imgWindow) {
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  return (
    <div>
      <ImgCrop grid shape="round" >
        <Upload
          name='image'
          fileList={fileLists}
          onPreview={onPreview}
          listType="picture-card"
          onChange={handleonChange}
          className="assess-img-upload-style"
          customRequest={({ _, onSuccess }: any) => onSuccess('ok')}
        >
          {(fileLists === undefined || fileLists.length < 1) && <UserOutlined style={{ fontSize: '35px', color: 'gray' }} />}
        </Upload>
      </ImgCrop>
    </div>
  )
}
export default UploadImg
