import { Button, Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
export interface Props {
  value?: any;
  onChange?: any
  disabled: boolean;
}
const InputList = (props: Props) => {
  const [linkList, setList] = useState<{ title: string, link: string }[] | null>(null)
  const [linkItem, setLinkItem] = useState<{ link: string, title: string }>({ link: "", title: "" })
  const [linkIsError, setLinkIsError] = useState<boolean>(false)
  const [inputIsError, setInputIsError] = useState<{ link: boolean; title: boolean }>({ link: false, title: false })

  useEffect(() => {
    setList(props.value)
    return () => {
      setList(null)
      setLinkItem({ link: "", title: "" })
      setLinkIsError(false)
    }
  }, [props.value])

  const handleClick = () => {
    if (!!linkIsError) return false
    if (!linkList || !linkItem.link.length || !linkItem.title.length) {
      if (!linkItem.link.length) setInputIsError({ ...inputIsError, link: true })
      if (!linkItem.title.length) setInputIsError({ ...inputIsError, title: true })
      return false
    } else {
      const newList = !!linkList ? [...linkList, linkItem] : [linkItem]
      setList(newList)
      setLinkItem({ link: "", title: "" })
      props.onChange && props.onChange(newList)
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, key: "link" | "title") => {
    const value = e.target.value
    if (!e.target.value.length) setInputIsError({ ...inputIsError, [key]: true })
    if (!!e.target.value.length) setInputIsError({ ...inputIsError, [key]: false })
    if (key === "link") {
      const isError = validURL(value)
      setLinkIsError(!isError)
    }
    const newValues: { link: string, title: string } = { ...linkItem, [key]: value }
    setLinkItem(newValues)
  }

  const validURL = (str: string) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }
  const handleDelete = (ind: number) => {
    const list = !!linkList ? linkList.filter((i, index) => index !== ind) : null
    setList(list)
    props.onChange && props.onChange(list)
  }
  return (
    <Row style={{ width: "100%" }} gutter={[6, 6]}>
      {!props.disabled && (
        <React.Fragment>
          <Col className="" style={{ width: 200 }}>
            <Input value={linkItem.title} disabled={props.disabled} placeholder="ใส่ชื่อ" size="large" name="title" onChange={(e) => onChange(e, "title")} />
            {!!inputIsError.title && <span style={{ color: '#eb2227', paddingLeft: 5 }}>กรุณากรอกข้อมูล</span>}
          </Col>
          <Col className="" flex={1} >
            <Input.Group compact size="large" style={{ display: 'flex' }}>
              <Input value={linkItem.link} placeholder="วางลิ้งก์" name="title" onChange={(e) => onChange(e, "link")} disabled={props.disabled} />
              <Button size="large" className="button-danger" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={handleClick} disabled={props.disabled}>เพิ่ม</Button>
            </Input.Group>
            {!!linkIsError && <span style={{ color: '#eb2227', paddingLeft: 5 }}>รูปแบบลิงก์ไม่ถูกต้อง</span>}
            {(!!inputIsError.link && !linkIsError) && <span style={{ color: '#eb2227', paddingLeft: 5 }}>กรุณากรอกข้อมูล</span>}
          </Col>
        </React.Fragment>
      )}
      <Col span={24} >
        {!!linkList && linkList.map((item, index) => (
          <Row key={index} style={{ border: '1px solid #d9d9d9', padding: '5.7px 11px', fontSize: 17, marginTop: 6 }} align="middle">
            <Col flex={1}>
              <a href={item.link} target="_blank" style={{ width: '100%' }} rel="noreferrer noopener">
                <div>{item.title}</div>
                <span style={{ color: '#d9d9d9' }}>{item.link}</span>
              </a></Col>
            {!props.disabled && (<Col ><Button type="link" className="icon-button-default" onClick={() => handleDelete(index)}><DeleteOutlined style={{ fontSize: '17px', color: '#d9d9d9' }} /></Button></Col>)}
          </Row>
        ))}
        {(!!linkList && linkList?.length < 1 && props.disabled) && <span>--- ไม่มีข้อมูล ---</span>}
      </Col>
    </Row >
  )
};

export default InputList;
