import React, { useState } from 'react'
import { Button } from "antd";
import Swal from 'sweetalert2';
import { useAuthContextState } from '@authContext/store';
import { GetExcelHistoryFileAndGen } from '@services/api/assessment';
import xls from '@assets/images/xls.png'

interface Props {
    assessmentid: number
}

export default function DownloadExcel({ assessmentid }: Props) {

    const { token } = useAuthContextState()
    const [loading, setLoading] = useState(false)

    const getExcelhistiryFile = async () => {
        setLoading(true)
        try {
            const res = await GetExcelHistoryFileAndGen(token.accesstoken, assessmentid)
            if (!res) throw new Error("/500");

            if (res.message === 'success') {
                const url = window.URL.createObjectURL(new Blob([res.res]))
                const link = document.createElement('a');
                link.href = url
                link.setAttribute("download", "ประวัติการทำแบบประเมิน.xlsx")
                document.body.appendChild(link);
                link.click()
                setLoading(false)

            }
            setLoading(false)
        } catch (error: any) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: error.message,
                showConfirmButton: false,
                timer: 1500
            })
            setLoading(false)
        }
    }

    return (
        <Button
            loading={loading}
            style={{
                backgroundColor: "white",
                height: "45px",
                width: "45px",
                borderRadius: "10px",
            }}
            onClick={() => {
                getExcelhistiryFile()
            }}
            icon={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img alt='xls' src={xls} width={25} height='auto' />
                </div>
            }
        ></Button>
    )
}
