import { Form, Radio } from 'antd';
import { Answer, Option } from '@interface/assessment'
import { useAuthContextState } from '@authContext/store';
import { ROLE_ENUM } from '@interface/role';
export interface Props {
  values: Answer | Option;
  disabled: boolean;
  variablePoint?: boolean

}

const Choice = ({ values, disabled }: Props) => {
  const { userInfo } = useAuthContextState()
  
  const html = values["description"] || ``
  return (
    <div >
      <div className="text-Answer">
        {values["title"] || "เลือกคำตอบ"}
      </div>
      <Form.Item name={values["keyName"]} rules={[{ required: ((userInfo.role===ROLE_ENUM.SECTOR && values["keyName"]==="choice1")?false:true) ,message:"กรุณากรอกข้อมูล"}]}>
        <Radio.Group disabled={disabled}>
          {values.options?.map((item, ind) => (     
            <Radio key={ind} value={item.value} className="content-boxchooseyes ">{values.typerole==="sector"?(item.title==="มี"?"เอกสารครบถ้วน":item.title==="ไม่มี"?"เอกสารไม่ครบถ้วน":item.title): item.title}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <span dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

export default Choice
