import { Form, Input } from 'antd';
import { Answer, Option } from "@interface/assessment";

const { TextArea } = Input

export interface Props {
  values: Answer | Option;
  disabled: boolean;
  variablePoint?: boolean;
}

const TextAreaComponent = ({ values, disabled }: Props) => {
  const html = values["description"] || ``

  return (
    <div >
      <div className="text-Answer">
        {values["title"] || "ใส่ข้อมูลตัวเลข"}
      </div>
      <Form.Item name={values["keyName"]} rules={[{ required: false }]}>
        <TextArea showCount disabled={disabled} rows={5} />
      </Form.Item>
      <span dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

export default TextAreaComponent
