import React from 'react';
import { CurrentSection } from '@interface/pages/assessment'
import { Questions } from '@interface/assessment';
import Steps from '../Steps';

type Props = {
  values: Questions;
  currentSection: CurrentSection;
};

const HeaderDetail = (props: Props) => {
  return (
    <React.Fragment>
      <div className="title-FormAssessment">
        <div>
          <h1>แบบประเมิน</h1>
          <h2>{props.values.title}</h2>
        </div>
      </div>

      {(!!props.values?.stapIndex && (props.values.stapIndex.length > 1) && (!!props.currentSection.stepIndex || props.currentSection.stepIndex === 0)) && <Steps stepInfo={props.values?.stapIndex} current={props.values.currentIndex}></Steps>}
      {/*    <div className="content-help">
        <Button
          type="link"
        >
          <img alt="iconload"
            style={{ marginRight: '5px', alignItems: 'center' }}
            width={18} src={help} />
          <span>ช่วยเหลือ</span>
        </Button>
      </div> */}

      <div className="content-boxcontent">
        <div className="content-textCriteria" style={{ borderBottom: '1px solid #f0f0f0' }} >
          <h2 className="textCriteria" >{props.values?.parent}</h2>
        </div>
        <div className="content-textCriteria">  <h3 className="textCriteria">{props.values?.subIndicator}</h3></div>
      </div>
      <div className="content-boxcontentgray">
        <div className="content-textSteps">
          {!!props.values && (
            <span>
              <div>{(!!props.currentSection.stepIndex || props.currentSection.stepIndex === 0) && props.values.question[props.currentSection.stepIndex]?.question}</div>
            </span>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderDetail;
