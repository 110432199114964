import React from 'react'
import ContentPage from '@layout/MainLayout/Header/Content';
import { useAuthContextState } from '@authContext/store'
import { GetEvaluationTemplateApi } from '@services/api/assessment'
import { AssessmentProps, AssessmentTemplate } from '@interface/pages/assessment'

import FormAssessor from './FormAssessor';
import FormAssessorOrganizer from './FormAssessorOrganizer'
import { useHistory } from 'react-router-dom';
import { ROLE_ENUM } from '@interface/role';

const AssessmentForm = (props: AssessmentProps) => {

  const year = new Date().getFullYear()
  const { userInfo } = useAuthContextState()
  const { token } = useAuthContextState()
  const history = useHistory()
  const [assessmentTemplateInfo, setAssessmentTemplateInfo] = React.useState<AssessmentTemplate[] | []>([])

  const getEvaluationTemplateInfo = async () => {
    try {
      const { res } = await GetEvaluationTemplateApi(token.accesstoken)
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        setAssessmentTemplateInfo(res.result.data)
      }
    } catch (error: any) {
      history.push(error.message)
    }
  }

  React.useEffect(() => {
    getEvaluationTemplateInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContentPage
      headerContent={
        <div className="hn-title-text" >
          <div className="text-title-white">สร้างแบบประเมินผลสัมฤทธิ์ ประจำปี {year + 543}</div>
          <div className="header-font-subtitle">{userInfo.title}</div>
        </div>
      }
    >
      {userInfo.role === ROLE_ENUM.ASSESSOR && userInfo.assessorType !== "organizer" && <FormAssessor assessmentTemplateInfo={assessmentTemplateInfo} />}
      {userInfo.role === ROLE_ENUM.ADMIN && <FormAssessorOrganizer assessmentTemplateInfo={assessmentTemplateInfo} />}
      {userInfo.role === ROLE_ENUM.SECTOR && <FormAssessorOrganizer assessmentTemplateInfo={assessmentTemplateInfo} />}
      {userInfo.role === ROLE_ENUM.OFFICER && <FormAssessorOrganizer assessmentTemplateInfo={assessmentTemplateInfo} />}
      {userInfo.assessorType === "organizer" && <FormAssessorOrganizer assessmentTemplateInfo={assessmentTemplateInfo} />}
    </ContentPage>
  )
}

export default AssessmentForm
