import { Link, useLocation } from "react-router-dom";
import { Layout } from 'antd'
import { Props } from './Sidebar.interface';

import Logo from '../../../assets/images/Logo.svg';
import Contact from '../../../assets/images/icon/Contact.svg';

const { Sider } = Layout;

const SidebarMenu = ({ value }: Props) => {
  // console.log('mmm', value)
  const { pathname } = useLocation();

  const isActiveLink = (path: string) => {
    return pathname.startsWith(path) ? "active-sider" : ""
  }

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      width={'90'}
      trigger={null}
      className="sider-layout sider-font"
    >
      <div className="sider-container">
        <div className="sider-layout-header">
          <div className="sider-header">
            <img
              alt="logo"
              width={50}
              src={Logo}
            />
          </div>
        </div>
        <div className="sider-content">
          <div>
            {value.map((item,index) => (
              <Link key={index} to={item.path} className={`sider-menu-item ${isActiveLink(item.path)}`}>
                <img
                  className="sider-icon"
                  src={item.icon}
                  alt="menu icon"
                  width={22}
                />
                <span className="sider-menu-item-text ">{item.name}</span>
              </Link>
            ))}
          </div>
          <div className="contact">
            <img
              width={25}
              src={Contact}
              alt="contact icon"
            />
            <div>02 186 7111</div>
            <span >ติดต่อกกท. แห่งประเทศไทย</span>
          </div>
        </div>
      </div>
    </Sider>
  )
};

export default SidebarMenu