import React from 'react';
import { Row, Col, Button, Table, Tag, Image, Switch } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import FormFilter from './FormFilter'
import { Link } from 'react-router-dom';
import { GetUserListApi } from '@services/api/user'
import { UserListItem, FormFilterOnSearch, GetUserListApiProp } from '@interface/pages/userManagement'
import { useHistory } from 'react-router';
import ContentPage from '@layout/MainLayout/Header/Content';
import { useAuthContextState } from '@authContext/store'
import { GetUserListApiRES } from '@interface/res'
import { ImageType } from '@interface/pages/userManagement'
import appConfig from '@config/app.config';
import { changStatusForCreate } from '@services/api/EditUser';

const UserList = () => {

  const { token, userInfo } = useAuthContextState()
  const [tabsLoading, setTabsLoading] = React.useState(true);
  const [roleValue, setRoleValue] = React.useState<string>('');
  const [useListData, setUseListData] = React.useState<UserListItem[]>([]);
  const [pagination, setPagination] = React.useState<{ limit: number; page: number; total: number; }>({
    limit: 10,
    page: 1,
    total: 1,
  });

  let history = useHistory();

  const getUserList = async (value: GetUserListApiProp) => {
    const req = {
      ...value,
      token: token.accesstoken
    }

    const { res } = await GetUserListApi<GetUserListApiRES>(req)
    try {
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401")
      if (res.code === 200) {
        setUseListData(res.result.items)
        setPagination({
          limit: res.result.limit,
          page: res.result.page,
          total: res.result.total,
        })
        setTabsLoading(false)
      }
    } catch (error: any) {
      setTabsLoading(false)
      history.push(error.message)
    }
  }

  React.useEffect(() => {
    getUserList({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearch = (value: FormFilterOnSearch) => {
    setRoleValue(value?.role || '')
    const params = value
    getUserList({ params })
    setUseListData([])
  }
  const handleReset = () => {
    getUserList({})
  }
  const handleChangStatus = async (e: any, uuid: any) => {
    try {
      const { res } = await changStatusForCreate(e, uuid, token.accesstoken)
      if (!res) throw new Error("/500");
      if (res.code === 500) throw new Error("/401");
    } catch (error: any) {
      history.push(error.message)
    }
  }
  const handleChangPagination = (page: number, pageSize?: number, total?: number) => {
    setTabsLoading(true)
    getUserList({
      params: { page: page, role: roleValue }
    })
  }

  const columns = [
    {
      title: 'ลำดับ',
      width: '5%',
      editable: true,
      render: (text: string, record: UserListItem, index: number) => (index + 1) + ((pagination.page * pagination.limit) - pagination.limit)

    },
    {
      title: 'รูปภาพ',
      dataIndex: 'image',
      width: '5%',
      render: (src: ImageType) => <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><Image width={50} src={src ? appConfig.apiURL + '/' + src.url : "/images/no-user-image.png"} preview={false} /></div>
    },
    {
      title: 'ชื่อ',
      dataIndex: 'title',
      width: '20%',
      editable: true,
    },
    {
      title: 'เบอร์ติดต่อ',
      dataIndex: 'phone',
      width: '10%',
      editable: true,
    },
    {
      title: 'อีเมล์',
      dataIndex: 'email',
      width: '10%',
      editable: true,

    },
    {
      title: 'บทบาท',
      dataIndex: 'roleTH',
      width: '10%',
      editable: true,
      render: (role: any) => (
        <Tag className="user-tag-role">{role}</Tag>
      )
    },
    {
      title: 'สถานะการสร้างแบบประเมิน',
      width: '10%',
      editable: true,
      render: (data: any) => (
        data.isCreateForm !== null && (<div style={{ display: 'flex', justifyContent: 'center' }}>
          <Switch onChange={(e) => { handleChangStatus(e, data.uuid) }} checkedChildren="อนุญาต" unCheckedChildren="ไม่อนุญาต" defaultChecked={data.isCreateForm} />
        </div>)
      )
    },
  ];
  return (
    <ContentPage
      headerContent={
        <div className="hn-title-center" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div className="hn-title-text" >
            <div className="text-title-white">จัดการผู้ใช้</div>
            <div className="header-font-subtitle">{userInfo.title}</div>
          </div>
        </div>
      }
    >
      <Row gutter={[12, 12]}>
        <Col sm={24} md={24} className="w-100">
          <div className="form-box ">
            <FormFilter onClick={handleSearch} onReset={handleReset} />
          </div>
        </Col>
        <Col sm={24} md={24} >
          <div className="form-box ">
            <div>
              <Col sm={24} md={24} className="user-title-col" >
                <h1 className="text-title title">รายการจัดการผู้ใช้</h1>
                <Link to="user/crate">
                  <Button className="user-button-add w-100" >
                    <PlusOutlined />
                    <span>เพิ่มผู้ใช้</span>
                  </Button>
                </Link>
              </Col>
              <div className="user-table " >
                <Table
                  rowKey={"uuid"}
                  loading={tabsLoading}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: event => {
                        console.log("record", record)
                        history.push(`/admin/user/${record.uuid}/edit`)
                      },
                    };
                  }}
                  className=" User-table user-data-table  "
                  bordered
                  dataSource={useListData}
                  columns={columns}
                  pagination={{
                    current: pagination.page,
                    pageSize: pagination.limit,
                    total: pagination.total,
                    onChange: handleChangPagination
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </ContentPage>
  )
}

export default UserList
