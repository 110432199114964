import { Col, Row, Avatar, Badge, Button, Popconfirm } from "antd";
import appConfig from "@config/app.config";
import { useHistory } from "react-router-dom";
import DownloadFile from "../../../../download/downloadFile";
import { YearOfAchievement } from "@interface/pages/assessment";
import AssessmentIcon from "@components/icon/RenderIcon/assessmentIcon";
import { motion } from "framer-motion";
import { ROLE_ENUM } from "@interface/role";
import { useAuthContextState } from "@authContext/store";
import FormAssessor from "@page/member/assessment/Form/FormAssessorOrganizer";
import { DeleteEvaluationById } from "@services/api/assessment";
import Swal from "sweetalert2";

interface Props {
  values: YearOfAchievement;
  icon: React.ReactNode;
  getAssessmentMemberInfo: () => void;
}

const ListItem = ({ values, icon, getAssessmentMemberInfo }: Props) => {
  const history = useHistory();
  const { userInfo } = useAuthContextState();
  const { token } = useAuthContextState();
  const handleDoubleClick = (path: string, status: string) => {
    status !== "draft" && history.push(path);
  };

  const deleteData = async () => {
    try {
      const res = await DeleteEvaluationById(token.accesstoken, values.id, {});

      console.log("res", res);
      console.log("res11", res.code >= 200 && res.code < 300);

      if (res.code >= 200 && res.code < 300) {
        getAssessmentMemberInfo();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "ลบแบบประเมินสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        getAssessmentMemberInfo();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.message,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const EditButton = () => {
    if (userInfo.role === ROLE_ENUM.ADMIN) {
      return (
        <Col style={{ wordBreak: "break-all" }}>
          <Button
            onClick={() => history.push(`/admin/assessment/edit/${values.id}`)}
          >
            แก้ไข
          </Button>
        </Col>
      );
    } else if (
      userInfo.role === ROLE_ENUM.SECTOR ||
      userInfo.role === ROLE_ENUM.OFFICER
    ) {
      return (
        <Col>
          <Button
            onClick={() =>
              history.push(`/officerAndSector/assessment/edit/${values.id}`)
            }
          >
            แก้ไข
          </Button>
        </Col>
      );
    }
  };
  const DelButton = () => {
    if (userInfo.role === ROLE_ENUM.ADMIN) {
      return (
        <Col style={{ wordBreak: "break-all" }}>
          <Popconfirm
            title="คุณแน่ใจแล้วใช่ไหมที่จะลบแบบประเมิน"
            onConfirm={() => {
              deleteData();
            }}
            okText="ใช่"
            cancelText="ไม่ใช่"
          >
            <Button>ลบ</Button>
          </Popconfirm>
        </Col>
      );
    }
  };

  console.log("values?.comment.sector", values);
  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <button
        onDoubleClick={() =>
          handleDoubleClick(`assessment/${values.id}`, values.status)
        }
        style={{
          backgroundColor: "transparent",
          border: "unset",
          width: "100%",
        }}
      >
        <Row className="text-black-assessmentListAdmin-form">
          <Col className="evaluation-infoinmation" xs={13} xl={11}>
            <Col xs={3} xl={4}>
              <div className="assessmentListMember-list-imgBorder">
                <Avatar
                  size={60}
                  src={` ${values.image?.url
                    ? appConfig.apiURL + "/" + values.image?.url
                    : "/images/logo-defalut.png"
                    } `}
                />
              </div>
            </Col>
            <Col xs={15} xl={18} className="titleAseessmentListAdmin">
              <Row>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="assessmentListMember-list-programName">
                    {values.title}
                  </div>
                  <div className="assessmentListAdmin-list-year">
                    &nbsp;( การประเมินผลสัมฤทธิ์ {values.year} )
                  </div>
                </div>
              </Row>
              <Row style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                <Col lg={24} xl={8} className="list-date-title">
                  <div className="list-date-detail">วันเริ่มโครงการ </div>
                  <div>{values.startDate}</div>
                </Col>
                <Col lg={24} xl={8} className="list-date-title">
                  <div className="list-date-detail">วันสิ้นสุดโครงการ</div>
                  <div>{values.endDate}</div>
                </Col>
                <Col lg={24} xl={8} className="list-date-title ">
                  <div className="list-date-detail">วันสิ้นสุดการประเมิน</div>
                  <div>{values.evaluationEndDate}</div>
                </Col>
              </Row>
            </Col>
          </Col>

          <Col xs={10} xl={13}>
            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%" }}
              gutter={[15, 15]}
            >
              <Col xs={4} xl={4}>
                <DownloadFile id={values.id} value={values.pdfButton} />
              </Col>
              <Col xs={2} xl={2}>
                {EditButton()}
              </Col>
              <Col xs={4} xl={4}>
                {DelButton()}
              </Col>
              <Col xs={10} xl={10}>
                <Badge
                  dot={
                    values?.comment.sector !== null &&
                    userInfo.role === ROLE_ENUM.OFFICER
                  }
                  size="default"
                  offset={[-2, 2]}
                >
                  <div className="assessmentListMember-boxsuccess">
                    <AssessmentIcon
                      values={{
                        status: values.status,
                        path: `assessment/${values.id}`,
                        title: values.status,
                        icon: icon,
                      }}
                    />
                  </div>
                </Badge>
              </Col>
            </Row>
          </Col>
        </Row>
      </button>
    </motion.div>
  );
};

export default ListItem;
