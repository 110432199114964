import React from 'react'
import { Row, Col, Form } from 'antd';
import User from '../../../../components/form/FormUser/index'
import Formfilter from '../../../../components/form/FormUser/formfilter'
import { useHistory, useParams } from 'react-router-dom'
import { GetUserByidApi, EditUserApi } from '@services/api/EditUser'
import Swal from 'sweetalert2'
import ContentPage from '@layout/MainLayout/Header/Content';
import { useAuthContextState } from '@authContext/store';
import appConfig from '@config/app.config';

interface Props {

}

const EditUser = (props: Props) => {
  const [oldImage, setOldImage] = React.useState<any>({});
  const [isLoading, setIsloading] = React.useState<boolean>(false);
  const { userInfo, token } = useAuthContextState()
  const [role, setRole] = React.useState('')
  const [roleTH, setRoleTH] = React.useState([{}])
  const history = useHistory()
  const formdata = new FormData()

  const ChangeLang = (assessorType: string, sport: string, role: string) => {

    roleTH.forEach((data: any) => {
      if (!!data.assessorType) {
        if (data.assessorTypeTH === assessorType) {
          formdata.append('assessorType', data.assessorType)
        }
        else {
          formdata.append('assessorType', assessorType)
        }
      } else if (data.assessorType === '') {
        formdata.append('assessorType', assessorType)
      }
      if (!!data.sport) {
        if (data.sportTH === sport) {
          formdata.append('sport', data.sport)
        }
        else {
          formdata.append('sport', sport)
        }
      } else if (data.sport === '') {
        formdata.append('sport', sport)
      }
      if (!!data.role) {
        if (data.roleTH === role) {
          formdata.append('role', data.role)
        }
        else {
          formdata.append('role', role)
        }
      } else if (data.role === '') {
        formdata.append('role', role)
      }
    })
  }
  let uuid = useParams<{ id: string }>();
  const handleFinish = async (values: any) => {
    if (values.file !== undefined && values.file.status === 'removed') {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'โปรดเลือกรูปภาพ',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      if (values.role === 'ผู้ประเมิน' || values.role === 'assessor') {
        ChangeLang(values.assessorType, values.sport, values.role)
        formdata.append('isCreateForm', values.isCreateForm)
      } else {
        ChangeLang('', '', values.role)
      }
      if (values.file !== undefined) {
        formdata.append('file', values.file.originFileObj)
      }
      if (!!values.confirmpassword && !!values.newpassword) {
        formdata.append('newpassword', values.newpassword)
      }
      formdata.append('address', values.address)
      formdata.append('contactName', values.contactName)
      formdata.append('contactPhone', values.contactPhone)
      formdata.append('phone', values.phone)
      formdata.append('title', values.title)
      formdata.append('email', values.email)
      console.log('values', values)
      setIsloading(true)
      const res = await EditUserApi(formdata, uuid.id, token.accesstoken);
      if (res.code === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'บันทึกข้อมูลสำเร็จ',
          showConfirmButton: false,
          timer: 1500
        })
        setIsloading(false)
        history.goBack()
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: res.message,
          showConfirmButton: false,
          timer: 1500
        })
      }
    }
  };
  const handleReset = () => {
    console.log("onReset");
  };
  const onFieldsChange = (_: any[], allFields: any[]) => {
    if (_[0].name[0] === "role") {
      console.log("_", _);
      setRole(_[0].value)
    }
  }

  const [form] = Form.useForm();
  const GetUserByid = async (uuid: any) => {
    try {
      const { res } = await GetUserByidApi(uuid.id, token.accesstoken)
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        form.setFieldsValue({
          title: res.result.title,
          phone: res.result.phone,
          email: res.result.email,
          role: res.result.roleTH,
          address: res.result.address,
          contactName: res.result.contactName,
          contactPhone: res.result.contactPhone,
          password: res.result.password,
          fileList: {
            uuid: res.result.image?.id || '',
            url: appConfig.apiURL + '/' + res.result.image?.url || '',
            fileName: res.result.image?.filename || ''
          },
          isCreateForm: String(res.result.isCreateForm)
        });
        if (res.result.roleTH === 'ผู้ประเมิน') {
          form.setFieldsValue({
            assessorType: res.result.assessorTypeTH,
            sport: res.result.sportTH
          })
        }
        setOldImage({
          uuid: res.result.image?.id || '',
          url: appConfig.apiURL + '/' + res.result.image?.url || '',
          fileName: res.result.image?.filename || ''
        })
        setRole(res.result.role)
        //เซตค่าเพื่อใช้ในการเปลี่ยนภาษา
        setRoleTH([{
          role: res.result.role,
          roleTH: res.result.roleTH
        },
        {
          assessorType: res.result.assessorType,
          assessorTypeTH: res.result.assessorTypeTH
        },
        {
          sport: res.result.sport,
          sportTH: res.result.sportTH,
        }])
        //--------------------------
      }
    } catch (error: any) {
      history.push(error.message)
    }
  }

  React.useEffect(() => {
    GetUserByid(uuid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid])

  return (
    <ContentPage
      headerContent={
        <div className="hn-title-center" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div className="hn-title-text" >
            <div className="text-title-white">จัดการผู้ใช้</div>
            <div className="header-font-subtitle">{userInfo.title}</div>
          </div>
        </div>
      }
    >
      <div>
        <Form
          form={form}
          onFinish={handleFinish}
          onFieldsChange={onFieldsChange}
        >
          <Row gutter={[0, 12]}>
            <div className="form-box ">
              <Row gutter={[12, 12]}>
                <Col sm={24} md={12} >
                  <div className="text-title title-text" >จัดการผู้ใช้</div>
                </Col>
                <Col sm={24} md={12}  >
                  <Formfilter isLoading={isLoading} onReset={handleReset} onFinish={handleFinish} />
                </Col>
              </Row>
            </div>
            <Col sm={24} md={24}>
              <div className="form-box ">
                <User valueImage={oldImage} onFinish={handleFinish} page="isEdit" form={form} role={role} />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </ContentPage>
  )
}

export default EditUser
