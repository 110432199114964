import React from 'react'
import { Button } from 'antd';
import { Link } from 'react-router-dom';
// import Swal from 'sweetalert2';
import Save from '@assets/images/icon/save-icon.svg'

interface Props {
    onReset?: () => void;
    onFinish?: (values: any) => void,
    isLoading?: boolean
}

const Formfilter = ({ onReset, isLoading }: Props) => {

    return (
        <div>
            <div className="button w-100" >
                <Link to="/admin/user">
                    {!!onReset && (
                        <Button
                            className="form-button-cancle"
                            style={{ margin: '0px 5px', width: '150px' }}
                            htmlType="submit"
                            disabled={isLoading}
                            // onClick={() => {
                            //     form.resetFields();}}
                            onClick={onReset}
                        >
                            <b>ยกเลิก</b>
                        </Button>)
                    }
                </Link>

                {/* <Link to="/admin/user"> */}
                <Button
                    loading={isLoading}
                    className="form-button-save"
                    htmlType="submit"
                >
                    <b>
                        <img
                            width={25}
                            src={Save}
                            alt="icon save"
                        />
                        บันทึก
                    </b>
                </Button>
                {/* </Link> */}
            </div>

        </div>
    )
}

export default Formfilter
