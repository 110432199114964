import React from 'react';
import { Button } from 'antd';
import appConfig from '@config/app.config';
import { useHistory } from 'react-router-dom'
import { CaretDownFilled } from '@ant-design/icons';
import { Row, Col, Dropdown, Avatar, Menu } from 'antd';
import { UserOutlined, ExportOutlined, } from '@ant-design/icons';
import { useAuthContextDispatch, useAuthContextState } from '@authContext/store'
// import { useLocation, useParams } from 'react-router-dom'
// import { CaretRightOutlined, CaretDownOutlined, FileTextFilled, CaretLeftOutlined } from '@ant-design/icons';
// import { Layout, Cascader, Select } from 'antd';
// import { Props } from '../../Sidebar/Sidebar.interface';

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

// const { Option } = Select;

const Header = ({ children }: Props) => {
  // const location = useLocation()
  let history = useHistory()
  const { _signOut } = useAuthContextDispatch()
  const { userInfo } = useAuthContextState()

  // const { assessmentid } = useParams<{ assessmentid: string }>()
  // console.log("location", assessmentid)


  const handleSignOut = async () => {
    await _signOut()
    history.push('/')
  }

  // function handleChange(value: any) {
  //   console.log(`selected ${value}`);
  // }

  const profile_menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => { history.push('/profile') }}>
        <UserOutlined style={{ marginRight: '10px' }} />
        โปรไฟล์ของฉัน
      </Menu.Item>
      <Menu.Item key="2" onClick={handleSignOut}>
        <ExportOutlined style={{ marginRight: '10px', color: 'red' }} />
        <div style={{ color: 'red' }}>ออกจากระบบ</div>
      </Menu.Item>
    </Menu >
  );

  const profile = (
    <div className="header-button-profile header-profile-btn" >
      <Dropdown overlay={profile_menu} trigger={['click']} overlayStyle={{ marginTop: '20px' }} overlayClassName="header-dropdown-style" >
        <Button ghost className="header-button-profile  header-text-center" >
          <Avatar
            style={{ backgroundColor: 'white', padding: '0' }}
            size="large"
            className="hn-avarta"
            icon={<img alt="img" src={userInfo.image ? appConfig?.apiURL + '/' + userInfo.image : "/images/no-user-image.png"} />}
          />
          <div >
            <div className="header-profile">{userInfo.title}</div>
            <div className="header-profile">{userInfo.roleTH}</div>
          </div>
          <CaretDownFilled style={{ marginLeft: '1em', fontSize: '1.3rem' }} />
        </Button>
      </Dropdown>
    </div>
  );

  /*  const profileHam = (
     <div>
       <Avatar
         style={{ backgroundColor: 'white', padding: '0' }}
         size="large"
         className="hn-avarta"
         icon={<img alt="img-profile" src={userInfo.image ? appConfig?.apiURL + '/' + userInfo.image : "/images/no-user-image.png"} />}
       />
       <div>
         <div className="header-profile-name">{userInfo.userName}</div>
         <div className="header-profile-role">{userInfo.title}</div>
       </div>
     </div>
   ); */

  return (
    <div className="hn-header-new ">
      <Row gutter={[0, 12]} >
        {/* <Col
          xs={{ span: 12, order: 1 }} sm={{ span: 2, order: 1 }} lg={{ span: 2, order: 1 }} xl={{ span: 1, order: 1 }}
        >
          <Button className="header-back-icon" onClick={() => window.history.back()}>
            <CaretLeftOutlined style={{ border: 'none' }} />
          </Button>
        </Col> */}
        <Col
          xs={{ span: 24, order: 3 }} sm={{ span: 19, order: 2 }} xl={{ span: 20, order: 2 }}
        >
          {children}
        </Col>
        <Col
          xs={{ span: 12, order: 2 }} sm={{ span: 3, order: 3 }} xl={{ span: 3, order: 3 }}
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          {profile}
        </Col>
      </Row>
    </div>
  )
}

export default Header
