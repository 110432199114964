import { Fetch, FetchFormData2 } from "../../utils/fetch/fetch";

export const GetUserByidApi = async <T = any>(uuid: string, token: string) => {
  const res = await Fetch<T>({
    method: "GET",
    path: `/user/${uuid}`,
    token: token
  });

  return res;
};

export const EditUserApi = async <T = any>(data: any, uuid: any, token: string) => {
  const res = await FetchFormData2<T>({
    // data,
    method: "PATCH",
    path: `/user/${uuid}`,
    token: token,
    data: data,
  },
    data
  );

  return res;
};
export const changStatusForCreate = async <T = any>(data: boolean, uuid: string, token: string) => {
  const res = await Fetch<T>({
    // data,
    method: "PATCH",
    path: `/user/createForm/${uuid}/${data}`,
    token: token,
  }
  );
  return res;
};
