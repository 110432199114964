import FormAnswer from './FormAnswer'
import React, { useState } from 'react'
import HeaderDetail from './HeaderDetail';
import { ROLE_ENUM } from '@interface/role';
import FormAudienceView from './FormAudienceView'
import { useAuthContextState } from '@authContext/store';
import { CurrentSection } from '@interface/pages/assessment'
import { Questions, DataResults } from '@interface/assessment'
import FormSuccess from './FormStatus/FormSuccess/FormSuccess';
import FormGrading from './FormStatus/FormGrading/FormGrading';
import FormSectorDoing from './FormStatus/FormSectorDoing/FormSectorDoing';
import FormAdminComment from './FormStatus/FormAdminComment/FormAdminComment';

interface Props {
  values: Questions;
  currentSection: CurrentSection;
  percentProps: number;
  assessmentInfo: DataResults | null;
  _getQuestionService: _GetQuestionService;

  setDisableBottonSend: React.Dispatch<React.SetStateAction<boolean>>;
  setValuesInfo: React.Dispatch<React.SetStateAction<Questions | null>>;
  setAssessmentStatus: React.Dispatch<React.SetStateAction<string>>;
  onStatusChang: (evaluationStatus: StatusQuestion[], subIndicatorStatus: StatusQuestion[]) => void;
  setPercentProps: React.Dispatch<React.SetStateAction<number>>;
  setCurrentSection: React.Dispatch<React.SetStateAction<CurrentSection | null>>;
  setStatusQuestion: React.Dispatch<React.SetStateAction<StatusQuestion[] | null>>;
}
interface StatusQuestion { id: string; status: string; }
interface _GetQuestionService {
  getQuestionBySubIdApi: (evaluationID: string, subIndicatorID: string) => void;
  getQuestionBySubIdAndQuestionIndexApi: (evaluationID: string, subIndicatorID: string, indexQuestion: number) => void;
}

const FormQuestionAndAnswer = (props: Props) => {

  const [editStatus, setEditstatus] = useState<boolean>(false)
  const { userInfo } = useAuthContextState()
  const [formProcessStatus, setFormProcessStatus] = React.useState<"onProcess" | "success" | "finish">("onProcess")

  const checkStatus = (role: string, status?: string) => {
    if (role === ROLE_ENUM.ASSESSOR) {
      if (status === 'document') {
        return true
      } else {
        return false
      }
    } else if (role === ROLE_ENUM.OFFICER) {
      if (status === 'grading') {
        return true
      } else {
        return false
      }

    } else if (role === ROLE_ENUM.SECTOR) {
      if (status === 'sector_doing') {
        return true
      } else {
        return false
      }
    } else if (role === ROLE_ENUM.ADMIN) {
      return false
    }
  }

  React.useEffect(() => {
    setFormProcessStatus(props.percentProps === 100 ? "success" : "onProcess")
    if (!!props.percentProps && props.percentProps === 100) {
      setEditstatus(true)
    }
    if (!!props.percentProps && props.percentProps === 100 && !!props.assessmentInfo && props.assessmentInfo.status === 'document' && userInfo.role === ROLE_ENUM.ASSESSOR) {
      props.setDisableBottonSend(false)
    } else if (!!props.percentProps && props.percentProps === 100 && !!props.assessmentInfo && props.assessmentInfo.status === 'grading' && userInfo.role === ROLE_ENUM.OFFICER) {
      props.setDisableBottonSend(false)
    } else if (!!props.percentProps && props.percentProps === 100 && !!props.assessmentInfo && props.assessmentInfo.status === 'sector_doing' && userInfo.role === ROLE_ENUM.SECTOR) {
      props.setDisableBottonSend(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.percentProps])

  return (
    <React.Fragment>
      {props.assessmentInfo?.status === "document" && !!editStatus && userInfo.role === ROLE_ENUM.ASSESSOR ? (
        <div className="boxright-FormAssessment" style={{ height: "100%" }}>
          <FormSuccess nestep={ROLE_ENUM.OFFICER} setEditstatus={setEditstatus} />
        </div>) : props.assessmentInfo?.status === "grading" && !!editStatus && userInfo.role === ROLE_ENUM.OFFICER ? (
          <div className="boxright-FormAssessment" style={{ height: "100%" }}>
            <FormSuccess nestep={ROLE_ENUM.SECTOR} setEditstatus={setEditstatus} />
          </div>)
        : props.assessmentInfo?.status === "sector_doing" && !!editStatus && userInfo.role === ROLE_ENUM.SECTOR ? (
          <div className="boxright-FormAssessment" style={{ height: "100%" }}>
            <FormSuccess nestep={ROLE_ENUM.ADMIN} setEditstatus={setEditstatus} />
          </div>
        ) : props.assessmentInfo?.status === "grading" && !!editStatus && userInfo.role !== ROLE_ENUM.OFFICER ? (
          <div className="boxright-FormAssessment" style={{ height: "100%" }}>
            <FormGrading setEditstatus={setEditstatus} />
          </div>
        ) : props.assessmentInfo?.status === "sector_doing" && !!editStatus ? (
          <div className="boxright-FormAssessment" style={{ height: "100%" }}>
            <FormSectorDoing setEditstatus={setEditstatus} />
          </div>
        ) : props.assessmentInfo?.status === "success" && !!editStatus && userInfo.role !== ROLE_ENUM.ADMIN ? (
          <div className="boxright-FormAssessment" style={{ height: "100%" }}>
            <FormAdminComment setEditstatus={setEditstatus} />
          </div>
        ) : (
          <div className="boxright-FormAssessment">
            <HeaderDetail values={props.values} currentSection={props.currentSection} />
            {!!props.values?.views &&
              (props.values.views.map((item, index) => (
                <FormAudienceView
                  view={props.values}
                  rederAction={checkStatus(userInfo.role, props.assessmentInfo?.status)}
                  formProcessStatus={formProcessStatus}
                  assessmentInfo={props.assessmentInfo}
                  currentSection={props.currentSection}
                  _getQuestionService={props._getQuestionService}
                  onStatusChang={props.onStatusChang}
                  setValuesInfo={props.setValuesInfo}
                  setPercentProps={props.setPercentProps}
                  setStatusQuestion={props.setStatusQuestion}
                  setCurrentSection={props.setCurrentSection}
                  setFormProcessStatus={setFormProcessStatus}
                  editStatus={editStatus}
                  index={index}
                  values={item} key={index} />)))}
            {checkStatus(userInfo.role, props.assessmentInfo?.status) && (
              <FormAnswer
                values={props.values}
                formProcessStatus={formProcessStatus}
                assessmentInfo={props.assessmentInfo}
                currentSection={props.currentSection}
                _getQuestionService={props._getQuestionService}
                onStatusChang={props.onStatusChang}
                setValuesInfo={props.setValuesInfo}
                setPercentProps={props.setPercentProps}
                setStatusQuestion={props.setStatusQuestion}
                setCurrentSection={props.setCurrentSection}
                setFormProcessStatus={setFormProcessStatus}
                editStatus={editStatus}
              />
            )}
          </div>
        )}
    </React.Fragment>
  )
}

export default FormQuestionAndAnswer