import React, { useState } from 'react'
import { Collapse, Progress, Col, Row, Button, Skeleton } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, CalendarFilled } from '@ant-design/icons';
import AssessmentButtonSubmit from '@components/icon/RenderIcon/ItemSubmit'
import { Status, SubmitStatus } from './StatusIcon';
import { DataResults } from '@interface/assessment';
import { useParams } from 'react-router-dom';
import clsx from 'clsx'
import { CurrentSection } from '@interface/pages/assessment';
const { Panel } = Collapse;

interface Props {
  values?: DataResults | null;
  onChange: (parentId: string, subIndicatorID: string) => void;
  icon?: React.ReactNode;
  percentProps: number;
  IsLoading: boolean;
  disableBottonSend: boolean;
  statusQuiestion?: StatusQuiestion[] | null;
  currentSection: CurrentSection | null;
}

interface StatusQuiestion {
  id?: string;
  status?: string
}
const FormIndicators = ({ values, onChange, percentProps, IsLoading, statusQuiestion, currentSection, disableBottonSend }: Props) => {

  const [status] = React.useState("Inprocess");
  const { assessmentid } = useParams<any>()
  const [activeKey, setActiveKey] = useState<string[]>([""])
  React.useEffect(() => {
    !!currentSection && setActiveKey([currentSection.parentId])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection?.parentId])

  const handleClick = async (parentId: string, subIndicatorID: string) => {
    onChange(parentId, subIndicatorID)
  }
  const handleClickCollapse = async (evaluationId: string) => {
    const newKey = activeKey.map(key => {
      if (key === evaluationId) {
        return ""
      }
      return evaluationId
    })
    setActiveKey(newKey)
  }

  return (
    <div className="boxleft-FormAssessment">
      <Row className="indicator-date">
        <Col xs={24} md={24} xl={12} >
          <div className="indicator-textdate" >
            <CalendarFilled style={{ marginRight: "5px" }} />
            <span>วันที่สิ้นสุด :</span>
            {IsLoading ? <Skeleton.Input active size="small" style={{ width: 30, height: 20, borderRadius: 5, marginLeft: 5 }} /> : <span>{values ? values.evaluation[0].endDate : "-"}</span>}

          </div>
        </Col>
        <Col xs={24} md={24} xl={12} >
          <div className="indicator-textdate" >
            <span>จำนวนวันประเมิน :</span>
            {IsLoading ? <Skeleton.Input active size="small" style={{ width: 30, height: 20, borderRadius: 5, marginLeft: 5 }} /> : <span>{values ? values.evaluation[0].dayRemaining : "-"}</span>}
          </div>
        </Col>
      </Row>
      <Row className="indicator-topbox">
        <Col xs={24} md={24} xl={18} >
          <div className="indicator-textboxleft" > ระดับความสมบูรณ์ </div>
          <Progress size="small" percent={percentProps} format={(percentProps: any) => ` ${percentProps}% Complete`} />
        </Col>

        <Col xs={24} md={24} xl={6} className="indicator-boxsand">
          <AssessmentButtonSubmit
            values={{
              disableBottonSend: disableBottonSend,
              percent: percentProps,
              status: values?.status,
              id: assessmentid,
              icon: SubmitStatus.find((icon) => icon.status === status)?.icon,
            }}
          />
        </Col>
      </Row>
      <div style={{ padding: 2, paddingBottom: 5 }}>
        <div className="indicatores-overflow">
          <div className="indicator-textindicators" style={{ fontSize: '20px' }}>  ตัวชี้วัด </div>
          {IsLoading && <div style={{ paddingLeft: 10, paddingRight: 10 }}><Skeleton active /></div>}
          {values && values.evaluation.map((item, index) => (
            <div key={index} className="indicator-CollapseActive" >
              <Collapse
                activeKey={activeKey}
                expandIconPosition={'right'}
                onChange={() => handleClickCollapse(item.id)}
                expandIcon={({ isActive }: any) => isActive ?
                  <CaretUpOutlined style={{ fontSize: '1rem', color: '#2A2866' }} />
                  : <CaretDownOutlined style={{ fontSize: '1rem', color: '#2A2866' }} />}
              >
                <Panel
                  header={item.title}
                  className={clsx("indicator-text", item.status)}
                  key={item.id}>
                  {item.subIndicator.map((sub, index) => (
                    <Button
                      key={`${index}`}
                      onClick={() => handleClick(item.id, sub.id)}
                      className={`assessmentList-boxmenu ${currentSection?.subIndicatorID === sub.id ? 'assessmentList-boxmenuActive' : ""}`}
                      type="link"
                    >
                      <span style={{ display: "flex", }}>
                        <span>{!!statusQuiestion && statusQuiestion?.filter((status) => {
                          return status.id === sub.id
                        }).map((data, ind) => (
                          <span key={ind}> {Status.find(x => x.status === data.status)?.icon}</span>
                        ))}
                        </span>
                        <span>{sub.title}</span>
                      </span>
                    </Button>
                  ))}
                </Panel>
              </Collapse>
            </div >
          ))}
        </div>
      </div >
    </div>
  )
}
export default FormIndicators
