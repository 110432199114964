import React from "react";
import SportDetial from "./modal/sportDetial";
import { Chart } from "react-chartjs-2";
import { CaretDownOutlined } from "@ant-design/icons";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Checkbox, Row, Col, Divider, Select, Skeleton, Spin } from "antd";
import ContentPage from "@layout/MainLayout/Header/Content";
import { DashboardProps, sportInformation } from "@interface/pages/dashboard";
import DoughnutComponetn from "./DoughnutChart/Doughnut";
import { YearOfAchievement } from "@interface/pages/assessment";
import nodata from "@assets/images/3024051.jpg";
import { useAuthContextState } from "@authContext/store";
import {
  GetDashboardApi,
  getInfomationOfSportApi,
  GetYearOfAdminDashboard,
} from "@services/api/dashboard";
import { SportImages } from "./CardList/CardItem/index";
import { useHistory } from "react-router-dom";

const { Option } = Select;

Chart.register(ChartDataLabels);
const Dashboard = (props: DashboardProps) => {
  const [dashboardInfo, setDashboardInfo] = React.useState<any[] | []>([]);
  const [dashboardInfoForFilter, setDashboardInfoForFilter] = React.useState<
    any[] | []
  >([]);
  const date = new Date();
  const history = useHistory();
  const { token, userInfo } = useAuthContextState();
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [sportInformation, setSportInfomation] =
    React.useState<sportInformation>();
  const [YearOfAchievementInfo, setYearOfAchievementInfo] = React.useState<
    YearOfAchievement[] | []
  >([]);
  const [year, setYear] = React.useState(0);
  const getDashboardInfo = async () => {
    try {
      const { res } = await GetDashboardApi(
        token.accesstoken,
        date.getFullYear()
      );
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.status === "success") {
        setDashboardInfo(res.result.data);
        setDashboardInfoForFilter(res.result.data);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      history.push(error.message);
    }
  };

  function onChange(checkedValues: any) {
    if (checkedValues.length > 0) {
      let newAr = checkedValues.map((data: any, id: any) => {
        let newArrFil = dashboardInfoForFilter.find((dataFil: any) => {
          return dataFil.id === data;
        });
        return newArrFil;
      });
      setDashboardInfo(newAr);
    } else {
      setDashboardInfo(dashboardInfoForFilter);
    }
  }

  const selecByYear = async (value: string) => {
    setIsLoading(true);
    const year = Number(value) - 543;
    setYear(year);
    try {
      const { res } = await GetDashboardApi(token.accesstoken, year);
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.status === "success") {
        setDashboardInfo(res.result.data);
        setDashboardInfoForFilter(res.result.data);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      history.push(error.message);
    }
  };

  const getInfomationSport = async (sportName: string) => {
    try {
      const { res } = await getInfomationOfSportApi(
        token.accesstoken,
        sportName,
        year
      );
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        setSportInfomation(res.result);
        setIsModalVisible(true);
      }
    } catch (error: any) {
      history.push(error.message);
    }
  };

  const getAllYear = async () => {
    try {
      const { res } = await GetYearOfAdminDashboard(token.accesstoken);
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        setYearOfAchievementInfo(res.result || []);
      }
    } catch (error: any) {
      history.push(error.message);
    }
  };

  React.useEffect(() => {
    getDashboardInfo();
    getAllYear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentPage
      headerContent={
        <div
          className="hn-title-center"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div className="hn-title-text">
            <div className="text-title-white">แดชบอร์ด</div>
            <div className="header-font-subtitle">{userInfo.title}</div>
          </div>
          <div className="member-select-year">
            <Select
              size="large"
              style={{ width: "150px" }}
              defaultValue="ทั้งหมด"
              onChange={(value: any) => selecByYear(value)}
              suffixIcon={
                <CaretDownOutlined style={{ color: "#000", height: "100px" }} />
              }
            >
              {YearOfAchievementInfo.map((data, index) => (
                <Option key={String(index)} value={String(data.year)}>
                  ปี : {data.year}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      }
    >
      <div className="main-dashboard">
        {!dashboardInfoForFilter.length && !!isLoading ? (
          <div style={{ padding: 20 }}>
            <Skeleton active />
            <Skeleton active />
          </div>
        ) : dashboardInfoForFilter.length > 0 ? (
          <React.Fragment>
            <div className="text-title">ภาพรวมทั้งหมด</div>
            <Spin spinning={isLoading}>
              <Checkbox.Group
                className="w-100 dashmin-checkbox"
                onChange={onChange}
              >
                <Row>
                  {!!dashboardInfoForFilter.length &&
                    dashboardInfoForFilter.map((val, id) => (
                      <Col xl={3} key={id}>
                        <Checkbox value={val?.id}>{val.title}</Checkbox>
                      </Col>
                    ))}
                </Row>
              </Checkbox.Group>
              <div className="dashmin-sport-title">
                <div className="text-primary dashmin-title">
                  ประเภทกีฬาทั้งหมด
                </div>
                {/* <div className="h-100 dashmin-gray-text">14 ประเภทกีฬา</div> */}
              </div>
              <Row gutter={[18, 18]} justify="space-between">
                {dashboardInfo.map((val, id) => (
                  <Col xl={12} key={id}>
                    <div className="dashmin-cardlist">
                      <Col
                        span={24}
                        className="text-primary dashmin-cardlist-header-bg"
                      >
                        <div className="dashmin-cardlist-text">
                          <div className="dashmin-cardlist-title">
                            <img
                              alt="icon"
                              src={
                                SportImages.find(
                                  (img) => img.title === val.title
                                )?.icon
                              }
                              className="dashmin-img-style"
                            />
                            {val.title}
                          </div>
                          <button
                            className="dashmin-cardlist-moreDetail"
                            onClick={() => {
                              getInfomationSport(val.sport);
                            }}
                          >
                            ดูเพิ่มเติม
                          </button>
                          <SportDetial
                            setIsModalVisible={setIsModalVisible}
                            sportInformation={sportInformation}
                            isModalVisible={isModalVisible}
                          />
                        </div>
                      </Col>
                      <Col className="dashmin-cardlist-detail">
                        <Row justify="space-around" align="middle">
                          {val.consensus.map((data: any, index: number) => (
                            <React.Fragment key={data.id}>
                              <Col
                                style={{
                                  height: "300px",
                                }}
                              >
                                {index > 0 && (
                                  <Divider
                                    type="vertical"
                                    style={{ height: "100%" }}
                                  />
                                )}
                              </Col>
                              <Col>
                                <div className="dashmin-role-name">
                                  {data.title}
                                </div>
                                <DoughnutComponetn id={data.id} data={data} />
                                <div className="box-data-of-chart">
                                  {data.role.map(
                                    (dataRole: any, index: number) => (
                                      <div key={index}>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <div className="item-name-role">
                                            <span
                                              className="colored-circle"
                                              style={{
                                                backgroundColor: dataRole.color,
                                              }}
                                            />
                                            {dataRole.title}
                                          </div>
                                          <div className="item-count">
                                            {dataRole.count}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </Col>
                              <Col
                                style={{
                                  height: "300px",
                                }}
                              ></Col>
                            </React.Fragment>
                          ))}
                        </Row>
                      </Col>
                    </div>
                  </Col>
                ))}
              </Row>
            </Spin>
          </React.Fragment>
        ) : (
          <div className="dashmin-cardlist-nodata">
            <div className="dashmin-cardlist-nodata image">
              <h2 className="title-FormAssessmentList">
                ไม่พบข้อมูลรายการประเมิน
              </h2>
              <img alt="img" width={"80%"} height={"80%"} src={nodata} />
            </div>
          </div>
        )}
      </div>
    </ContentPage>
  );
};

export default Dashboard;
