import React from 'react'
import { Row, Col, Form } from 'antd';
import User from '../../components/form/FormUser/index'
import Formfilter from '../../components/form/FormUser/formfilter'
import ContentPage from '@layout/MainLayout/Header/Content';
import Swal from 'sweetalert2'
import appConfig from '@config/app.config';
import { useAuthContextState } from '@authContext/store';
import { EditUserApi, GetUserByidApi } from '@services/api/EditUser';
import { useHistory } from 'react-router-dom';
interface Props {

}

const UserProfile = (props: Props) => {
  const [isLoading, setIsloading] = React.useState<boolean>(false);
  const [role, setRole] = React.useState('')
  const { userInfo, token } = useAuthContextState()
  const formdata = new FormData()
  const [roleTH, setRoleTH] = React.useState([{}])
  const [oldImage, setOldImage] = React.useState<any>({});
  const [form] = Form.useForm();
  const history = useHistory()

  const ChangeLang = (assessorType: string, sport: string, role: string) => {
    roleTH.forEach((data: any) => {
      if (!!data.assessorType) {
        if (data.assessorTypeTH === assessorType) {
          formdata.append('assessorType', data.assessorType)
          console.log('assessorType', data.assessorType);
        }
        else {
          formdata.append('assessorType', assessorType)
          console.log('assessorType', assessorType);
        }
      } else if (data.assessorType === '') {
        formdata.append('assessorType', assessorType)
        console.log('assessorType', assessorType);
      }
      if (!!data.sport) {
        console.log('sport ===');
        if (data.sportTH === sport) {
          formdata.append('sport', data.sport)
          console.log('sport', data.sport);
        }
        else {
          formdata.append('sport', sport)
          console.log('sport', sport);
        }
      } else if (data.sport === '') {
        formdata.append('sport', sport)
        console.log('sport', sport);
      }
      if (!!data.role) {
        console.log('role ===');
        if (data.roleTH === role) {
          formdata.append('role', data.role)
          console.log('role', data.role);
        }
        else {
          formdata.append('role', role)
          console.log('role', role);
        }
      } else if (data.role === '') {
        formdata.append('role', role)
        console.log('sport', role);
      }
    })
  }

  const handleFinish = async (values: any) => {
    if (values.file !== undefined && values.file.status === 'removed') {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'โปรดเลือกรูปภาพ',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      if (values.file !== undefined) {
        formdata.append('file', values.file.originFileObj)
      }
      if (values.role === 'ผู้ประเมิน' || values.role === 'assessor') {
        ChangeLang(values.assessorType, values.sport, values.role)
      } else {
        ChangeLang('', '', values.role)
      }
      if (!!values.password && !!values.confirmpassword && !!values.newpassword) {
        formdata.append('oldpassword', values.password)
        formdata.append('newpassword', values.newpassword)
      }
      formdata.append('address', values.address)
      formdata.append('contactName', values.contactName)
      formdata.append('contactPhone', values.contactPhone)
      formdata.append('phone', values.phone)
      formdata.append('title', values.title)
      formdata.append('email', values.email)
      setIsloading(true)
      const res = await EditUserApi(formdata, userInfo.uuid, token.accesstoken);
      if (res.code === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'บันทึกข้อมูลสำเร็จ',
          showConfirmButton: false,
          timer: 1500
        })
        setIsloading(false)
        history.goBack()
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: res.message,
          showConfirmButton: false,
          timer: 1500
        })
        setIsloading(false)
      }
    }
  };

  const getFormUserList = async () => {
    try {
      const { res } = await GetUserByidApi(userInfo.uuid, token.accesstoken)
      if (!res) throw new Error("/500");
      if (res.code === 401) throw new Error("/401");
      if (res.code === 200) {
        setOldImage({
          uuid: res.result.image?.id || '',
          url: res.result.image?.url ? appConfig.apiURL + '/' + res.result.image?.url : "/images/no-user-image.png",
          fileName: res.result.image?.filename || ''
        })
        setRole(res.result.role)
        setRoleTH([{
          role: res.result.role,
          roleTH: res.result.roleTH
        },
        {
          assessorType: res.result.assessorType,
          assessorTypeTH: res.result.assessorTypeTH
        },
        {
          sport: res.result.sport,
          sportTH: res.result.sportTH,
        }])
        form.setFieldsValue({
          title: res.result.title,
          phone: res.result.phone,
          email: res.result.email,
          role: res.result.roleTH,
          address: res.result.address,
          contactName: res.result.contactName,
          contactPhone: res.result.contactPhone,
        });
        //ถ้าแก้ไขโดยผู้เป็นสถานะผู้ประเมินจะทำการเพิ่มในส่วนของประเภทผู้ประเมิน
        if (res.result.roleTH === 'ผู้ประเมิน') {
          form.setFieldsValue({
            assessorType: res.result.assessorTypeTH,
            sport: res.result.sportTH,
            isCreateForm: String(res.result.isCreateForm)
          })
        }
      }

    } catch (error: any) {
      history.push(error.message)
    }
  }

  React.useEffect(() => {
    getFormUserList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContentPage
      headerContent={
        <div className="hn-title-center" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div className="hn-title-text" >
            <div className="text-title-white">จัดการผู้ใช้</div>
            {/* <div className="header-font-subtitle">{userInfo.title}</div> */}
          </div>
        </div>
      }
    >

      <div>
        <Form
          form={form}
          onFinish={handleFinish}
        >
          <Row gutter={[0, 12]}>
            <div className="form-box ">
              <Row gutter={[12, 12]}>
                <Col sm={24} md={12} >
                  <div className="text-title title-text" >โปรไฟล์</div>
                </Col>
                <Col sm={24} md={12} >
                  <Formfilter isLoading={isLoading} onFinish={handleFinish} />
                </Col>
              </Row>
            </div>
            <Col sm={24} md={24}>
              <div className="form-box ">
                <User onFinish={handleFinish} valueImage={oldImage} form={form} role={role} page="isMyprofile" />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </ContentPage>
  )
}

export default UserProfile
