
import { Collapse } from 'antd';
import { AssessmentTemplate } from '@interface/pages/assessment'
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

interface Props {
  values: AssessmentTemplate[];
}

const { Panel } = Collapse;

const FormDetailMember = ({ values }: Props) => {
  return (
    <div className="assess-list-font-style assess-head-style assess-list-style assess-collapse">
      <Collapse
        defaultActiveKey={[0]}
        accordion
        expandIcon={({ isActive }) => isActive ? <CaretUpOutlined /> : <CaretDownOutlined />}
        expandIconPosition={'right'}>
        {values.map((val, index) => (
          <Panel
            header={val.title}
            key={index}
            className="assess-content-title assess-subhead-style assess-collapse-style "
          >
            <Collapse
              expandIcon={({ isActive }) => isActive ? "" : ""}
              expandIconPosition={'right'}
            >
              {val.detail.map((item, key) => (
                <Panel
                  header={item.title}
                  key={key}
                  className="assess-detail-style"
                >
                </Panel>
              ))}
            </Collapse>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

export default FormDetailMember
