
import car_bg from '@assets/images/background/car-bg.svg'
import golf_bg from '@assets/images/background/golf-bg.svg'
import bike_bg from '@assets/images/background/bike-bg.svg'
import takraw_bg from '@assets/images/background/takraw-bg.svg'
import tennis_bg from '@assets/images/background/tennis-bg.svg'
import jetski_bg from '@assets/images/background/jetski-bg.svg'
import bowling_bg from '@assets/images/background/bowling-bg.svg'
import snooker_bg from '@assets/images/background/snooker-bg.svg'
import footboll_bg from '@assets/images/background/football-bg.svg'
import badminton_bg from '@assets/images/background/badminton-bg.svg'
import motorcycle_bg from '@assets/images/background/motorcycle-bg.svg'
import basketball_bg from '@assets/images/background/basketball-bg.svg'
import volleyball_bg from '@assets/images/background/volleyball-bg.svg'
import esport_bg from '@assets/images/background/esport-bg.svg'

import car from '@assets/images/icon/car.svg'
import golf from '@assets/images/icon/golf.svg'
import bike from '@assets/images/icon/bike.svg'
import tennis from '@assets/images/icon/tennis.svg'
import jetski from '@assets/images/icon/jetski.svg'
import takraw from '@assets/images/icon/takraw.svg'
import bowling from '@assets/images/icon/bowling.svg'
import snooker from '@assets/images/icon/snooker.svg'
import football from '@assets/images/icon/football.svg'
import badminton from '@assets/images/icon/badminton.svg'
import volleyball from '@assets/images/icon/volleyball.svg'
import motorcycle from '@assets/images/icon/motorcycle.svg'
import basketball from '@assets/images/icon/basketball.svg'
import esport from '@assets/images/icon/esport.svg'

export const SportImages = [
  { title: "รถยนต์", background: car_bg, icon: car },
  { title: "กอล์ฟ", background: golf_bg, icon: golf },
  { title: "จักรยาน", background: bike_bg, icon: bike },
  { title: "เจ็ตสกี", background: jetski_bg, icon: jetski },
  { title: "ตะกร้อ", background: takraw_bg, icon: takraw },
  { title: "เทนนิส", background: tennis_bg, icon: tennis },
  { title: "โบว์ลิ่ง", background: bowling_bg, icon: bowling },
  { title: "สนุกเกอร์", background: snooker_bg, icon: snooker },
  { title: "ฟุตบอล", background: footboll_bg, icon: football },
  { title: "แบดมินตัน", background: badminton_bg, icon: badminton },
  { title: "บาสเกตบอล", background: basketball_bg, icon: basketball },
  { title: "วอลเลย์บอล", background: volleyball_bg, icon: volleyball },
  { title: "จักรยานยนต์", background: motorcycle_bg, icon: motorcycle },
  { title: "อีสปอร์ต", background: esport_bg, icon: esport },
]
